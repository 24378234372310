import { assocPath, pipe, props, pathOr, map, prop } from 'ramda';

import { palette } from 'theme';
import { ID, NAME, HISTORY } from 'api/consts';

import { YEAR, AGE_FROM, AGE_UNTIL, VALUE, ACTIVE } from './consts';

const colors = props(['yellow', 'blue', 'pink', 'sea', 'gray', 'green', 'purple', 'orange', 'pistachio', 'red'])(palette);

export const getGroups = (ageGroups) =>
  ageGroups
    .map(({ [ID]: id, [NAME]: name, [AGE_FROM]: from, [AGE_UNTIL]: to, [ACTIVE]: active }, index) => ({
      id,
      name,
      range: [from, to ?? Infinity],
      color: colors[index],
      active,
    }))
    .filter(({ active }) => active);

export const getYearCollection = (population) => pipe(pathOr([], [0, HISTORY]), map(prop(YEAR)))(population);

export const parseResult = (populationMap) => {
  let result = {};

  populationMap.forEach((value, key) => {
    const { year, [NAME]: name } = key;

    result = assocPath([year, name], value, result);
  });

  return result;
};

const getTemplate = (years, groups) => {
  const result = new Map();

  years.forEach((year) => {
    groups.forEach((item) => {
      result.set({ year, ...item }, 0);
    });
  });

  return result;
};
export const getMapPopulation = (years, groups, population) => {
  const result = getTemplate(years, groups);

  population.forEach((item = {}) => {
    const [history = [], from = 0, to] = props([HISTORY, AGE_FROM, AGE_UNTIL], item);

    history.forEach((historyItem = {}) => {
      const [y = 0, v = 0] = props([YEAR, VALUE], historyItem);

      result.forEach((value, key) => {
        const { year, range } = key;

        if (y === year && from >= range[0] && (to ?? Infinity) <= range[1]) result.set(key, value + v);
      });
    });
  });

  return result;
};
