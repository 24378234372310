import styled from 'styled-components';
import { motion } from 'framer-motion';

import { ReactComponent as MapIcon } from 'assets/svg-icons/switzerlandMap.svg';

export const Container = styled(motion.div).attrs(({ $isVisible }) => ({
  animate: { opacity: $isVisible ? 1 : 0 },
  transition: { duration: 0.5 },
  initial: { opacity: 1 },
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  z-index: -1;
  padding: 50px 50px 20px;
  overflow: hidden;
  @media (max-width: 991px) {
    padding: 20px 0;
  }
  @media (max-width: 689px) {
    max-height: 100vh;
  }
`;

export const Icon = styled(motion(MapIcon)).attrs(({ $withScaling }) => ({
  animate: { scale: 1.25 },
  transition: { duration: 9, ease: 'easeOut' },
  initial: { scale: $withScaling ? 0 : 1.25 },
}))`
  height: 100%;
  min-height: 100%;
  min-width: calc(100vh * 1332 / 843);
  box-sizing: border-box;
`;
