import styled from 'styled-components';

import { fontStyles, palette } from 'theme';

const { black, white, yellow, gray, red } = palette;

export const StyledButton = styled.button`
  ${fontStyles.textUpper}
  width: 100%;
  height: 48px;
  background-color: ${({ $secondary }) => ($secondary ? white : black)};
  color: ${({ $secondary }) => ($secondary ? black : white)};
  border: 2px solid ${({ $warn }) => ($warn ? red : black)};
  border-radius: 0;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.3s;
  @media (hover) {
    &:hover {
      background-color: ${({ $secondary, $warn }) => {
        if ($warn) return red;
        if ($secondary) return black;

        return yellow;
      }};
      color: ${({ $secondary, $warn }) => ($secondary && !$warn ? white : black)};
    }
  }
  &:disabled {
    border: 2px solid ${gray};
    background-color: ${gray};
    color: ${black};
    cursor: not-allowed;
  }
`;
