import { pipe, propOr, props, filter, ifElse, isEmpty, join, repeat, is, slice, map, when } from 'ramda';

import { FIRST_NAME, LAST_NAME } from 'store/session';

import { DEFAULT_LANGUAGE } from './constants';

export const extractYupErrors = ({ inner = [] }) =>
  inner.reduce((acc, { path, message }) => {
    acc[path] = message;

    return acc;
  }, {});

const formatDate = new Intl.DateTimeFormat(DEFAULT_LANGUAGE, {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

const formatTime = new Intl.DateTimeFormat(DEFAULT_LANGUAGE, {
  hour: '2-digit',
  minute: '2-digit',
});

export const parseDate = (date) => (date ? formatDate.format(new Date(date)) : '');
export const parseTime = (date) => (date ? formatTime.format(new Date(date)) : '');
export const parseDateWithTime = (date) => (date ? `${parseDate(date)}, ${parseTime(date)}` : '');

export const extractMeta = (field) =>
  pipe(
    propOr({}, field),
    props([FIRST_NAME, LAST_NAME]),
    filter(Boolean),
    ifElse(isEmpty, () => '', join(' '))
  );

export const isPrerender = navigator && (navigator.userAgent.includes('Prerender') || navigator.userAgent.includes('prerender'));

export const getDecimalNumber = (number = null, count = 1) => {
  if (number === '' || (typeof number === 'number' && Number.isNaN(number))) return null;
  if (!(number && count) || typeof number !== 'number' || typeof count !== 'number' || Number.isNaN(count)) return number;

  const divider = Number(`1${repeat('0', count).join('')}`);
  const roundNumber = Math.round(number * divider) / divider;

  return roundNumber % 1 ? Number(roundNumber.toFixed(count)) : roundNumber;
};

export const fromHexToRgba = (hex, alpha = 1) => {
  if (!is(String, hex) || hex.length !== 7) return 'transparent';

  const rgb = pipe(
    (str) => [slice(1, 3, str), slice(3, 5, str), slice(5, 7, str)],
    map((n16) => Number(`0x${n16}`)),
    join(',')
  )(hex);

  return `rgba(${rgb},${alpha})`;
};

export const parseImgUrl = (fileName) => {
  try {
    const file = require(`assets/images/${fileName}`); // eslint-disable-line import/no-dynamic-require

    return file?.default || file;
  } catch (error) {
    console.error(error); // eslint-disable-line no-console

    return null;
  }
};

export const parsePDFLinkObject = when(is(Object), ($) => {
  if (!$.pdfFile) return $;

  try {
    const file = require(`assets/pdfs/${$.pdfFile}`); // eslint-disable-line import/no-dynamic-require

    return { ...$, url: file?.default || file };
  } catch (error) {
    console.error(error); // eslint-disable-line no-console

    return { ...$, url: null };
  }
});

const PRETTY_NUMBER_FORMAT = { useGrouping: 'min2', maximumFractionDigits: 0 };
export const getPrettyNumber = (num) => num.toLocaleString('de-CH', PRETTY_NUMBER_FORMAT);

const PERCENT_FORMAT = { style: 'percent', maximumFractionDigits: 1 };
export const getPercentNumber = (num) => num.toLocaleString('de-CH', PERCENT_FORMAT);

export const getPrettyDecimalNumber = (num, decimalCount = 1) =>
  num.toLocaleString('de-CH', { ...PRETTY_NUMBER_FORMAT, maximumFractionDigits: decimalCount });

export const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
