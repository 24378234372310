export const FEATURE_NAME = 'scenarios';

export const LIST = 'list';
export const FILTERS = 'filters';

export const TITLE = 'title';
export const DESCRIPTION = 'description';
export const YEAR = 'year';
export const AGE_FROM = 'ageFrom';
export const AGE_UNTIL = 'ageTo';
export const VALUE = 'value';
export const AGE_GROUPS = 'ageGroups';
export const ACTIVE = 'active';
export const DEMENTIA = 'dementia';
export const GERONTO = 'geronto';
export const PALLIATIVE = 'palliative';
export const GERIATRICS = 'geriatrics';
export const PARKINSON = 'parkinson';
export const COMPLEX_CARE = 'complexCare';
export const WET_AMD = 'wetAMD';
export const PLUS_65 = 'plus65';
export const PLUS_80 = 'plus80';
export const SHARE_PLUS_65 = 'sharePlus65';
export const SHARE_PLUS_80 = 'sharePlus80';
export const MIN_A = 'minA';
export const MIN_B = 'minB';
export const MIN_C = 'minC';
export const MIN_INPATIENT = 'minInpatient';
export const AMOUNT_OUTPATIENT = 'amountOutpatient';
export const AMOUNT_INPATIENT = 'amountInpatient';
export const FILE_NAME = 'fileName';

export const INPATIENT = 'inpatient';
export const INPATIENT_TARGET = 'inpatientTarget';
export const OUTPATIENT = 'outpatient';
export const INPATIENT_REFERENCE = 'inpatientReference';
export const INPATIENT_SCENARIO = 'inpatientScenario';
export const INPATIENT_INFRASTRUCTURE = 'inpatientInfrastructure';
export const OUTPATIENT_REFERENCE = 'outpatientReference';
export const OUTPATIENT_SCENARIO = 'outpatientScenario';
export const OUTPATIENT_INFRASTRUCTURE = 'outpatientInfrastructure';
export const INPATIENT_STRATEGY = 'inpatientStrategy';
export const INPATIENT_DISTRIBUTION = 'inpatientDistribution';
export const SCENARIO = 'scenario';
export const INFRASTRUCTURE = 'infrastructure';
export const UPPER_BOUND = 'upperBound';
export const LOWER_BOUND = 'lowerBound';
export const AS_IS = 'asis';
export const SPECIAL = 'special';
export const BEDS = 'beds';
export const PRIMARY_CARE = 'primaryCare';
export const SPECIAL_CARE = 'specialCare';
export const SPECIAL_CARE_INPATIENT = 'specialCareInpatient';
export const SEVERE_COURSE = 'severeCourse';
export const APALLIC_SYNDROME = 'apallicSyndrome';
export const BRAIN_DYSFUNCTION = 'brainDysfunction';
export const LATERAL_SCLEROSIS = 'lateralSclerosis';
export const TETRAPLAGIA = 'tetraplegia';
export const INPATIENT_SPECIAL = 'inpatientSpecial';
export const CLEARING = 'clearing';
export const BEDS_DEMENTIA = 'bedsDementia';
export const DAY_STRUCTURE = 'dayStructure';
export const NURSING_HOME = 'nursingHome';
export const DISABLED_FACILITY = 'disabledFacility';
export const DENOMINATIONAL_FACILITY = 'denominationalFacility';
export const HOSPICE_FACILITY = 'hospiceFacility';
export const NIGHT_STRUCTURE = 'nightStructure';
export const REGIONAL_FACILITIES = 'regionalFacilities';
export const MUNICIPAL_FACILITIES = 'municipalFacilities';
export const IS_REGION = 'isRegion';
export const BEDS_GERONTO = 'bedsGeronto';
export const BEDS_AUP = 'bedsAup';
export const BEDS_UP = 'bedsUp';
export const OTHER_SERVICES = 'otherServices';
export const MUNICIPAL_MIGRATION = 'municipalMigration';
export const REGIONAL_MIGRATION = 'regionalMigration';

export const SCENARIO_KINDS = [
  { id: 'low_discharge', label: 'Leichte Heimentlastung', name: 'leichte Heimentlastung', forLegend: '(Pflegestufen 0–2)' },
  { id: 'medium_discharge', label: 'Mittlere Heimentlastung', name: 'mittlere Heimentlastung', forLegend: '(Pflegestufen 0–3)' },
  { id: 'high_discharge', label: 'Starke Heimentlastung', name: 'starke Heimentlastung', forLegend: '(Pflegestufen 0–4)' },
  { id: 'custom', label: 'Benutzerdefiniert', name: 'benutzerdefiniert' },
];
