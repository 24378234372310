import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_INPATIENT,
  UPDATE_INPATIENT_SUMMARY,
  UPDATE_OUTPATIENT,
  UPDATE_OUTPATIENT_SUMMARY,
  UPDATE_CARE_PROVIDER,
  UPDATE_DASHBOARDS_FILTERS,
  UPDATE_CANTONS_MAP,
  UPDATE_SELECTED_CANTON,
  FETCH_INPATIENT,
  FETCH_INPATIENT_SUMMARY,
  FETCH_OUTPATIENT,
  FETCH_OUTPATIENT_SUMMARY,
  FETCH_CARE_PROVIDERS,
  FETCH_CARE_PROVIDER,
  CREATE_CARE_PROVIDER,
  SAVE_CARE_PROVIDER,
  REMOVE_CARE_PROVIDER,
  CREATE_SERVICE,
  SAVE_SERVICE,
  REMOVE_SERVICE,
  UPDATE_DASHBOARDS,
  FETCH_DASHBOARDS,
  FETCH_DASHBOARD,
  SAVE_DASHBOARD,
  UPDATE_DASHBOARD_SETTINGS,
  FETCH_CANTONS_MAP,
} from './types';

export const updateInpatient = createAction(UPDATE_INPATIENT);
export const updateInpatientSummary = createAction(UPDATE_INPATIENT_SUMMARY);
export const updateOutpatient = createAction(UPDATE_OUTPATIENT);
export const updateOutpatientSummary = createAction(UPDATE_OUTPATIENT_SUMMARY);
export const updateCareProvider = createAction(UPDATE_CARE_PROVIDER);
export const updateDashboards = createAction(UPDATE_DASHBOARDS);
export const updateDashboardsFilters = createAction(UPDATE_DASHBOARDS_FILTERS);
export const updateCantonsMap = createAction(UPDATE_CANTONS_MAP);
export const updateSelectedCanton = createAction(UPDATE_SELECTED_CANTON);

export const fetchInpatient = createAlertAction(FETCH_INPATIENT);
export const fetchInpatientSummary = createAlertAction(FETCH_INPATIENT_SUMMARY);
export const fetchOutpatient = createAlertAction(FETCH_OUTPATIENT);
export const fetchOutpatientSummary = createAlertAction(FETCH_OUTPATIENT_SUMMARY);
export const fetchCareProviders = createAlertAction(FETCH_CARE_PROVIDERS);
export const fetchCareProvider = createAlertAction(FETCH_CARE_PROVIDER);
export const createCareProvider = createAlertAction(CREATE_CARE_PROVIDER);
export const saveCareProvider = createAlertAction(SAVE_CARE_PROVIDER);
export const removeCareProvider = createAlertAction(REMOVE_CARE_PROVIDER);
export const createService = createAlertAction(CREATE_SERVICE);
export const saveService = createAlertAction(SAVE_SERVICE);
export const removeService = createAlertAction(REMOVE_SERVICE);
export const fetchDashboards = createAlertAction(FETCH_DASHBOARDS);
export const fetchDashboard = createAlertAction(FETCH_DASHBOARD);
export const saveDashboard = createAlertAction(SAVE_DASHBOARD);
export const updateDashboardSettings = createAlertAction(UPDATE_DASHBOARD_SETTINGS);
export const fetchCantonsMap = createAlertAction(FETCH_CANTONS_MAP);
