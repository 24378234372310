import styled, { createGlobalStyle } from 'styled-components';

import { palette } from 'theme';

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${({ $isTransparent }) => ($isTransparent ? 'transparent' : palette.yellow)};
    transition: background-color 0.5s;
  }
  main {
    min-height: calc(100% - 141px);
    @media (max-width: 991px) {
      min-height: calc(100% - 80px);
    }
    @media (max-width: 689px) {
      min-height: 100vh;
    }
  }
  header {
    @media (min-width: 690px) {
      background-color: ${({ $isTransparent }) => ($isTransparent ? palette.yellow : 'transparent !important')};
      transition: background-color 0.5s;
    }
    @media (max-width: 689px) {
      background-color: transparent !important;
    }
  }
  footer {
    z-index: 1;
    @media (max-width: 991px) {
      background-color: ${palette.white};
    }
  }
`;

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  @media (max-width: 689px) {
    min-height: calc(100vh - 85px);
  }
`;
