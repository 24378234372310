export default {
  slogan: `
    font-family: "SimpleLight", sans-serif;
    font-size: 96px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.97;
    letter-spacing: -4.4px;
    text-transform: uppercase;
    @media (max-width: 991px) {
      font-size: 53px;
      line-height: 1;
      letter-spacing: -1.8px;
    }
`,
  title: `
    font-family: "SimpleLight", sans-serif;
    font-size: 57px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -1.8px;
    text-transform: uppercase;
    @media (max-width: 991px) {
      font-size: 35px;
      letter-spacing: -1.5px;
    }
`,
  titleH3: `
    font-family: "SimpleLight", sans-serif;
    font-size: 57px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -1.8px;  
    text-transform: uppercase;
    @media (max-width: 991px) {
      font-size: 32px;
      letter-spacing: -1px;
    }
`,
  titleH4: `
    font-family: "SimpleLight", sans-serif;
    font-size: 35px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -1.5px;
    text-transform: uppercase;
    @media (max-width: 991px) {
      font-size: 32px;
      letter-spacing: -1px;
    }
`,
  textUpper: `
    font-family: "SimpleRegular", sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: 0.21px;
    text-transform: uppercase;
`,
  text: `
    font-family: "SimpleRegular", sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
`,
  smallText: `
    font-family: "SimpleRegular", sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
  `,
};
