import React from 'react';
import PropTypes from 'prop-types';

import Layout from './Layout';
import { ExternWrap } from './styles';

const Extern = ({ children }) => (
  <Layout>
    <ExternWrap>{children}</ExternWrap>
  </Layout>
);

Extern.propTypes = { children: PropTypes.node.isRequired };

export default Extern;
