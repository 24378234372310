import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_PROJECTS,
  UPDATE_SEARCH,
  UPDATE_PAGINATION,
  UPDATE_SORT,
  RESET_FILTERS,
  FETCH_PROJECTS,
  RESET_PROJECTS,
} from './types';

export const updateProjects = createAction(UPDATE_PROJECTS);
export const resetProjects = createAction(RESET_PROJECTS);
export const updateSearch = createAction(UPDATE_SEARCH);
export const updatePagination = createAction(UPDATE_PAGINATION);
export const updateSort = createAction(UPDATE_SORT);
export const resetFilters = createAction(RESET_FILTERS);

export const fetchProjects = createAlertAction(FETCH_PROJECTS);
