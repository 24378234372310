import { takeLatest, takeEvery, throttle, call, put } from 'redux-saga/effects';
import { pick, omit } from 'ramda';

import { withAlert, applyCancelToken } from 'store/alerts';
import api from 'api';

import {
  ID,
  TITLE,
  READ,
  READER,
  OWNER,
  EDITOR,
  HAS_MORE,
  COUNT,
  FIELDS,
  EXPAND,
  SUB_REGION,
  SUB_ORGANIZATION,
  SUB_OUTPATIENT,
  SUB_INPATIENT,
  SUB_SCENARIO_FOR,
  INPATIENT,
  OUTPATIENT,
} from '.';
import { updateScenarios, updateScenario, deleteScenario, updatePagination } from './actions';
import {
  FETCH_SCENARIOS,
  FETCH_SCENARIO,
  CREATE_SCENARIO,
  SAVE_SCENARIO,
  REMOVE_SCENARIO,
  CREATE_SERVICE,
  SAVE_SERVICE,
  REMOVE_SERVICE,
  FETCH_SCENARIO_COMPLEX_CARE,
  FETCH_SCENARIO_EYE_DISEASES,
  FETCH_SCENARIO_POPULATION,
  FETCH_SCENARIO_OUTPATIENT_DATA,
  FETCH_SCENARIO_REPORTS,
  FETCH_SCENARIO_REPORT,
  CREATE_SCENARIO_REPORT,
} from './types';

const scenariosParams = {
  [FIELDS]: [TITLE, READ, READER, OWNER],
  [EXPAND]: [READER, OWNER],
};
function* fetchScenarios({ payload }) {
  const data = yield call(api.get, '/scenarios', { params: { ...scenariosParams, ...payload } });
  yield put(updateScenarios(data?.data || []));
  yield put(updatePagination(pick([COUNT, HAS_MORE], data)));
}

const scenarioParams = {
  [EXPAND]: [SUB_REGION, SUB_ORGANIZATION, OWNER, EDITOR, SUB_OUTPATIENT, SUB_INPATIENT, SUB_SCENARIO_FOR],
};
function* fetchScenario({ payload, ...rest }) {
  const data = yield call(api.get, `/scenarios/${payload}`, {
    params: scenarioParams,
    ...applyCancelToken(rest),
  });
  yield put(updateScenario(data));
}

function* fetchScenarioPopulation({ payload, ...rest }) {
  return {
    success: yield call(api.post, `/scenarios/${payload[ID]}/population`, omit([ID], payload), applyCancelToken(rest)),
  };
}

const createScenarioParams = { [FIELDS]: [ID] };
function* createScenario({ payload }) {
  const data = yield call(api.post, '/scenarios', payload, { params: createScenarioParams });

  return { success: data[ID] };
}

function* saveScenario({ payload, ...rest }) {
  const data = yield call(api.put, `/scenarios/${payload[ID]}`, payload, {
    params: scenarioParams,
    ...applyCancelToken(rest),
  });
  yield put(updateScenario(data));
}

function* removeScenario({ payload }) {
  const data = yield call(api.delete, `/scenarios/${payload}`);
  yield put(deleteScenario(data));
}

const serviceUrls = {
  [INPATIENT]: 'inpatients',
  [OUTPATIENT]: 'outpatients',
};

function* createService({ payload }) {
  const { scenarioId, type, data } = payload;
  const url = serviceUrls[type];

  if (!(scenarioId || url)) return { error: 'Incorrect action data.' };

  const result = yield call(api.post, `/scenarios/${scenarioId}/${url}`, data);
  const scenario = yield call(api.get, `/scenarios/${scenarioId}`, { params: scenarioParams });

  yield put(updateScenario(scenario));

  return { success: result[ID] };
}

function* saveService({ payload }) {
  const { scenarioId, serviceId, type, data } = payload;
  const url = serviceUrls[type];

  if (!(scenarioId || serviceId || url)) return { error: 'Incorrect action data.' };

  yield call(api.patch, `/scenarios/${scenarioId}/${url}/${serviceId}`, data);
  const scenario = yield call(api.get, `/scenarios/${scenarioId}`, { params: scenarioParams });

  yield put(updateScenario(scenario));

  return { success: true };
}

function* removeService({ payload }) {
  const { scenarioId, serviceId, type } = payload;
  const url = serviceUrls[type];

  if (!(scenarioId || serviceId || url)) return { error: 'Incorrect action data.' };

  yield call(api.delete, `/scenarios/${scenarioId}/${url}/${serviceId}`);
  const scenario = yield call(api.get, `/scenarios/${scenarioId}`, { params: scenarioParams });

  yield put(updateScenario(scenario));

  return { success: true };
}

function* fetchScenarioComplexCare({ payload }) {
  return { success: yield call(api.get, `/scenarios/${payload}/complexcare`) };
}

function* fetchScenarioEyeDiseases({ payload }) {
  return { success: yield call(api.get, `/scenarios/${payload}/eye`) };
}

function* fetchScenarioOutpatientData({ payload }) {
  return { success: yield call(api.get, `/scenarios/${payload}/resources`) };
}

function* fetchScenarioReports({ payload, ...rest }) {
  return {
    success: yield call(api.get, `/scenarios/${payload[ID]}/reports`, { params: omit([ID], payload), ...applyCancelToken(rest) }),
  };
}

function* fetchScenarioReport({ payload }) {
  return {
    success: yield call(api.get, `/scenarios/${payload[0]}/reports/${payload[1]}`, { responseType: 'blob' }),
  };
}

function* createScenarioReport({ payload }) {
  return { success: yield call(api.post, `/scenarios/${payload[ID]}/reports`, omit([ID], payload), { responseType: 'blob' }) };
}

export default function* watchScenarios() {
  yield throttle(500, FETCH_SCENARIOS, withAlert(fetchScenarios));
  yield takeLatest(FETCH_SCENARIO, withAlert(fetchScenario));
  yield takeEvery(CREATE_SCENARIO, withAlert(createScenario));
  yield takeLatest(SAVE_SCENARIO, withAlert(saveScenario));
  yield takeEvery(REMOVE_SCENARIO, withAlert(removeScenario));
  yield takeEvery(CREATE_SERVICE, withAlert(createService));
  yield takeEvery(SAVE_SERVICE, withAlert(saveService));
  yield takeEvery(REMOVE_SERVICE, withAlert(removeService));
  yield takeEvery(FETCH_SCENARIO_COMPLEX_CARE, withAlert(fetchScenarioComplexCare));
  yield takeEvery(FETCH_SCENARIO_EYE_DISEASES, withAlert(fetchScenarioEyeDiseases));
  yield takeLatest(FETCH_SCENARIO_POPULATION, withAlert(fetchScenarioPopulation));
  yield takeEvery(FETCH_SCENARIO_OUTPATIENT_DATA, withAlert(fetchScenarioOutpatientData));
  yield takeLatest(FETCH_SCENARIO_REPORTS, withAlert(fetchScenarioReports));
  yield takeEvery(FETCH_SCENARIO_REPORT, withAlert(fetchScenarioReport));
  yield takeEvery(CREATE_SCENARIO_REPORT, withAlert(createScenarioReport));
}
