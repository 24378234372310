import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';
import ErrorBoundary from '../ErrorBoundary';

const PublicRoute = ({ layout: Layout, component: Component }) => (
  <Layout>
    <ErrorBoundary>
      <Suspense fallback={<Spinner fullScreen />}>
        <Component />
      </Suspense>
    </ErrorBoundary>
  </Layout>
);

PublicRoute.propTypes = {
  layout: PropTypes.elementType.isRequired,
  component: PropTypes.elementType.isRequired,
};

export default PublicRoute;
