import { wrapActions, wrapSelector } from 'store/utils';

import * as actions from './actions';
import { getSeenAnimation, getFilter, getCertificateKind, getTopology, getPublicScenario } from './selectors';

export const useApplicationActions = wrapActions({ ...actions });

export const useSeenAnimation = wrapSelector(getSeenAnimation);
export const usePublicFilter = wrapSelector(getFilter);
export const useCertificateKind = wrapSelector(getCertificateKind);
export const useDoctorDensityTopo = wrapSelector(getTopology);
export const usePublicScenario = wrapSelector(getPublicScenario);
