import styled from 'styled-components';

import { palette } from 'theme';

export const Container = styled.div`
  display: none;
  margin: 15px 20px 0 20px;
  border: 2px solid ${palette.black};
  @media (max-width: 991px) {
    margin: 15px 15px 0 15px;
  }
`;
