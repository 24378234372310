import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_COUNTRIES,
  RESET_COUNTRIES,
  UPDATE_SEARCH,
  UPDATE_PAGINATION,
  UPDATE_SORT,
  RESET_FILTERS,
  FETCH_COUNTRIES,
} from './types';

export const updateCountries = createAction(UPDATE_COUNTRIES);
export const resetCountries = createAction(RESET_COUNTRIES);
export const updateSearch = createAction(UPDATE_SEARCH);
export const updatePagination = createAction(UPDATE_PAGINATION);
export const updateSort = createAction(UPDATE_SORT);
export const resetFilters = createAction(RESET_FILTERS);

export const fetchCountries = createAlertAction(FETCH_COUNTRIES);
