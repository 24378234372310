import { useMemo } from 'react';
import { cond, any, includes, __, T } from 'ramda';

import { usePermissionsObject, useUser, ROLES } from 'store/session';
import { CURADATA_KIND, OBSAN_KIND, PRIVATE_KIND, EPIDEMIOLOGY_OBSAN_KIND } from 'store/healthcareServices/consts';

import { checkAccess } from './utils';
import { REGION, CONSULTANT, REGION_MANAGER, EPIDEMIOLOGY, INTERN_USER } from './consts';

export const usePermissions = () => {
  const permissions = usePermissionsObject();
  const roles = useUser(ROLES);

  return useMemo(() => checkAccess(permissions, roles || []), [permissions, roles]);
};

export const useDashboardTabs = () => {
  const roles = useUser(ROLES);

  return useMemo(
    () =>
      cond([
        [includes(EPIDEMIOLOGY), () => [EPIDEMIOLOGY_OBSAN_KIND, OBSAN_KIND, PRIVATE_KIND]],
        [
          any(includes(__, [INTERN_USER, REGION, CONSULTANT, REGION_MANAGER])),
          () => ['', CURADATA_KIND, OBSAN_KIND, PRIVATE_KIND],
        ],
        [T, () => [CURADATA_KIND]],
      ])(roles || []),
    [roles]
  );
};
