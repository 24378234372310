import { reduce, when, has, mergeDeepLeft, T, path } from 'ramda';

import model from './model';
import { CREATE, READ, UPDATE, DELETE, REDIRECT } from './consts';

export const getPermissions = reduce((acc, item) => when(() => has(item, model), mergeDeepLeft(model[item]), acc), {});

const checkSource =
  (name, permissions, roles) =>
  (source, task = T) =>
    Boolean(path([source, name], permissions) && typeof task === 'function' && task(source, name, permissions, roles));

export const checkAccess = (permissions, roles) => ({
  create: checkSource(CREATE, permissions, roles),
  read: checkSource(READ, permissions, roles),
  update: checkSource(UPDATE, permissions, roles),
  delete: checkSource(DELETE, permissions, roles),
  redirect: checkSource(REDIRECT, permissions, roles),
});
