import styled from 'styled-components';
import { motion } from 'framer-motion';

import { fontStyles, palette } from 'theme';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  width: 100%;
  margin: 0 auto 0;
  @media (max-width: 1119px) {
    row-gap: 8px;
  }
  @media (max-width: 689px) {
    min-height: calc(100vh - 85px);
  }
`;

export const Row = styled(motion.div).attrs(({ $withoutAnimation }) => ({
  variants: {
    hidden: {
      padding: '0 0',
      borderWidth: 0,
      opacity: 0,
    },
    show: {
      padding: '0 10px',
      borderWidth: 2,
      opacity: 1,
      transition: {
        duration: 0.25,
        delayChildren: 0.3,
        staggerChildren: 0.07,
      },
    },
  },
  initial: $withoutAnimation ? 'show' : 'hidden',
}))`
  display: flex;
  align-items: center;
  height: 58px;
  background-color: ${palette.white};
  border-style: solid;
  border-color: ${palette.black};
  margin-bottom: ${({ $mb }) => ($mb ? '10vh' : 0)};
  @media (max-width: 1119px) {
    height: 44px;
  }
`;

export const Text = styled(motion.p).attrs(() => ({
  variants: {
    hidden: {
      display: 'none',
    },
    show: {
      display: 'block',
    },
  },
}))`
  display: none;
  min-width: 20px;
  ${fontStyles.titleH3}
  line-height: 1.19;
  @media (max-width: 1119px) {
    min-width: 15.5px;
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 0.97;
  }
`;

export const Cursor = styled(motion.div).attrs(({ $isFinished }) => ({
  animate: { visibility: $isFinished ? 'hidden' : 'visible', opacity: [1, 0, 0, 1] },
  transition: {
    visibility: { delay: 2.85 },
    opacity: {
      times: [0, 0.4, 0.6, 1],
      repeat: Infinity,
      duration: 1,
    },
  },
  initial: { visibility: 'visible', opacity: 1 },
}))`
  height: 54px;
  width: 2px;
  background-color: #000;
  @media (max-width: 1119px) {
    height: 31px;
  }
`;
