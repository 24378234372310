import React from 'react';
import PropTypes from 'prop-types';

import { Container, LeftLine, RightLine } from './styles';

const ArrowIcon = ({ className, isOpen }) => (
  <Container className={className}>
    <LeftLine $isOpen={isOpen} />
    <RightLine $isOpen={isOpen} />
  </Container>
);

ArrowIcon.defaultProps = { className: null, isOpen: false };
ArrowIcon.propTypes = { className: PropTypes.string, isOpen: PropTypes.bool };

export default ArrowIcon;
