import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { fontStyles, palette } from 'theme';

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
`;

export const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
`;

export const Item = styled(NavLink)`
  display: block;
  height: 33px;
  padding: 1px 10px 0;
  margin-right: 60px;
  &:last-child {
    margin-right: 0;
  }
  cursor: pointer;
  background-color: ${palette.white};
  border: 2px solid ${palette.white};
  ${fontStyles.textUpper}
  line-height: 28px;
  color: ${palette.black};
  text-decoration: none;
  box-sizing: border-box;
  &.active {
    background-color: ${palette.black};
    border: 2px solid ${palette.black};
    color: ${palette.white};
  }
  @media (hover) {
    &:hover {
      border: 2px solid ${palette.black};
    }
  }
  @media (max-width: 1400px) {
    margin-right: 20px;
  }
  @media (max-width: 1300px) {
    margin-right: 10px;
  }
  @media (max-width: 1200px) {
    font-size: 16px;
    padding: 1px 5px 0;
    margin-right: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Button = styled.button`
  height: 33px;
  padding: 0 10px;
  cursor: pointer;
  background-color: ${palette.white};
  border: 2px solid ${palette.white};
  ${fontStyles.textUpper}
  color: ${palette.black};
  box-sizing: border-box;
  @media (hover) {
    &:hover {
      border: 2px solid ${palette.black};
    }
  }
  @media (max-width: 1200px) {
    font-size: 16px;
    padding: 0 5px;
  }
`;
