import { useMemo } from 'react';

import { useInitOrganization, useInitDementiaPopulation } from 'store/organizations';
import { useInitCurrentCanton } from 'store/cantons';
import { useInitCountries } from 'store/countries';
import { useInitHealthcare } from 'store/healthcareServices';
import { mergeAlerts } from 'store/alerts';

export const useInitData = () => {
  const orgAlerts = useInitOrganization();
  const cantonAlerts = useInitCurrentCanton();
  const countriesAlerts = useInitCountries();
  const dementiaPopulation = useInitDementiaPopulation();
  const healthcareAlerts = useInitHealthcare();

  const { loading, success, error } = useMemo(
    () => mergeAlerts([orgAlerts, cantonAlerts, countriesAlerts, dementiaPopulation, healthcareAlerts]),
    [orgAlerts, cantonAlerts, countriesAlerts, dementiaPopulation, healthcareAlerts]
  );

  return { loading: !(success || error) || loading, error };
};
