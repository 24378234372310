import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { CenteredContainer, Title, Text, Restart } from './styles';

const Error = ({ isSuspense }) => {
  const [counter, setCounter] = useState(10);

  const onReload = useCallback(() => window.location.reload(), []);

  useEffect(() => {
    if (isSuspense) return () => null;
    const timer = setInterval(() => {
      setCounter(counter - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [counter, isSuspense, setCounter]);

  useEffect(() => {
    if (counter === 0) window.location.reload();
  }, [counter]);

  return (
    <CenteredContainer>
      <Title>Oops :(</Title>
      <Text>
        {isSuspense
          ? 'Wir konnten keine Verbindung zum Server herstellen. Bitte überprüfen Sie Ihre Internetverbindung.'
          : 'Auf Ihrem Computer ist ein Problem aufgetreten. Wir haben die Fehlerinformation gesammelt und anonymisiert an unsere Fehleranalyse-Software übertragen. Wir versuchen, das Problem so schnell wie möglich zu beheben.'}
      </Text>
      <Restart type="button" onClick={onReload} disabled={!isSuspense}>
        {isSuspense ? 'Seite neu laden' : `Die Anwendung wird in ${counter}s neu gestartet.`}
      </Restart>
    </CenteredContainer>
  );
};

Error.defaultProps = { isSuspense: false };
Error.propTypes = { isSuspense: PropTypes.bool };

export default Error;
