import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { values } from 'ramda';

import { wrapActions, wrapMultiSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';

import { getTeam, getMember, getMemberProp } from './selectors';
import * as actions from './actions';

export const useTeamActions = wrapActions({ ...actions });

export const useTeam = () => {
  const team = useSelector(getTeam);

  return useMemo(() => values(team), [team]);
};

export const useInitTeam = (params) => {
  const { fetchTeam } = useTeamActions();
  const { action, id, loading, error } = useAlerts(fetchTeam);
  const list = useTeam();

  useEffect(() => {
    action(params);
  }, [action, params]);

  return { loading: !id || loading, list, error };
};

export const useMember = (id, arg) => wrapMultiSelector(getMemberProp(id), getMember(id))(arg);
