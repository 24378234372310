import styled from 'styled-components';

import { palette, fontStyles } from 'theme';
import Btn from 'components/Button';

export const Text = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  min-height: 33px;
  padding: 0 5px;
  ${fontStyles.text}
  color: ${palette.black};
  background-color: ${palette.yellow};
`;

export const Button = styled(Btn)`
  width: 200px;
  min-width: 130px;
  height: auto;
  min-height: 33px;
  border: none;
  border-left: 2px solid ${palette.black};
  padding: 0 5px;
`;
