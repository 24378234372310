import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_SCENARIOS = getName('UPDATE_SCENARIOS');
export const UPDATE_SCENARIO = getName('UPDATE_SCENARIO');
export const DELETE_SCENARIO = getName('DELETE_SCENARIO');
export const RESET_SCENARIOS = getName('RESET_SCENARIOS');
export const UPDATE_SEARCH = getName('UPDATE_SEARCH');
export const UPDATE_PAGINATION = getName('UPDATE_PAGINATION');
export const UPDATE_SORT = getName('UPDATE_SORT');
export const RESET_FILTERS = getName('RESET_FILTERS');

export const FETCH_SCENARIOS = getName('FETCH_SCENARIOS');
export const FETCH_SCENARIO = getName('FETCH_SCENARIO');
export const CREATE_SCENARIO = getName('CREATE_SCENARIO');
export const SAVE_SCENARIO = getName('SAVE_SCENARIO');
export const REMOVE_SCENARIO = getName('REMOVE_SCENARIO');
export const CREATE_SERVICE = getName('CREATE_SERVICE');
export const SAVE_SERVICE = getName('SAVE_SERVICE');
export const REMOVE_SERVICE = getName('REMOVE_SERVICE');
export const FETCH_SCENARIO_COMPLEX_CARE = getName('FETCH_SCENARIO_COMPLEX_CARE');
export const FETCH_SCENARIO_EYE_DISEASES = getName('FETCH_SCENARIO_EYE_DISEASES');
export const FETCH_SCENARIO_POPULATION = getName('FETCH_SCENARIO_POPULATION');
export const FETCH_SCENARIO_OUTPATIENT_DATA = getName('FETCH_SCENARIO_OUTPATIENT_DATA');
export const FETCH_SCENARIO_REPORTS = getName('FETCH_SCENARIO_REPORTS');
export const FETCH_SCENARIO_REPORT = getName('FETCH_SCENARIO_REPORT');
export const CREATE_SCENARIO_REPORT = getName('CREATE_SCENARIO_REPORT');
