import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { values } from 'ramda';

import { wrapActions, wrapMultiSelector, wrapSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { SEARCH, SORT, PAGINATION, LIMIT, START_AFTER } from 'api/consts';
import { usePermissions, REGIONS } from 'permissions';

import { getRegions, getRegion, getRegionProp, getRegionsFilters } from './selectors';
import * as actions from './actions';

export const useRegionsActions = wrapActions({ ...actions });

export const useRegion = (id, arg) => wrapMultiSelector(getRegionProp(id), getRegion(id))(arg);

export const useRegionsFilters = wrapSelector(getRegionsFilters);

export const useInitRegions = () => {
  const can = usePermissions();
  const hasAccess = can.read(REGIONS);
  const alerts = useAlerts();
  const regions = useSelector(getRegions);
  const list = useMemo(() => values(regions), [regions]);
  const filters = useSelector(getRegionsFilters);
  const search = filters[SEARCH];
  const sort = filters[SORT];
  const pagination = filters[PAGINATION];
  const limit = pagination[LIMIT];
  const startAfter = pagination[START_AFTER];

  const { extractId } = alerts;
  const { fetchRegions, resetRegions } = useRegionsActions();

  useEffect(() => {
    if (!hasAccess) return;
    extractId(
      fetchRegions({
        ...(search && { [SEARCH]: search }),
        [LIMIT]: limit,
        [START_AFTER]: startAfter,
        ...sort,
      })
    );
  }, [hasAccess, extractId, fetchRegions, search, limit, startAfter, sort]);

  useEffect(() => {
    if (!hasAccess) resetRegions();

    return resetRegions;
  }, [hasAccess, resetRegions]);

  return {
    list,
    ...alerts,
    search,
    sort,
    pagination,
  };
};
