import React from 'react';
import PropTypes from 'prop-types';

import { MOBILE_MENU } from '../consts';

import Select from './Select';
import { Container, Wrapper, Link, Label } from './styles';

const Menu = ({ isOpen }) => (
  <Container animate={isOpen ? 'visible' : 'hidden'}>
    <Wrapper>
      {MOBILE_MENU.map(({ id, path, label, isDropdown, items }, index) =>
        isDropdown ? (
          <Select key={id} label={label} items={items} index={index} animate={isOpen ? 'visible' : 'hidden'} />
        ) : (
          <Link key={id} to={path} custom={index} animate={isOpen ? 'visible' : 'hidden'}>
            <Label>{label}</Label>
          </Link>
        )
      )}
    </Wrapper>
  </Container>
);

Menu.propTypes = { isOpen: PropTypes.bool.isRequired };

export default Menu;
