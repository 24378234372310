import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

import { fontStyles, palette } from 'theme';
import ArrowIcon from 'components/ArrowIcon';

export const Container = styled.div`
  position: relative;
  width: 210px;
  height: 33px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: 100%;
  height: 100%;
  border: 2px solid ${palette.black};
  background-color: ${({ $isOpened }) => ($isOpened ? palette.yellow : palette.black)};
  ${fontStyles.textUpper}
  line-height: 30px;
  color: ${({ $isOpened }) => ($isOpened ? palette.black : palette.white)};
  box-sizing: border-box;
  padding: 0 5px;
  @media (hover) {
    &:hover {
      background-color: ${palette.yellow};
      color: ${palette.black};
    }
  }
`;

export const Text = styled.span`
  flex: 1 1 auto;
  ${fontStyles.textUpper}
  line-height: 30px;
  color: inherit;
`;

export const Icon = styled(ArrowIcon)`
  color: inherit;
  & > div {
    background-color: currentColor;
  }
`;

export const List = styled(motion.div).attrs(({ $isOpened }) => ({
  animate: { height: $isOpened ? 'auto' : 0 },
  transition: { duration: 0.3 },
  initial: { height: $isOpened ? 'auto' : 0 },
}))`
  position: absolute;
  inset: 100% 0 auto 0;
  overflow: hidden;
`;

export const Li = styled.li`
  display: flex;
  align-items: center;
  height: 33px;
  background-color: ${({ $color }) => $color};
`;

export const Link = styled(NavLink)`
  width: 100%;
  border-width: 0;
  border-style: solid;
  border-color: ${palette.black};
  ${fontStyles.textUpper}
  line-height: 33px;
  color: ${palette.black};
  text-decoration: none;
  text-align: center;
  padding: 0 5px;
  box-sizing: border-box;
  position: relative;
  &:before,
  &:after {
    content: none;
    position: absolute;
    height: 2px;
    background-color: ${palette.black};
  }
  &:before {
    inset: ${({ $isFirst }) => ($isFirst ? -2 : -1)}px -2px auto -2px;
  }
  &:after {
    inset: auto -2px ${({ $isLast }) => ($isLast ? 0 : -1)}px -2px;
  }
  @media (hover) {
    &:hover {
      border-width: 0 2px;
      &:before,
      &:after {
        content: '';
      }
    }
  }
  &.active {
    border-width: 0 2px;
    cursor: default;
    &:before,
    &:after {
      content: '';
    }
  }
`;
