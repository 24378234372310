import { path } from 'ramda';

import { FEATURE_NAME as $, CURRENT, CONFIG, DEMENTIA } from './consts';

export const getOrganization = path([$, CURRENT]);
export const getOrganizationProp = (name) => path([$, CURRENT, name]);
export const getOrganizationConfig = path([$, CONFIG]);
export const getOrganizationConfigProp = (name) => path([$, CONFIG, name]);
export const getDementiaPopulation = (id) => path([$, DEMENTIA, id]);
export const getDementiaPopulationProp = (id) => (name) => path([$, DEMENTIA, id, name]);
