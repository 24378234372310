import styled from 'styled-components';
import { motion } from 'framer-motion';

import { fontStyles, palette } from 'theme';

import arrowWhite from './arrowWhite.svg';
import arrowBlack from './arrowBlack.svg';

export const Container = styled(motion.select).attrs(() => ({
  variants: {
    visible: (index) => ({
      scale: 1,
      transition: {
        type: 'spring',
        delay: 0.2 + index * 0.05,
        duration: 0.1,
      },
    }),
    hidden: {
      scale: 0,
      transition: {
        delay: 0,
        duration: 0,
      },
    },
  },
  initial: 'hidden',
}))`
  height: 50px;
  width: 100%;
  ${fontStyles.title}
  text-align: center;
  text-align-last: center;
  background: url(${arrowWhite}) calc(100% - 5px) 50% no-repeat;
  background-color: ${palette.black};
  color: ${palette.white};
  padding: 0 32px 0 5px;
  &:focus {
    background: url(${arrowBlack}) calc(100% - 5px) 50% no-repeat;
    background-color: ${palette.yellow};
    color: ${palette.black};
  }
  @media (hover) {
    &:hover {
      background: url(${arrowBlack}) calc(100% - 5px) 50% no-repeat;
      background-color: ${palette.yellow};
      color: ${palette.black};
    }
  }
  @media (max-width: 374px) {
    font-size: 31px;
    letter-spacing: -1px;
  }
`;
