import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const AUTHENTICATE = getName('AUTHENTICATE');
export const AUTHENTICATE_2FA = getName('AUTHENTICATE_2FA');
export const LOGOUT = getName('LOGOUT');
export const UPDATE_USER_DATA = getName('UPDATE_USER_DATA');

export const LOGIN = getName('LOGIN');
export const LOGIN_2FA = getName('LOGIN_2FA');
export const RESTORE = getName('RESTORE');
export const FETCH_RESET_LINK = getName('FETCH_RESET_LINK');
export const RESET_PASSWORD = getName('RESET_PASSWORD');
export const SAVE_USER_DATA = getName('SAVE_USER_DATA');
export const SAVE_USER_PASSWORD = getName('SAVE_USER_PASSWORD');
export const CREATE_2FA = getName('CREATE_2FA');
export const VALIDATE_2FA = getName('VALIDATE_2FA');
export const DELETE_2FA = getName('DELETE_2FA');
export const SUBSCRIBE_NEWSLETTER = getName('SUBSCRIBE_NEWSLETTER');
