import React from 'react';
import PropTypes from 'prop-types';

import { useSeenAnimation } from 'store/application';

import { Container, Icon } from './styles';

const AnimatedMap = ({ isVisible }) => {
  const isSeenAnimation = useSeenAnimation();

  return (
    <Container $isVisible={isVisible}>
      <Icon $withScaling={!isSeenAnimation && isVisible} />
    </Container>
  );
};

AnimatedMap.propTypes = { isVisible: PropTypes.bool.isRequired };

export default AnimatedMap;
