import { keys, omit } from 'ramda';

import { SORT_DIR, DESC, ASC, POPULATION } from 'api/consts';

export const FEATURE_NAME = 'healthcareServices';
export const CURADATA_KIND = 'curadata';
export const OBSAN_KIND = 'obsan';
export const PRIVATE_KIND = 'private';
export const EPIDEMIOLOGY_OBSAN_KIND = 'epidemiology_obsan';
export const CANTONS_MAP = 'cantonsMapData';
export const LIST = 'list';
export const SELECTED_CANTON = 'selectedCanton';

export const PLUS_65 = '65-plus';
export const PLUS_80 = '80-plus';
export const PLUS_85 = '85-plus';
export const INPATIENT = 'inpatient';
export const INPATIENT_SUMMARY = 'inpatientSummary';
export const OUTPATIENT = 'outpatient';
export const OUTPATIENT_SUMMARY = 'outpatientSummary';
export const LIVING = 'living';
export const CARE_PROVIDER = 'careProvider';
export const DASHBOARDS_FILTERS = 'dashboardsFilters';
export const DASHBOARDS = 'dashboards';
export const SETTINGS = 'settings';
export const ABOUT = 'about';
export const SPECIAL = 'special';

export const CITY = 'city';
export const BEDS_TOTAL = 'bedsTotal';
export const BEDS_NORMAL = 'bedsNormal';
export const BEDS_DEMENTIA = 'bedsDementia';
export const BEDS_GERONTO = 'bedsGeronto';
export const BEDS_AUP = 'bedsAup';
export const DAY_STRUCTURE = 'dayStructure';
export const NIGHT_STRUCTURE = 'nightStructure';

export const HOURS_TOTAL = 'hoursTotal';
export const HOURS_KLV = 'hoursKlv';
export const HOURS_NKLV = 'hoursNklv';
export const HOURS_AUP = 'hoursAup';
export const HOURS_HOUSE = 'hoursHousekeeping';
export const MEALS_CONSUMED = 'mealsConsumed';
export const PEOPLE_CONSUMING_MEALS = 'peopleConsumingMeals';
export const MEALS_PER_PERSON = 'mealsPerPerson';

export const EMERGENCY = 'emergency';
export const FOOD = 'food';
export const ASSISTANCE = 'assistance';
export const CARE = 'care';
export const CLEANING = 'cleaning';
export const SECURITY = 'security';
export const LAUNDRY = 'laundry';
export const PERSONAL_CONTACT = 'personalcontact';
export const SHOPPING = 'shopping';
export const CONSULTING = 'consulting';
export const EVENTS = 'events';
export const CONCIERGE = 'concierge';
export const INFORMATION = 'information';
export const COMMENT = 'comment';

export const PROVIDER = 'provider';
export const PROVIDER_TYPE = 'providerType';
export const IS_PROVIDER_MANAGER = 'providerIsManager';
export const ZIP_CODE = 'zipcode';
export const ADDRESS = 'address';
export const PHONE = 'phone';
export const EMAIL = 'email';
export const CARE_PROVIDER_URL = 'url';

export const FILTER_TYPE = 'filterType';
export const PLANNING_TYPE = 'planning';
export const REGION_TYPE = 'region';
export const LOCALITY_TYPE = 'locality';
export const CARE_PROVIDER_TYPE = 'careprovider';
export const TOTAL_TYPE = 'total';
export const FEMALE_TYPE = 'female';
export const MALE_TYPE = 'male';
export const COUNTRY_TYPE = 'country';
export const CANTON_TYPE = 'canton';
export const ORGANIZATION_TYPE = 'organization';
export const TOUCHED = 'touched';
export const SELECTED_LINES = 'selectedLines';

export const USED_BY = 'usedBy';
export const SHAREHOLDERS = 'shareholders';
export const RATIO = 'ratio';

export const UNITS = 'units';
export const QUANTITY = 'quantity';
export const ROOMS = 'rooms';

export const CURADATA_POPULATION = 'curadata-population';
export const CURADATA_POPULATION_GROUP = 'curadata-population-group';
export const CURADATA_POPULATION_GROUP_65_PLUS = 'curadata-population-group/65-plus';
export const CURADATA_POPULATION_GROUP_80_PLUS = 'curadata-population-group/80-plus';
export const CURADATA_POPULATION_GROUP_85_PLUS = 'curadata-population-group/85-plus';
export const CURADATA_CARE_SERVICES = 'curadata-care-services';
export const CURADATA_CARE_SERVICES_INPATIENT = 'curadata-care-services/inpatient';
export const CURADATA_CARE_SERVICES_OUTPATIENT = 'curadata-care-services/outpatient';
export const CURADATA_CARE_PROVIDERS = 'curadata-care-providers';
export const CURADATA_CARE_PROVIDERS_INPATIENT = 'curadata-care-providers/inpatient';
export const CURADATA_CARE_PROVIDERS_OUTPATIENT = 'curadata-care-providers/outpatient';
export const CURADATA_CARE_PROVIDERS_HISTORY = 'curadata-care-providers-history';
export const CURADATA_CARE_PROVIDERS_INPATIENT_HISTORY = 'curadata-care-providers-history/inpatient';
export const CURADATA_CARE_PROVIDERS_OUTPATIENT_HISTORY = 'curadata-care-providers-history/outpatient';
export const CARE_DAYS = 'care-days';
export const OCCUPANCY = 'occupancy';
export const PROVIDER_DAYS = 'provider-days';
export const OCCUPANCY_LOCATION = 'occupancy-location';
export const EMIGRATION = 'emigration';
export const IMMIGRATION = 'immigration';
export const MIGRATION = 'migration';
export const AVG_NURSING_LEVEL = 'avg-nursing-level';
export const NURSING_LEVEL_RATIOS = 'nursing-level-ratios';
export const AVG_AGE = 'avg-age';
export const AGE_DISTRIBUTION = 'age-distribution';
export const AGE_NURSING_DISTRIBUTION = 'age-nursing-distribution';
export const REMAINING_COSTS = 'remaining-costs';
export const NURSING_CASES = 'nursing-cases';
export const NURSING_ABC = 'nursing-abc';
export const NURSING_COSTS = 'nursing-costs';
export const ENTRY_AGE = 'entry-age';
export const OUTPATIENT_EXPENSES = 'outpatient-expenses';
export const EPIDEMIOLOGY_POPULATION_GROUP = 'epidemiology-population-group';
export const EPIDEMIOLOGY_POPULATION_GROUP_65_PLUS = 'epidemiology-population-group/65-plus';
export const EPIDEMIOLOGY_POPULATION_GROUP_80_PLUS = 'epidemiology-population-group/80-plus';
export const EPIDEMIOLOGY_POPULATION_GROUP_85_PLUS = 'epidemiology-population-group/85-plus';
export const EPIDEMIOLOGY_DEMENTIA = 'epidemiology-dementia';
export const EPIDEMIOLOGY_DEMENTIA_POPULATION = 'epidemiology-dementia/population';
export const EPIDEMIOLOGY_DEMENTIA_INPATIENT = 'epidemiology-dementia/inpatient';
export const EPIDEMIOLOGY_DEMENTIA_SPECIAL = 'epidemiology-dementia/special';
export const EPIDEMIOLOGY_POPULATION = 'epidemiology-population';
export const EPIDEMIOLOGY_WET_AMD = 'epidemiology-wet-amd';
export const EPIDEMIOLOGY_DME = 'epidemiology-dme';
export const EPIDEMIOLOGY_GA = 'epidemiology-ga';
export const EPIDEMIOLOGY_EYE_SUMMARY = 'epidemiology-eye-summary';
export const EPIDEMIOLOGY_EYE_SUMMARY_BAR = 'epidemiology-eye-summary-bar';
export const OUTPATIENT_NURSES = 'outpatient-nurses';
export const INPATIENT_NURSES = 'inpatient-nurses';
export const INPATIENT_DURATION = 'inpatient-duration';
export const ID_KEY = 'id';
export const TAB = 'tab';
export const REFS = 'refs';
export const VALUES = 'values';
export const VALUE = 'value';
export const DAYS = 'days';
export const SPEC_DAYS = 'specDays';
export const PATIENTS = 'patients';
export const SHARE = 'share';
export const BENCHMARK = 'benchmark';
export const CAREDAYS = 'caredays';
export const DESCRIPTION = 'description';
export const TITLE = 'title';
export const TEXT = 'text';
export const LOCAL_PATIENTS = 'localPatients';
export const LOW_LEVEL = 'lowLevel';
export const LOW_LEVEL_RATIO = 'lowLevelRatio';
export const HIGH_LEVEL = 'highLevel';
export const HIGH_LEVEL_RATIO = 'highLevelRatio';
export const YOUNGEST_AGE_GROUP = 'youngestAgeGroup';
export const YOUNGEST_AGE_GROUP_RATIO = 'youngestAgeGroupRatio';
export const MIDDLE_AGE_GROUP = 'middleAgeGroup';
export const MIDDLE_AGE_GROUP_RATIO = 'middleAgeGroupRatio';
export const OLDEST_AGE_GROUP = 'oldestAgeGroup';
export const OLDEST_AGE_GROUP_RATIO = 'oldestAgeGroupRatio';
export const LOW_LEVEL_CAREDAYS = 'lowLevelCaredays';
export const LOW_LEVEL_CAREDAYS_RATIO = 'lowLevelCaredaysRatio';
export const MIDDLE_LEVEL_CAREDAYS = 'midLevelCaredays';
export const MIDDLE_LEVEL_CAREDAYS_RATIO = 'midLevelCaredaysRatio';
export const HIGH_LEVEL_CAREDAYS = 'highLevelCaredays';
export const HIGH_LEVEL_CAREDAYS_RATIO = 'highLevelCaredaysRatio';
export const LABEL = 'label';
export const AGE_FROM = 'ageFrom';
export const AGE_TO = 'ageTo';
export const TOPOLOGY = 'topo';
export const NURSING_LEVEL_RATIOS_REMAINING_COSTS = 'nursingLevelRatios';
export const NURSING_LEVEL_MOVEMENT = 'nursingLevelMovement';
export const COSTS = 'costs';
export const TO_BE_COSTS = 'toBeCosts';
export const INPATIENT_COSTS = 'inpatientCosts';
export const TOTAL_COSTS_TO_BE = 'totalCostsTobe';
export const OUTPATIENT_COSTS_TO_BE = 'outpatientCostsToBe';
export const RJ_PATIENTS = 'rj-patients';
export const YEAR = 'year';
export const YEAR_FROM = 'yearFrom';
export const YEAR_TO = 'yearTo';
export const DATA = 'data';
export const RJ_PATIENTS_LOCATION = 'rj-patients-location';
export const RJ_REMAINING_COSTS = 'rj-remaining-costs';
export const RJ_DECEASED = 'rj-deceased';
export const RJ_OUTPATIENT_HISTORY = 'rj-outpatient-history';
export const RJ_OUTPATIENT_CLIENTS = 'rj-outpatient-clients';
export const RJ_OUTPATIENT_CLIENTS_HISTORY = 'rj-outpatient-clients-history';
export const RJ_OUTPATIENT_AGE_GROUPS = 'rj-outpatient-age-groups';
export const RJ_OUTPATIENT_PRIVATE_PUBLIC = 'rj-outpatient-private-public';
export const RJ_OUTPATIENT_CARE_TYPE = 'rj-outpatient-care-type';
export const RJ_OUTPATIENT_CARE_TYPE_AGE = 'rj-outpatient-care-type-age';
export const PRIVATE_PROVIDERS = 'privateProviders';
export const PUBLIC_PROVIDERS = 'publicProviders';
export const MIN_A = 'minA';
export const MIN_B = 'minB';
export const MIN_C = 'minC';
export const IS_COUNTRY = 'isCountry';
export const CONFIG = 'config';
export const AGE_GROUP = 'ageGroup';
export const DEMENTIA = 'dementia';
export const TOTAL = 'total';
export const SHORT_NAME = 'shortname';
export const DME = 'dme';
export const GA = 'ga';
export const OTHER = 'other';
export const WET_AMD = 'wetAMD';
export const HWL = 'hoursHousekeeping';
export const KLV_TOTAL = 'hoursKLV';
export const KLV_A = 'hoursKLVA';
export const KLV_B = 'hoursKLVB';
export const KLV_C = 'hoursKLVC';
export const AGE = 'age';
export const MAX_AGE = 'maxAge';
export const MIN_AGE = 'minAge';

export const EPIDEMIOLOGY_EYE_DISEASES = {
  [WET_AMD]: ['Makuladegeneration (nvAMD)', 'nvAMD'],
  [DME]: ['Diabetisches Makulaödem (DMÖ)', 'DMÖ'],
  [OTHER]: ['Andere Netzhauterkrankungen', 'Andere'],
  [GA]: ['Geografische Atrophie (GA)', 'GA'],
};

export const DEFAULT_CUSTOM_DASHBOARD_SETTINGS = {
  [CARE_DAYS]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: [],
    [REGION_TYPE]: [],
    [LOCALITY_TYPE]: [],
  },
  [PLUS_80]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: [],
    [REGION_TYPE]: [],
    [LOCALITY_TYPE]: [],
  },
  [OCCUPANCY]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [SORT_DIR]: DESC,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: null,
    [REGION_TYPE]: null,
    [LOCALITY_TYPE]: null,
    [CARE_PROVIDER_TYPE]: [],
  },
  [OCCUPANCY_LOCATION]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [SORT_DIR]: DESC,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: null,
    [REGION_TYPE]: [],
    [LOCALITY_TYPE]: [],
    [CARE_PROVIDER_TYPE]: [],
  },
  [PROVIDER_DAYS]: {
    [DAYS]: true,
    [SPEC_DAYS]: false,
    [SORT_DIR]: DESC,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: null,
    [REGION_TYPE]: null,
    [LOCALITY_TYPE]: null,
    [CARE_PROVIDER_TYPE]: [],
  },
  [EMIGRATION]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [SORT_DIR]: DESC,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: null,
    [REGION_TYPE]: null,
    [LOCALITY_TYPE]: null,
  },
  [IMMIGRATION]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [SORT_DIR]: DESC,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: null,
    [REGION_TYPE]: null,
    [LOCALITY_TYPE]: null,
    [CARE_PROVIDER_TYPE]: null,
  },
  [MIGRATION]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [SORT_DIR]: ASC,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: [],
    [REGION_TYPE]: [],
    [LOCALITY_TYPE]: [],
  },
  [AVG_NURSING_LEVEL]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: null,
    [REGION_TYPE]: [],
    [LOCALITY_TYPE]: [],
    [CARE_PROVIDER_TYPE]: [],
  },
  [NURSING_LEVEL_RATIOS]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: null,
    [REGION_TYPE]: [],
    [LOCALITY_TYPE]: [],
    [CARE_PROVIDER_TYPE]: [],
  },
  [AVG_AGE]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: null,
    [REGION_TYPE]: [],
    [LOCALITY_TYPE]: [],
    [CARE_PROVIDER_TYPE]: [],
  },
  [AGE_DISTRIBUTION]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: null,
    [REGION_TYPE]: [],
    [LOCALITY_TYPE]: [],
    [CARE_PROVIDER_TYPE]: [],
  },
  [AGE_NURSING_DISTRIBUTION]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: null,
    [REGION_TYPE]: null,
    [LOCALITY_TYPE]: null,
    [CARE_PROVIDER_TYPE]: null,
  },
  [REMAINING_COSTS]: {
    [DAYS]: true,
    [SPEC_DAYS]: false,
    [NURSING_LEVEL_MOVEMENT]: INPATIENT,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: [],
    [REGION_TYPE]: [],
    [LOCALITY_TYPE]: [],
    [CARE_PROVIDER_TYPE]: [],
    [SELECTED_LINES]: [COSTS],
  },
  [RJ_PATIENTS]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [FILTER_TYPE]: REGION_TYPE,
    [REGION_TYPE]: [],
    [CARE_PROVIDER_TYPE]: [],
  },
  [RJ_PATIENTS_LOCATION]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [FILTER_TYPE]: REGION_TYPE,
    [REGION_TYPE]: [],
  },
  [RJ_REMAINING_COSTS]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [FILTER_TYPE]: REGION_TYPE,
    [REGION_TYPE]: [],
    [CARE_PROVIDER_TYPE]: [],
  },
  [RJ_DECEASED]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [FILTER_TYPE]: REGION_TYPE,
    [REGION_TYPE]: [],
    [CARE_PROVIDER_TYPE]: [],
  },
  [NURSING_CASES]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [SORT_DIR]: ASC,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: [],
    [REGION_TYPE]: [],
    [LOCALITY_TYPE]: [],
    [SELECTED_LINES]: ['0', '1', '2', '3'],
  },
  [NURSING_ABC]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [SORT_DIR]: ASC,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: [],
    [REGION_TYPE]: [],
    [LOCALITY_TYPE]: [],
  },
  [NURSING_COSTS]: {
    [DAYS]: true,
    [SPEC_DAYS]: true,
    [SORT_DIR]: ASC,
    [FILTER_TYPE]: PLANNING_TYPE,
    [PLANNING_TYPE]: [],
    [REGION_TYPE]: [],
    [LOCALITY_TYPE]: [],
    [SELECTED_LINES]: ['0', '1', '2', '3'],
  },
  [RJ_OUTPATIENT_HISTORY]: {
    [SELECTED_LINES]: [HWL, KLV_TOTAL, KLV_A, KLV_B, KLV_C],
  },
  [RJ_OUTPATIENT_CLIENTS]: {},
  [RJ_OUTPATIENT_CLIENTS_HISTORY]: {},
  [RJ_OUTPATIENT_PRIVATE_PUBLIC]: {},
  [RJ_OUTPATIENT_AGE_GROUPS]: {},
  [RJ_OUTPATIENT_CARE_TYPE]: {},
  [RJ_OUTPATIENT_CARE_TYPE_AGE]: {},
};
export const STATIC_DASHBOARDS = {
  [CURADATA_CARE_SERVICES]: [INPATIENT, OUTPATIENT],
  [CURADATA_CARE_PROVIDERS]: [INPATIENT, OUTPATIENT],
};

export const STATIC_DASHBOARD_TABS = [
  CURADATA_CARE_SERVICES_INPATIENT,
  CURADATA_CARE_SERVICES_OUTPATIENT,
  CURADATA_CARE_PROVIDERS_INPATIENT,
  CURADATA_CARE_PROVIDERS_OUTPATIENT,
];

export const DEFAULT_OBSAN_DASHBOARD_SETTINGS = {
  [ENTRY_AGE]: {},
  [OUTPATIENT_EXPENSES]: {},
  [OUTPATIENT_NURSES]: {},
  [INPATIENT_NURSES]: {},
  [INPATIENT_DURATION]: {},
};

export const EPIDEMIOLOGY_DASHBOARDS = {
  [CURADATA_POPULATION]: null,
  [CURADATA_POPULATION_GROUP]: [PLUS_65, PLUS_80, PLUS_85],
  [EPIDEMIOLOGY_POPULATION_GROUP]: [PLUS_65, PLUS_80, PLUS_85],
  [EPIDEMIOLOGY_DEMENTIA]: [POPULATION, INPATIENT, SPECIAL],
  [EPIDEMIOLOGY_POPULATION]: null,
  [EPIDEMIOLOGY_WET_AMD]: null,
  [EPIDEMIOLOGY_DME]: null,
  [EPIDEMIOLOGY_GA]: null,
  [EPIDEMIOLOGY_EYE_SUMMARY]: null,
  [EPIDEMIOLOGY_EYE_SUMMARY_BAR]: null,
  [CURADATA_CARE_PROVIDERS_HISTORY]: [INPATIENT, OUTPATIENT],
};
export const EPIDEMIOLOGY_DASHBOARD_TABS = [
  CURADATA_POPULATION,
  EPIDEMIOLOGY_POPULATION_GROUP_65_PLUS,
  CURADATA_POPULATION_GROUP_65_PLUS,
  CURADATA_POPULATION_GROUP_80_PLUS,
  CURADATA_POPULATION_GROUP_85_PLUS,
  EPIDEMIOLOGY_POPULATION_GROUP_80_PLUS,
  EPIDEMIOLOGY_POPULATION_GROUP_85_PLUS,
  EPIDEMIOLOGY_POPULATION,
  EPIDEMIOLOGY_WET_AMD,
  EPIDEMIOLOGY_DEMENTIA_POPULATION,
  EPIDEMIOLOGY_DEMENTIA_INPATIENT,
  EPIDEMIOLOGY_DEMENTIA_SPECIAL,
  EPIDEMIOLOGY_DME,
  EPIDEMIOLOGY_GA,
  EPIDEMIOLOGY_EYE_SUMMARY,
  EPIDEMIOLOGY_EYE_SUMMARY_BAR,
  CURADATA_CARE_PROVIDERS_INPATIENT_HISTORY,
  CURADATA_CARE_PROVIDERS_OUTPATIENT_HISTORY,
];

export const DEFAULT_EPIDEMIOLOGY_DASHBOARD_SETTINGS = {
  [EPIDEMIOLOGY_POPULATION_GROUP]: {
    [FILTER_TYPE]: COUNTRY_TYPE,
  },
  [EPIDEMIOLOGY_POPULATION]: {
    [FILTER_TYPE]: COUNTRY_TYPE,
  },
  [EPIDEMIOLOGY_WET_AMD]: {
    [FILTER_TYPE]: COUNTRY_TYPE,
  },
  [EPIDEMIOLOGY_DME]: {
    [FILTER_TYPE]: COUNTRY_TYPE,
  },
  [EPIDEMIOLOGY_GA]: {
    [FILTER_TYPE]: COUNTRY_TYPE,
  },
  [EPIDEMIOLOGY_DEMENTIA]: {
    [FILTER_TYPE]: COUNTRY_TYPE,
  },
  [EPIDEMIOLOGY_EYE_SUMMARY]: {
    [SELECTED_LINES]: [DME, GA, OTHER, WET_AMD],
    [FILTER_TYPE]: COUNTRY_TYPE,
  },
  [EPIDEMIOLOGY_EYE_SUMMARY_BAR]: {
    [FILTER_TYPE]: COUNTRY_TYPE,
  },
  [CURADATA_POPULATION]: {
    [FILTER_TYPE]: '',
  },
  [CURADATA_POPULATION_GROUP]: {
    [FILTER_TYPE]: '',
  },
};

export const CUSTOM_DASHBOARDS = keys(DEFAULT_CUSTOM_DASHBOARD_SETTINGS);

export const CANTON_MAP_KEYS = omit([CURADATA_POPULATION, CURADATA_POPULATION_GROUP], DEFAULT_EPIDEMIOLOGY_DASHBOARD_SETTINGS);
