import { reducerListTemplate } from 'store/utils';

import { ID } from '.';
import { UPDATE_TEAM, RESET_TEAM, UPDATE_MEMBER, DELETE_MEMBER } from './types';

export default reducerListTemplate(
  {
    id: ID,
    list: UPDATE_TEAM,
    item: UPDATE_MEMBER,
    deleteItem: DELETE_MEMBER,
    reset: RESET_TEAM,
  },
  {}
);
