import React, { useMemo, useCallback, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { cond, T } from 'ramda';
import PropTypes from 'prop-types';

import { useAsyncState } from 'hooks';

import { Container, Button, Text, Icon, List, Li, Link } from './styles';

const Dropdown = ({ label, items }) => {
  const [isOpened, setOpened] = useAsyncState(false);
  const { pathname } = useLocation();
  const title = useMemo(
    () =>
      cond([...items.map(({ to, name }) => [(currentPath) => matchPath(to, currentPath), () => name]), [T, () => label]])(
        pathname
      ),
    [items, label, pathname]
  );

  const onOpen = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setOpened(($) => !$);
    },
    [setOpened]
  );

  useEffect(() => {
    if (!isOpened) return () => null;

    const listener = () => {
      setOpened(false);
      document.body.removeEventListener('click', listener);
    };

    document.body.addEventListener('click', listener);

    return () => {
      document.body.removeEventListener('click', listener);
    };
  }, [isOpened, setOpened]);

  return (
    <Container>
      <Button type="button" onClick={onOpen} $isOpened={isOpened}>
        <Text>{title}</Text>
        <Icon isOpen={isOpened} />
      </Button>
      <List $isOpened={isOpened}>
        <ul>
          {items.map(({ to, name, color }, i) => (
            <Li key={to} $color={color}>
              <Link to={to} $isFirst={i === 0} $isLast={i === items.length - 1}>
                {name}
              </Link>
            </Li>
          ))}
        </ul>
      </List>
    </Container>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default Dropdown;
