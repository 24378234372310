import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { palette, fontStyles } from 'theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  row-gap: 10px;
  justify-content: center;
  padding: 15px;
`;

export const Item = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border: 2px solid ${palette.black};
  background-color: ${({ $color }) => $color};
  ${fontStyles.titleH3}
  color: ${palette.black};
  text-decoration: none;
  padding: 0 10px;
  transition: background-color 0.3s;
  &:active {
    background-color: ${palette.white};
  }
  @media (hover) {
    &:hover {
      background-color: ${palette.white};
    }
  }
`;
