import { takeLatest, call, put } from 'redux-saga/effects';
import { pick } from 'ramda';

import { withAlert, applyCancelToken } from 'store/alerts';
import api from 'api';

import { COUNT, HAS_MORE } from '.';
import { updateCountries, updatePagination } from './actions';
import { FETCH_COUNTRIES } from './types';

function* fetchCountries({ payload, ...rest }) {
  const data = yield call(api.get, '/countries', { params: payload, ...applyCancelToken(rest) });
  yield put(updateCountries(data?.data || []));
  yield put(updatePagination(pick([COUNT, HAS_MORE], data)));
}

export default function* watchCountries() {
  yield takeLatest(FETCH_COUNTRIES, withAlert(fetchCountries));
}
