import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

import { fontStyles, palette } from 'theme';

const { black, white } = palette;

const transition = {
  duration: 0.3,
  ease: 'easeOut',
};

export const Container = styled(motion.div).attrs(() => ({
  variants: {
    visible: {
      height: 'auto',
      bottom: 0,
      opacity: 1,
      transition,
    },
    hidden: {
      height: 0,
      bottom: 'auto',
      opacity: 0,
      transition,
    },
  },
  initial: 'hidden',
}))`
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: auto;
  height: 0;
  background-color: ${white};
  z-index: 99;
  overflow: hidden;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 15px;
`;

const activeLinkStyle = `
  border-color: transparent;
  & + a {
    border-color: transparent;
  }
`;
const activeLabelStyle = `
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      height: 50px;
      transform: translateY(-50%);
      box-sizing: border-box;
      border: 2px solid ${black};
    }
  `;
export const Link = styled(motion(NavLink)).attrs(() => ({
  variants: {
    visible: (index) => ({
      scale: 1,
      transition: {
        type: 'spring',
        delay: 0.2 + index * 0.05,
        duration: 0.1,
      },
    }),
    hidden: {
      scale: 0,
      transition: {
        delay: 0,
        duration: 0,
      },
    },
  },
  initial: 'hidden',
}))`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-top: 2px solid ${palette.black};
  text-decoration: none;
  &:first-of-type {
    border-top: none;
    height: 50px;
  }
  @media (hover) {
    &:hover {
      ${activeLinkStyle}
    }
    &:hover > label {
      ${activeLabelStyle}
    }
  }
  &:active,
  &.active {
    ${activeLinkStyle}
  }
  & > label {
    background-color: ${white};
    color: ${black};
  }
  &:active > label,
  &.active > label {
    ${activeLabelStyle}
  }
`;

export const Label = styled.label`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ${fontStyles.title}
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 991px) {
    line-height: 44px;
  }
  user-select: none;
`;
