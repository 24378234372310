import { all, spawn } from 'redux-saga/effects';

import watchApplication from 'store/application/sagas';
import watchSession from 'store/session/sagas';
import watchTeam from 'store/team/sagas';
import watchRegions from 'store/regions/sagas';
import watchOrganizations from 'store/organizations/sagas';
import watchScenarios from 'store/scenarios/sagas';
import watchCantons from 'store/cantons/sagas';
import watchCountries from 'store/countries/sagas';
import watchProjects from 'store/projects/sagas';
import watchHealthcareServices from 'store/healthcareServices/sagas';

export default function* watcher() {
  yield all([
    spawn(watchApplication),
    spawn(watchSession),
    spawn(watchTeam),
    spawn(watchRegions),
    spawn(watchOrganizations),
    spawn(watchScenarios),
    spawn(watchCantons),
    spawn(watchCountries),
    spawn(watchProjects),
    spawn(watchHealthcareServices),
  ]);
}
