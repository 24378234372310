import { takeLatest, takeEvery, throttle, call, put } from 'redux-saga/effects';
import { pick, omit } from 'ramda';

import { withAlert, applyCancelToken } from 'store/alerts';
import api from 'api';
import { NAME as ORGANIZATION_NAME, CANTON } from 'store/organizations';

import {
  ID,
  NAME,
  SUB_ORGANIZATIONS,
  OWNER,
  EDITOR,
  CREATED,
  MODIFIED,
  LEGAL_ENTITY,
  IS_CANTON,
  HAS_MORE,
  COUNT,
  FIELDS,
  EXPAND,
  SORT_BY,
  SORT_DIR,
  ASC,
} from '.';
import { updateRegions, updateRegion, deleteRegion, updatePagination } from './actions';
import {
  FETCH_REGIONS,
  FETCH_REGIONS_COLLECT,
  FETCH_LOCALITIES_COLLECT,
  FETCH_REGION,
  CREATE_REGION,
  SAVE_REGION,
  REMOVE_REGION,
} from './types';

const defaultParams = {
  [FIELDS]: [
    NAME,
    `${SUB_ORGANIZATIONS}.${ORGANIZATION_NAME}`,
    `${SUB_ORGANIZATIONS}.${CANTON}`,
    OWNER,
    EDITOR,
    CREATED,
    MODIFIED,
    LEGAL_ENTITY,
    IS_CANTON,
  ],
  [EXPAND]: [SUB_ORGANIZATIONS, OWNER, EDITOR],
};

function* fetchRegions({ payload }) {
  const data = yield call(api.get, '/regions', { params: { ...defaultParams, ...payload } });
  yield put(updateRegions(data?.data || []));
  yield put(updatePagination(pick([COUNT, HAS_MORE], data)));
}

function* fetchRegionsCollect({ payload }) {
  return {
    success: yield call(api.get, '/regions', {
      params: {
        ...defaultParams,
        ...payload,
        [SORT_BY]: NAME,
        [SORT_DIR]: ASC,
      },
    }),
  };
}

function* fetchLocalitiesCollect({ payload }) {
  return {
    success: yield call(api.get, '/localities', {
      params: {
        ...payload,
        [SORT_BY]: NAME,
        [SORT_DIR]: ASC,
      },
    }),
  };
}

function* fetchRegion({ payload, ...rest }) {
  const data = yield call(api.get, `/regions/${payload}`, { params: defaultParams, ...applyCancelToken(rest) });
  yield put(updateRegion(data));
}

function* createRegion({ payload }) {
  const data = yield call(api.post, '/regions', payload, { params: defaultParams });
  yield put(updateRegion(data));
}

function* saveRegion({ payload, ...rest }) {
  const data = yield call(api.patch, `/regions/${payload[ID]}`, omit([ID], payload), {
    params: defaultParams,
    ...applyCancelToken(rest),
  });
  yield put(updateRegion(data));
}

function* removeRegion({ payload }) {
  const data = yield call(api.delete, `/regions/${payload}`);
  yield put(deleteRegion(data));
}

export default function* watchRegions() {
  yield throttle(500, FETCH_REGIONS, withAlert(fetchRegions));
  yield throttle(300, FETCH_REGIONS_COLLECT, withAlert(fetchRegionsCollect));
  yield throttle(300, FETCH_LOCALITIES_COLLECT, withAlert(fetchLocalitiesCollect));
  yield takeLatest(FETCH_REGION, withAlert(fetchRegion));
  yield takeEvery(CREATE_REGION, withAlert(createRegion));
  yield takeLatest(SAVE_REGION, withAlert(saveRegion));
  yield takeEvery(REMOVE_REGION, withAlert(removeRegion));
}
