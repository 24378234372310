import React from 'react';
import PropTypes from 'prop-types';

import Layout from './Layout';
import { FullScreenWrap } from './styles';

const FullScreen = ({ children }) => (
  <Layout>
    <FullScreenWrap>{children}</FullScreenWrap>
  </Layout>
);

FullScreen.propTypes = { children: PropTypes.node.isRequired };

export default FullScreen;
