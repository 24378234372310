export const CACHE_NAME = 'offline';
export const DEFAULT_ERROR =
  'Oops, ein Fehler ist aufgetreten. Helfen Sie uns, Ihr Benutzererlebnis zu verbessern und senden Sie einen Fehlerbericht.';

export const TOKEN = 'token';
export const TOKEN_2FA = 'token2fa';

export const SEARCH = 'search';
export const FILTER = 'filter';
export const KIND = 'kind';
export const PAGINATION = 'pagination';
export const COUNT = 'count';
export const HAS_MORE = 'hasMore';
export const START_AFTER = 'startAfter';
export const LIMIT = 'limit';
export const SORT = 'sort';
export const SORT_BY = 'sortBy';
export const SORT_DIR = 'sortDir';
export const ASC = 'asc';
export const DESC = 'desc';
export const EXPAND = 'expand[]';
export const FIELDS = 'fields[]';
export const DATE = 'date';
export const DATE_LT = 'date[lt]';
export const DATE_LTE = 'date[lte]';
export const DATE_GT = 'date[gt]';
export const DATE_GTE = 'date[gte]';

export const ID = '_id';
export const NAME = 'name';
export const POPULATION = 'population';
export const POPULATION_FORECAST = 'populationForecast';
export const SOURCES = 'sources';
export const HISTORY = 'history';

export const OWNER = 'owner';
export const EDITOR = 'editor';
export const READER = 'reader';
export const CREATED = 'created';
export const MODIFIED = 'modified';
export const READ = 'read';

export const SUB_ORGANIZATION = '_organization';
export const SUB_ORGANIZATIONS = '_organizations';
export const SUB_REGION = '_region';
export const SUB_REGIONS = '_regions';
export const SUB_CARE_PROVIDER = '_careprovider';
export const SUB_CARE_PROVIDERS = '_careproviders';
export const SUB_SIM_ORGANIZATION = '_sim_organization';
export const SUB_INPATIENT = '_inpatient';
export const SUB_OUTPATIENT = '_outpatient';
export const SUB_LIVING = '_living';
export const SUB_CANTON = '_canton';
export const SUB_SCENARIO_FOR = '_scenarioFor';
