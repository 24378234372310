import styled from 'styled-components';

import { fontStyles, palette } from 'theme';

export const Message = styled.div`
  background-color: ${palette.yellow};
  border: 2px solid ${palette.black};
  ${fontStyles.text}
  color: ${palette.black};
  margin: 15px 20px 0 20px;
  padding: 4px;
  & > a {
    ${fontStyles.text}
    color: inherit;
  }
  & > a:hover {
    opacity: 0.6;
  }
  @media (max-width: 991px) {
    margin: 15px 15px 0 15px;
  }
`;
