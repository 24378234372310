import React, { useMemo } from 'react';

import { useUser, FIRST_NAME, LAST_NAME } from 'store/session';
import { PROFILE as PROFILE_ROUTE } from 'utils/routes';

import { Button, Text } from './styles';

const ProfileButton = () => {
  const [firstName, lastName] = useUser([FIRST_NAME, LAST_NAME]);

  const shortName = useMemo(
    () =>
      [firstName, lastName]
        .filter(Boolean)
        .map((name) => name.slice(0, 1))
        .join(''),
    [firstName, lastName]
  );

  return (
    <Button to={PROFILE_ROUTE}>
      {shortName && <Text>{shortName}</Text>}
      <Text>{`${firstName} ${lastName}`}</Text>
    </Button>
  );
};

export default ProfileButton;
