import { combineReducers } from 'redux';

import { reducerListTemplate, reducerFiltersTemplate } from 'store/utils';

import {
  ID,
  LIST,
  FILTERS,
  SEARCH,
  PAGINATION,
  COUNT,
  HAS_MORE,
  START_AFTER,
  LIMIT,
  SORT,
  SORT_BY,
  SORT_DIR,
  DATE,
  DESC,
} from '.';
import { UPDATE_PROJECTS, UPDATE_SEARCH, UPDATE_PAGINATION, UPDATE_SORT, RESET_FILTERS } from './types';

const projectsReducer = reducerListTemplate(
  {
    id: ID,
    list: UPDATE_PROJECTS,
  },
  {}
);

const initFilter = {
  [SEARCH]: '',
  [SORT]: {
    [SORT_BY]: DATE,
    [SORT_DIR]: DESC,
  },
  [PAGINATION]: {
    [START_AFTER]: 0,
    [LIMIT]: 50,
    [COUNT]: 0,
    [HAS_MORE]: false,
  },
};

const filterReducer = reducerFiltersTemplate(
  {
    search: UPDATE_SEARCH,
    sort: UPDATE_SORT,
    pagination: UPDATE_PAGINATION,
    reset: RESET_FILTERS,
  },
  initFilter
);

export default combineReducers({
  [LIST]: projectsReducer,
  [FILTERS]: filterReducer,
});
