import {
  INTERN_USER,
  ADMIN,
  INTERNAL,
  REGISTRAR,
  REGION,
  CONSULTANT,
  REGION_MANAGER,
  EPIDEMIOLOGY,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  REDIRECT,
  USER,
  ORGANIZATIONS,
  REGIONS,
  CARE_PROVIDERS,
  SCENARIOS,
  TEAM,
  CARE_PROVIDER,
  DASHBOARD,
  CANTONS,
  COUNTRIES,
  PROJECTS,
  BUG_REPORT,
} from './consts';

const common = {
  [USER]: {
    [READ]: true,
    [UPDATE]: true,
    [REDIRECT]: true,
  },
  [DASHBOARD]: {
    [REDIRECT]: true,
  },
  [TEAM]: {
    [REDIRECT]: true,
  },
  [CANTONS]: {
    [READ]: true,
  },
  [COUNTRIES]: {
    [READ]: true,
  },
  [PROJECTS]: {
    [READ]: true,
    [REDIRECT]: true,
  },
  [BUG_REPORT]: {
    [CREATE]: true,
    [REDIRECT]: true,
  },
};

export default {
  [INTERN_USER]: {
    ...common,
    [CARE_PROVIDERS]: {
      [REDIRECT]: true,
    },
    [SCENARIOS]: {
      [CREATE]: true,
      [READ]: true,
      [REDIRECT]: true,
    },
    [CARE_PROVIDER]: {
      [REDIRECT]: true,
    },
  },
  [ADMIN]: common,
  [INTERNAL]: common,
  [REGISTRAR]: common,
  [REGION]: {
    ...common,
    [CARE_PROVIDERS]: {
      [REDIRECT]: true,
    },
    [SCENARIOS]: {
      [CREATE]: true,
      [READ]: true,
      [REDIRECT]: true,
    },
    [CARE_PROVIDER]: {
      [REDIRECT]: true,
    },
    [REGIONS]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
      [DELETE]: true,
      [REDIRECT]: true,
    },
    [ORGANIZATIONS]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
      [DELETE]: true,
    },
  },
  [CONSULTANT]: {
    ...common,
    [ORGANIZATIONS]: {
      [READ]: true,
    },
  },
  [REGION_MANAGER]: {
    ...common,
  },
  [EPIDEMIOLOGY]: {
    ...common,
    [DASHBOARD]: {
      [REDIRECT]: true,
    },
    [REGIONS]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
      [DELETE]: true,
      [REDIRECT]: true,
    },
  },
};
