import React, { useRef, useCallback } from 'react';

import { Text, Button } from '../styles';

import { Container } from './styles';

const UpdateNotification = () => {
  const containerRef = useRef();
  const handleClick = useCallback(() => {
    containerRef.current.style.display = 'none';
    window.location.reload();
  }, []);

  return (
    <Container id="sw-notification" ref={containerRef}>
      <Text>Es ist eine neue Version von CuraData verfügbar.</Text>
      <Button id="sw-reload" onClick={handleClick}>
        Jetzt installieren
      </Button>
    </Container>
  );
};

export default UpdateNotification;
