import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_SCENARIOS,
  UPDATE_SCENARIO,
  DELETE_SCENARIO,
  RESET_SCENARIOS,
  UPDATE_SEARCH,
  UPDATE_PAGINATION,
  UPDATE_SORT,
  RESET_FILTERS,
  FETCH_SCENARIOS,
  FETCH_SCENARIO,
  CREATE_SCENARIO,
  SAVE_SCENARIO,
  REMOVE_SCENARIO,
  CREATE_SERVICE,
  SAVE_SERVICE,
  REMOVE_SERVICE,
  FETCH_SCENARIO_COMPLEX_CARE,
  FETCH_SCENARIO_EYE_DISEASES,
  FETCH_SCENARIO_POPULATION,
  FETCH_SCENARIO_OUTPATIENT_DATA,
  FETCH_SCENARIO_REPORTS,
  FETCH_SCENARIO_REPORT,
  CREATE_SCENARIO_REPORT,
} from './types';

export const updateScenarios = createAction(UPDATE_SCENARIOS);
export const updateScenario = createAction(UPDATE_SCENARIO);
export const deleteScenario = createAction(DELETE_SCENARIO);
export const resetScenarios = createAction(RESET_SCENARIOS);
export const updateSearch = createAction(UPDATE_SEARCH);
export const updatePagination = createAction(UPDATE_PAGINATION);
export const updateSort = createAction(UPDATE_SORT);
export const resetFilters = createAction(RESET_FILTERS);

export const fetchScenarios = createAlertAction(FETCH_SCENARIOS);
export const fetchScenario = createAlertAction(FETCH_SCENARIO);
export const createScenario = createAlertAction(CREATE_SCENARIO);
export const saveScenario = createAlertAction(SAVE_SCENARIO);
export const removeScenario = createAlertAction(REMOVE_SCENARIO);
export const createService = createAlertAction(CREATE_SERVICE);
export const saveService = createAlertAction(SAVE_SERVICE);
export const removeService = createAlertAction(REMOVE_SERVICE);
export const fetchScenarioComplexCare = createAlertAction(FETCH_SCENARIO_COMPLEX_CARE);
export const fetchScenarioEyeDiseases = createAlertAction(FETCH_SCENARIO_EYE_DISEASES);
export const fetchScenarioPopulation = createAlertAction(FETCH_SCENARIO_POPULATION);
export const fetchScenarioOutpatientData = createAlertAction(FETCH_SCENARIO_OUTPATIENT_DATA);
export const fetchScenarioReports = createAlertAction(FETCH_SCENARIO_REPORTS);
export const fetchScenarioReport = createAlertAction(FETCH_SCENARIO_REPORT);
export const createScenarioReport = createAlertAction(CREATE_SCENARIO_REPORT);
