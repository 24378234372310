import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';

import { StyledButton } from './styles';

const Button = forwardRef(({ type, isLoading, disabled, secondary, warn, children, ...rest }, ref) => (
  <StyledButton ref={ref} type={type} disabled={Boolean(disabled || isLoading)} $secondary={secondary} $warn={warn} {...rest}>
    {isLoading ? <Spinner height="40px" /> : children}
  </StyledButton>
));

Button.defaultProps = {
  type: 'button',
  isLoading: false,
  disabled: false,
  secondary: false,
  warn: false,
};
Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  warn: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Button;
