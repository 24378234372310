import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  AUTHENTICATE,
  AUTHENTICATE_2FA,
  LOGOUT,
  UPDATE_USER_DATA,
  LOGIN,
  LOGIN_2FA,
  RESTORE,
  FETCH_RESET_LINK,
  RESET_PASSWORD,
  SAVE_USER_DATA,
  SAVE_USER_PASSWORD,
  CREATE_2FA,
  VALIDATE_2FA,
  DELETE_2FA,
  SUBSCRIBE_NEWSLETTER,
} from './types';

export const authenticate = createAction(AUTHENTICATE);
export const authenticate2FA = createAction(AUTHENTICATE_2FA);
export const logout = createAction(LOGOUT);
export const updateUserData = createAction(UPDATE_USER_DATA);

export const login = createAlertAction(LOGIN);
export const login2FA = createAlertAction(LOGIN_2FA);
export const restore = createAlertAction(RESTORE);
export const fetchResetLink = createAlertAction(FETCH_RESET_LINK);
export const resetPassword = createAlertAction(RESET_PASSWORD);
export const saveUserData = createAlertAction(SAVE_USER_DATA);
export const saveUserPassword = createAlertAction(SAVE_USER_PASSWORD);
export const create2FA = createAlertAction(CREATE_2FA);
export const validate2FA = createAlertAction(VALIDATE_2FA);
export const delete2FA = createAlertAction(DELETE_2FA);
export const subscribeNewsletter = createAlertAction(SUBSCRIBE_NEWSLETTER);
