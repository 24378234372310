import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link as LinkDef } from 'react-router-dom';

import { fontStyles, palette } from 'theme';

export const Button = styled.button`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 33px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  box-sizing: border-box;
  &:disabled {
    color: ${palette.black};
    border-top: none;
  }
  @media (max-width: 991px) {
    grid-template-rows: 44px;
    border-top: 2px solid ${palette.black};
    margin-bottom: 0;
    padding-top: 2px;
  }
`;

export const Title = styled.h1`
  ${fontStyles.textUpper}
  width: max-content;
  justify-self: start;
  color: ${palette.black};
`;

export const Wrapper = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: {
    height: $isOpen ? 'auto' : 0,
    display: $isOpen ? 'flex' : 'none',
  },
  transition: {
    height: { duration: 0.3 },
    display: { delay: $isOpen ? 0 : 0.3 },
  },
  initial: {
    height: $isOpen ? 'auto' : 0,
    display: $isOpen ? 'flex' : 'none',
  },
}))`
  flex-direction: column;
  overflow: hidden;
`;

export const SubscribeWrap = styled.div`
  border-top: 2px solid ${palette.black};
  padding-top: 15px;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const Link = styled(LinkDef)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 100%;
  border: 2px solid ${palette.black};
  background-color: ${palette.black};
  color: ${palette.white};
  ${fontStyles.titleH3}
  text-decoration: none;
  &:active {
    background-color: ${palette.yellow};
    color: ${palette.black};
  }
  @media (hover) {
    &:hover {
      background-color: ${palette.yellow};
      color: ${palette.black};
    }
  }
`;

export const IconWrap = styled.div`
  display: ${({ $visible }) => ($visible ? 'block' : 'none')};
  height: 24px;
  @media (min-width: 992px) {
    display: none;
  }
`;
