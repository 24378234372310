import React from 'react';
import PropTypes from 'prop-types';

import { useLoggedIn } from 'store/session';

import Header from './Header';
import Footer from './Footer';
import { Main } from './styles';

const Layout = ({ children }) => {
  const isLoggedIn = useLoggedIn();

  return (
    <>
      <Header isPrivate={isLoggedIn} />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};
Layout.propTypes = { children: PropTypes.node.isRequired };

export default Layout;
