import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_CONFIG,
  UPDATE_DEMENTIA_POPULATIONS,
  RESET_DEMENTIA_POPULATIONS,
  UPDATE_DEMENTIA_POPULATION,
  DELETE_DEMENTIA_POPULATION,
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATION,
  FETCH_DEMENTIA_POPULATION,
  FETCH_ACCOUNT_ORGANIZATIONS,
  SAVE_ORGANIZATION,
} from './types';

export const updateOrganization = createAction(UPDATE_ORGANIZATION);
export const updateOrganizationConfig = createAction(UPDATE_ORGANIZATION_CONFIG);
export const updateDementiaPopulations = createAction(UPDATE_DEMENTIA_POPULATIONS);
export const resetDementiaPopulations = createAction(RESET_DEMENTIA_POPULATIONS);
export const updateDementiaPopulation = createAction(UPDATE_DEMENTIA_POPULATION);
export const deleteDementiaPopulation = createAction(DELETE_DEMENTIA_POPULATION);

export const fetchOrganizations = createAlertAction(FETCH_ORGANIZATIONS);
export const fetchOrganization = createAlertAction(FETCH_ORGANIZATION);
export const fetchDementiaPopulation = createAlertAction(FETCH_DEMENTIA_POPULATION);
export const fetchAccountOrganizations = createAlertAction(FETCH_ACCOUNT_ORGANIZATIONS);
export const saveOrganization = createAlertAction(SAVE_ORGANIZATION);
