import { handleActions } from 'redux-actions';

import { UPDATE_CANTONS, RESET_CANTONS } from './types';

export default handleActions(
  {
    [UPDATE_CANTONS]: (state, { payload }) => payload,
    [RESET_CANTONS]: () => ({}),
  },
  {}
);
