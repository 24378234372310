import styled from 'styled-components';

import { palette } from 'theme';

export const ExternWrap = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: ${palette.white};
  padding: 58px 15px 50px 15px;
  overflow: hidden;
  @media (max-width: 991px) {
    padding: 5px 15px 50px 15px;
  }
`;

export const FullScreenWrap = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 0 20px 60px;
  overflow: auto; // TODO scrollX appears at dashboards page if infographic hover labels go out container
  box-sizing: border-box;

  @media (max-width: 991px) {
    padding: 0 15px 60px;
  }
`;

export const MapWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;
