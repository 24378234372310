import { combineReducers } from 'redux';

import { reducerListTemplate, reducerFiltersTemplate } from 'store/utils';

import {
  ID,
  LIST,
  FILTERS,
  SEARCH,
  PAGINATION,
  COUNT,
  HAS_MORE,
  START_AFTER,
  LIMIT,
  SORT,
  SORT_BY,
  SORT_DIR,
  READ,
  DESC,
} from '.';
import {
  UPDATE_SCENARIOS,
  UPDATE_SCENARIO,
  DELETE_SCENARIO,
  RESET_SCENARIOS,
  UPDATE_SEARCH,
  UPDATE_PAGINATION,
  UPDATE_SORT,
  RESET_FILTERS,
} from './types';

const regionsReducer = reducerListTemplate(
  {
    id: ID,
    list: UPDATE_SCENARIOS,
    item: UPDATE_SCENARIO,
    deleteItem: DELETE_SCENARIO,
    reset: RESET_SCENARIOS,
  },
  {}
);

const initFilter = {
  [SEARCH]: '',
  [SORT]: {
    [SORT_BY]: READ,
    [SORT_DIR]: DESC,
  },
  [PAGINATION]: {
    [START_AFTER]: 0,
    [LIMIT]: 10,
    [COUNT]: 0,
    [HAS_MORE]: false,
  },
};

const filterReducer = reducerFiltersTemplate(
  {
    search: UPDATE_SEARCH,
    sort: UPDATE_SORT,
    pagination: UPDATE_PAGINATION,
    reset: RESET_FILTERS,
  },
  initFilter
);

export default combineReducers({
  [LIST]: regionsReducer,
  [FILTERS]: filterReducer,
});
