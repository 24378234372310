import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { fontStyles, palette } from 'theme';

const active = `
  &>p:first-child {
    margin-right: 2px;
  }
  &>p:last-child {
    border-left: none;
    background-color: ${palette.black};
    color: ${palette.white};
  }
`;

export const Button = styled(NavLink)`
  display: flex;
  height: 33px;
  margin-right: 60px;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  &.active {
    ${active}
  }
  @media (hover) {
    &:hover {
      ${active}
    }
  }
  @media (max-width: 1400px) {
    margin-right: 20px;
  }
  @media (max-width: 1300px) {
    margin-right: 10px;
  }
  @media (max-width: 1200px) {
    margin-right: 5px;
  }
`;

export const Text = styled.p`
  box-sizing: border-box;
  padding: 1px 10px 0;
  ${fontStyles.textUpper}
  line-height: 28px;
  &:first-child {
    background-color: ${palette.black};
    color: ${palette.white};
    border: 2px solid ${palette.black};
    margin-right: 0;
  }
  &:last-child {
    border: 2px solid ${palette.black};
    background-color: ${palette.white};
    color: ${palette.black};
  }
  @media (max-width: 1200px) {
    font-size: 16px;
    padding: 1px 5px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
