import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_TEAM = getName('UPDATE_TEAM');
export const RESET_TEAM = getName('RESET_TEAM');
export const UPDATE_MEMBER = getName('UPDATE_MEMBER');
export const DELETE_MEMBER = getName('DELETE_MEMBER');

export const FETCH_TEAM = getName('FETCH_TEAM');
export const CREATE_MEMBER = getName('CREATE_MEMBER');
export const SAVE_MEMBER = getName('SAVE_MEMBER');
export const REMOVE_MEMBER = getName('REMOVE_MEMBER');
