export const FEATURE_NAME = 'application';

export const SEEN_ANIMATION = 'seenAnimation';
export const POPULATION_ID = 'population';
export const ELDERLY_ID = 'elderly';
export const DEMENTIA_ID = 'dementia';
export const DOCTOR_ID = 'doctor-density';
export const SCENARIO_ID = 'scenario';
export const CERTIFICATE_KIND = 'certificateKind';

export const MESSAGE = 'message';
export const COPY = 'copy';
export const MEDIA = 'media';
export const DEMO = 'demo';
export const CONTACT = 'contact';
export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const INSTITUTION = 'institution';
export const EMAIL = 'email';
export const DEMENTIA = 'dementia';
export const YEAR = 'year';
export const DOCUMENT = 'document';
export const DOCUMENT_ID = 'documentId';
export const SUBSCRIBED = 'subscribed';
export const DATA = 'data';
export const CANTON = 'canton';
export const AGE_FROM = 'ageFrom';
export const AGE_TO = 'ageTo';
export const VALUE = 'value';
export const PLUS_65 = 'plus65';
export const PLUS_80 = 'plus80';
export const PLUS_85 = 'plus85';
export const TOTAL_POPULATION = 'totalPopulation';
export const SHORT_NAME = 'shortname';
export const TOPOLOGY = 'topo';
export const PHYSICIANS = 'physicians';
export const RETIRED_PHYSICIANS = 'retiredPhysicians';
export const INPATIENT_CASES = 'inpatientCases';
export const ZIP_CODE = 'zipCode';

export const DOCTOR_SPECIALIZATIONS = [
  { id: 1019, label: 'Allergische und immunologische Krankheiten' },
  { id: 1012, label: 'Anästhesiologie' },
  { id: 1041, label: 'Angiologie' },
  { id: 1018, label: 'Blasen-, Nieren- und Prostatakrankheiten (Urologie)' },
  { id: 1024, label: 'Blutkrankheiten (Hämatologie)' },
  { id: 1013, label: 'Gehirn- und Nervenchirurgie (Neurochirurgie)' },
  { id: 1004, label: 'Herz- und thorakale Gefässchirurgie' },
  { id: 1027, label: 'Kinder- und Jugendpsychiatrie' },
  { id: 1029, label: 'Klinische Pharmakologie und Toxikologie' },
  { id: 1008, label: 'Ohren-, Nasen und Halskrankheiten' },
  { id: 1007, label: 'Augenkrankheiten (Ophthalmologie)' },
  { id: 1046, label: 'Rechtsmedizin' },
  { id: 1023, label: 'Magen- und Darmkrankheiten (Gastroenterologie)' },
  { id: 1051, label: 'Allgemeine Innere Medizin' },
  { id: 1040, label: 'Praktische Ärzte' },
  { id: 1003, label: 'Frauenkrankheiten und Geburtshilfe (Gynäkologie)' },
  { id: 1059, label: 'Handchirurgie' },
  { id: 1025, label: 'Herzkrankheiten (Kardiologie)' },
  { id: 1022, label: 'Hormonkrankheiten und Diabetes (Endokrinologie-Diabetologie)' },
  { id: 1039, label: 'Infektionskrankheiten (Infektiologie)' },
  { id: 1042, label: 'Intensivmedizin' },
  { id: 1033, label: 'Nierenkrankheiten (Nephrologie)' },
  { id: 1016, label: 'Lungenkrankheiten (Pneumologie)' },
  { id: 1043, label: 'Medizinische Genetik' },
  { id: 1014, label: 'Nervenkrankheiten (Neurologie)' },
  { id: 1031, label: 'Nuklearmedizin' },
  { id: 1020, label: 'Arbeitsmedizin' },
  { id: 1026, label: 'Mund-, Kiefer- und Gesichtschirurgie' },
  { id: 1015, label: 'Orthopädische Chirurgie und Traumatologie des Bewegungsapparates' },
  { id: 1028, label: 'Kinderchirurgie' },
  { id: 1006, label: 'Kinder- und Jugendmedizin' },
  { id: 1009, label: 'Pathologie' },
  { id: 1045, label: 'Pharmazeutische Medizin' },
  { id: 1034, label: 'Physikalische Medizin und Rehabilitation' },
  { id: 1035, label: 'Plastische, Rekonstruktive und Ästhetische Chirurgie' },
  { id: 1036, label: 'Prävention und Public Health' },
  { id: 1017, label: 'Psychiatrie und Psychotherapie' },
  { id: 1032, label: 'Radio-Onkologie_Strahlentherapie' },
  { id: 1030, label: 'Radiologie' },
  { id: 1037, label: 'Rheumaerkrankungen (Rheumatologie)' },
  { id: 1021, label: 'Haut- und Geschlechtskrankheiten (Dermatologie und Venerologie)' },
  { id: 1002, label: 'Chirurgie' },
  { id: 1061, label: 'Thoraxchirurgie' },
  { id: 1038, label: 'Tropen- und Reisemedizin' },
  { id: 1044, label: 'Tumorerkrankungen (Medizinische Onkologie)' },
  { id: 1060, label: 'Gefässchirurgie' },
];
