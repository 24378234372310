import { useMemo, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ID, SUB_ORGANIZATION, KIND, SEARCH } from 'api/consts';
import { useDashboardTabs } from 'permissions';
import { wrapActions, wrapSelector, wrapMultiSelector, wrapSelectorWithArg } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { mergeAlerts } from 'store/alerts';
import { useLoggedIn, useUser } from 'store/session';

import {
  getInpatient,
  getInpatientSummary,
  getOutpatient,
  getOutpatientSummary,
  getCareProviderProp,
  getCareProvider,
  getDashboard,
  getDashboards,
  getDashboardsFilters,
  getDashboardSettings,
  getCantonsMapProp,
  getCantonsMap,
  getSelectedCanton,
} from './selectors';
import * as actions from './actions';

export const useHealthcareActions = wrapActions({ ...actions });

export const useInpatient = wrapSelector(getInpatient);
export const useInpatientSummary = wrapSelector(getInpatientSummary);
export const useOutpatient = wrapSelector(getOutpatient);
export const useOutpatientSummary = wrapSelector(getOutpatientSummary);
export const useCantonsMap = wrapMultiSelector(getCantonsMapProp, getCantonsMap);
export const useSelectedCanton = wrapSelector(getSelectedCanton);

export const useInitHealthcare = () => {
  const loggedIn = useLoggedIn();
  const organizationId = useUser(SUB_ORGANIZATION);
  const orgIdRef = useRef(organizationId);
  const dashboardTabs = useDashboardTabs();
  const dashboardKind = loggedIn ? dashboardTabs[0] : null;

  const {
    updateHealthcareServicesFor,
    fetchInpatient,
    fetchInpatientSummary,
    fetchOutpatient,
    fetchOutpatientSummary,
    fetchDashboards,
    updateDashboardsFilters,
  } = useHealthcareActions();
  const inpatientAlerts = useAlerts(fetchInpatient);
  const inpatientSumAlerts = useAlerts(fetchInpatientSummary);
  const outpatientAlerts = useAlerts(fetchOutpatient);
  const outpatientSumAlerts = useAlerts(fetchOutpatientSummary);
  const dashboardsAlerts = useAlerts(fetchDashboards);

  const { action: inpatientAction, id: inpatientId } = inpatientAlerts;
  const { action: inpatientSumAction, id: inpatientSumId } = inpatientSumAlerts;
  const { action: outpatientAction, id: outpatientId } = outpatientAlerts;
  const { action: outpatientSumAction, id: outpatientSumId } = outpatientSumAlerts;
  const { action: dashboardsAction, id: dashboardsId } = dashboardsAlerts;

  useEffect(() => {
    if (dashboardKind) updateDashboardsFilters({ [KIND]: dashboardKind, [SEARCH]: '' });
  }, [dashboardKind, updateDashboardsFilters]);

  useEffect(() => {
    if (!loggedIn) return;
    if (!inpatientId || organizationId !== orgIdRef.current) inpatientAction();
    if (!inpatientSumId || organizationId !== orgIdRef.current) inpatientSumAction();
    if (!outpatientId || organizationId !== orgIdRef.current) outpatientAction();
    if (!outpatientSumId || organizationId !== orgIdRef.current) outpatientSumAction();
    if (!dashboardsId || organizationId !== orgIdRef.current) dashboardsAction();

    orgIdRef.current = organizationId;
  }, [
    dashboardsAction,
    dashboardsId,
    inpatientAction,
    inpatientId,
    inpatientSumAction,
    inpatientSumId,
    loggedIn,
    organizationId,
    outpatientAction,
    outpatientId,
    outpatientSumAction,
    outpatientSumId,
    updateHealthcareServicesFor,
  ]);

  return useMemo(
    () =>
      loggedIn
        ? mergeAlerts([inpatientAlerts, inpatientSumAlerts, outpatientAlerts, outpatientSumAlerts, dashboardsAlerts])
        : { success: true },
    [loggedIn, inpatientAlerts, inpatientSumAlerts, outpatientAlerts, outpatientSumAlerts, dashboardsAlerts]
  );
};

export const useCareProvider = wrapMultiSelector(getCareProviderProp, getCareProvider);

export const useInitCareProvider = (careProviderId) => {
  const currentId = useCareProvider(ID);
  const { fetchCareProvider, updateCareProvider } = useHealthcareActions();
  const { action, loading, success, error } = useAlerts(fetchCareProvider);

  useEffect(() => {
    if (!(loading || error) && careProviderId !== currentId) {
      action(careProviderId);

      return updateCareProvider;
    }

    return () => null;
  }, [action, careProviderId, currentId, loading, error, updateCareProvider]);

  return { loading: !currentId || loading, success, error };
};

export const useDashboards = wrapMultiSelector(getDashboard, getDashboards);
export const useDashboardSettings = wrapSelectorWithArg(getDashboardSettings);

export const useDashboardsFilters = () => {
  const { [KIND]: kind, [SEARCH]: search } = useSelector(getDashboardsFilters);
  const { fetchDashboards, updateDashboardsFilters } = useHealthcareActions();
  const { action, loading, error } = useAlerts(fetchDashboards);

  const updateKind = useCallback(
    (k) => {
      action({ [KIND]: k, [SEARCH]: search });
      updateDashboardsFilters({ [KIND]: k, [SEARCH]: search });
    },
    [action, search, updateDashboardsFilters]
  );

  const updateSearch = useCallback(
    (s) => {
      action({ [KIND]: kind, [SEARCH]: s });
      updateDashboardsFilters({ [KIND]: kind, [SEARCH]: s });
    },
    [action, kind, updateDashboardsFilters]
  );

  return { kind, updateKind, search, updateSearch, loading, error };
};
