import 'react-app-polyfill/stable';
import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Provider } from 'react-redux';
import { StyleSheetManager } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';

import 'locale';
import store from 'store/store';
import { isPrerender } from 'utils/helper';
import Root from 'components/Root';
import FallbackComponent from 'components/Error';
import packageJson from '../package.json';

import * as serviceWorker from './serviceWorkerRegistration';

const allowBugSnag = process.env.REACT_APP_TYPE === 'production' && process.env.REACT_APP_BUGSNAG_API_KEY;
if (allowBugSnag) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    appType: 'client',
    appVersion: packageJson.version,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production'],
  });
}
const ErrorBoundary = allowBugSnag ? Bugsnag.getPlugin('react').createErrorBoundary(React) : Fragment;
const bugsnagProps = allowBugSnag ? { FallbackComponent } : {};

createRoot(document.getElementById('root')).render(
  <ErrorBoundary {...bugsnagProps}>
    <Provider store={store}>
      <StyleSheetManager disableCSSOMInjection={isPrerender}>
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </StyleSheetManager>
    </Provider>
  </ErrorBoundary>
);

if (process.env.REACT_APP_TYPE === 'production' && process.env.REACT_APP_GA_KEY) {
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
}

serviceWorker.register({
  onUpdate: (event) => {
    const notification = document.getElementById('sw-notification');
    const button = document.getElementById('sw-reload');

    if (notification && button) {
      notification.style.display = 'flex';
      button.addEventListener('click', () => event.postMessage({ type: 'SKIP_WAITING' }));
    }
  },
  score: '/',
});
