import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_INPATIENT = getName('UPDATE_INPATIENT');
export const UPDATE_INPATIENT_SUMMARY = getName('UPDATE_INPATIENT_SUMMARY');
export const UPDATE_OUTPATIENT = getName('UPDATE_OUTPATIENT');
export const UPDATE_OUTPATIENT_SUMMARY = getName('UPDATE_OUTPATIENT_SUMMARY');
export const UPDATE_CARE_PROVIDER = getName('UPDATE_CARE_PROVIDER');
export const UPDATE_DASHBOARDS = getName('UPDATE_DASHBOARDS');
export const UPDATE_DASHBOARDS_FILTERS = getName('UPDATE_DASHBOARDS_FILTERS');
export const UPDATE_CANTONS_MAP = getName('UPDATE_CANTONS_MAP');
export const UPDATE_SELECTED_CANTON = getName('UPDATE_SELECTED_CANTON');

export const FETCH_INPATIENT = getName('FETCH_INPATIENT');
export const FETCH_INPATIENT_SUMMARY = getName('FETCH_INPATIENT_SUMMARY');
export const FETCH_OUTPATIENT = getName('FETCH_OUTPATIENT');
export const FETCH_OUTPATIENT_SUMMARY = getName('FETCH_OUTPATIENT_SUMMARY');
export const FETCH_CARE_PROVIDERS = getName('FETCH_CARE_PROVIDERS');
export const FETCH_CARE_PROVIDER = getName('FETCH_CARE_PROVIDER');
export const CREATE_CARE_PROVIDER = getName('CREATE_CARE_PROVIDER');
export const SAVE_CARE_PROVIDER = getName('SAVE_CARE_PROVIDER');
export const REMOVE_CARE_PROVIDER = getName('REMOVE_CARE_PROVIDER');
export const CREATE_SERVICE = getName('CREATE_SERVICE');
export const SAVE_SERVICE = getName('SAVE_SERVICE');
export const REMOVE_SERVICE = getName('REMOVE_SERVICE');
export const FETCH_DASHBOARDS = getName('FETCH_DASHBOARDS');
export const FETCH_DASHBOARD = getName('FETCH_DASHBOARD');
export const SAVE_DASHBOARD = getName('SAVE_DASHBOARD');
export const UPDATE_DASHBOARD_SETTINGS = getName('UPDATE_DASHBOARD_SETTINGS');
export const FETCH_CANTONS_MAP = getName('FETCH_CANTONS_MAP');
