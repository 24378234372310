import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { reducerListTemplate } from 'store/utils';

import { ID, CURRENT, CONFIG, DEMENTIA } from '.';
import {
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_CONFIG,
  UPDATE_DEMENTIA_POPULATIONS,
  RESET_DEMENTIA_POPULATIONS,
  UPDATE_DEMENTIA_POPULATION,
  DELETE_DEMENTIA_POPULATION,
} from './types';

export default combineReducers({
  [CURRENT]: handleActions({ [UPDATE_ORGANIZATION]: (state, { payload }) => payload }, {}),
  [CONFIG]: handleActions({ [UPDATE_ORGANIZATION_CONFIG]: (state, { payload }) => payload }, {}),
  [DEMENTIA]: reducerListTemplate(
    {
      id: ID,
      list: UPDATE_DEMENTIA_POPULATIONS,
      item: UPDATE_DEMENTIA_POPULATION,
      deleteItem: DELETE_DEMENTIA_POPULATION,
      reset: RESET_DEMENTIA_POPULATIONS,
    },
    {}
  ),
});
