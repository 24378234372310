import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useMobileDetect } from 'hooks/useMobileDetect';
import { NEWSLETTER } from 'utils/routes';

import { ReactComponent as PlusIcon } from './plus.svg';
import { ReactComponent as MinusIcon } from './minus.svg';
import { Button, Title, Wrapper, SubscribeWrap, IconWrap, Link } from './styles';

const Content = ({ title, children, lastItem }) => {
  const [isOpen, setIsOpen] = useState();
  const isMobile = useMobileDetect();
  const onClick = useCallback(() => setIsOpen(($) => !$), []);

  return (
    <div>
      {lastItem && (
        <SubscribeWrap>
          <Link to={NEWSLETTER}>NEWSLETTER ANMELDEN</Link>
        </SubscribeWrap>
      )}
      <Button type="button" onClick={onClick} disabled={lastItem}>
        {isMobile && lastItem && children}
        <Title>{title}</Title>
        <IconWrap $visible={!lastItem}>{isOpen ? <MinusIcon /> : <PlusIcon />}</IconWrap>
      </Button>
      {(!isMobile || (isMobile && !lastItem)) && (
        <Wrapper key={isMobile} $isOpen={!isMobile || isOpen}>
          {children}
        </Wrapper>
      )}
    </div>
  );
};

Content.defaultProps = { lastItem: false };
Content.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  lastItem: PropTypes.bool,
};

export default Content;
