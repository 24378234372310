import { takeLatest, takeLeading, call, select, put, all } from 'redux-saga/effects';

import { withAlert, applyCancelToken } from 'store/alerts';
import api from 'api';

import {
  CREATE_BUG_REPORT,
  SEND_CONTACT,
  UPLOAD_MEDIA,
  DOWNLOAD_XLSX,
  FETCH_PUBLIC_LOCALITIES,
  FETCH_PUBLIC_POPULATION,
  FETCH_PUBLIC_ELDERLY,
  FETCH_PUBLIC_DEMENTIA,
  FETCH_PUBLIC_DOCTOR_DENSITY,
  FETCH_PUBLIC_SCENARIO,
  DOWNLOAD_PUBLIC_SCENARIO,
  FETCH_INPATIENT_ESTIMATES,
} from './types';
import {
  getPublicPopulation,
  getPublicElderly,
  getPublicDementia,
  getPublicScenario,
  getTopology,
  getPublicDoctorDensity,
} from './selectors';
import {
  updatePublicPopulation,
  updatePublicElderly,
  updatePublicDementia,
  updatePublicDoctorDensity,
  updatePublicScenario,
  updateCertificateKind,
  updateTopology,
} from './actions';
import { FIELDS, NAME, CANTON, SORT_BY, SORT_DIR, ASC, CERTIFICATE_KIND, ID, INPATIENT_CASES } from '.';

function* createBugReport({ payload, ...rest }) {
  yield call(api.post, '/bugreports', { applicationToken: process.env.REACT_APP_TOKEN_APP, ...payload }, applyCancelToken(rest));
}

function* sendContact({ payload, ...rest }) {
  yield call(api.post, '/contacts', payload, applyCancelToken(rest));
}

function* uploadMedia({ payload, ...rest }) {
  const { file, onUploadProgress = () => null } = payload;

  const body = new FormData();
  body.append('file', file);

  const data = yield call(api.uploadFile, '/uploads', body, { onUploadProgress, ...applyCancelToken(rest) });

  return { success: data };
}

function* downloadXlsx({ payload, ...rest }) {
  const blob = yield call(api.post, '/xlsxexport', payload, { responseType: 'blob', ...applyCancelToken(rest) });

  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = 'CuraData_Export.xlsx';
  a.click();
}

function* fetchPublicLocalities({ payload, ...rest }) {
  return {
    success: yield call(api.get, '/public/localities', {
      params: { ...payload, [FIELDS]: [NAME, CANTON], [SORT_BY]: NAME, [SORT_DIR]: ASC },
      ...applyCancelToken(rest),
    }),
  };
}

function* handlePublicData(feature, selector, action, payload) {
  const existData = yield select(selector);

  if (existData) return existData;

  const data = yield call(api.get, payload ? `/public/localities/${payload}/${feature}` : `/public/${feature}`);

  yield put(action(data));

  return data;
}

function* fetchPublicPopulation({ payload }) {
  return { success: yield handlePublicData('population', getPublicPopulation, updatePublicPopulation, payload) };
}

function* fetchPublicElderly({ payload }) {
  return { success: yield handlePublicData('elderly', getPublicElderly, updatePublicElderly, payload) };
}

function* fetchPublicDementia({ payload }) {
  return { success: yield handlePublicData('dementia', getPublicDementia, updatePublicDementia, payload) };
}

function* fetchPublicDoctorDensity({ payload }) {
  const existTopo = yield select(getTopology);
  const existData = yield select(getPublicDoctorDensity);

  const [topo, data] = yield all([
    existTopo || call(api.get, '/public/doctor-density/topology'),
    existData || call(api.get, '/public/doctor-density', { params: { [CERTIFICATE_KIND]: payload } }),
  ]);

  if (!existTopo) yield put(updateTopology(topo));
  if (!existData) {
    yield put(updateCertificateKind(data[CERTIFICATE_KIND]));
    yield put(updatePublicDoctorDensity(data));
  }

  return { success: data };
}

function* fetchPublicScenario({ payload }) {
  const existData = yield select(getPublicScenario);

  if (existData) return { success: existData };

  const data = yield call(api.post, payload[ID] ? `/public/localities/${payload[ID]}/scenarios` : '/public/scenarios', {
    [INPATIENT_CASES]: payload[INPATIENT_CASES] || 0,
  });

  yield put(updatePublicScenario(data));

  return { success: data };
}

function* downloadPublicScenario({ payload }) {
  const { [ID]: id } = yield select(getPublicScenario) || {};
  const blob = yield call(
    api.post,
    `/public/localities/${id}/scenarios/downloads`,
    { [INPATIENT_CASES]: payload || 0 },
    { responseType: 'blob' }
  );

  return { success: window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' })) };
}

function* fetchInpatientEstimates({ payload, ...rest }) {
  return {
    success: payload
      ? yield call(api.get, `/public/localities/${payload}/inpatient-estimates`, applyCancelToken(rest))
      : { [INPATIENT_CASES]: 0 },
  };
}

export default function* watchApplication() {
  yield takeLatest(CREATE_BUG_REPORT, withAlert(createBugReport));
  yield takeLatest(SEND_CONTACT, withAlert(sendContact));
  yield takeLatest(UPLOAD_MEDIA, withAlert(uploadMedia));
  yield takeLatest(DOWNLOAD_XLSX, withAlert(downloadXlsx));
  yield takeLatest(FETCH_PUBLIC_LOCALITIES, withAlert(fetchPublicLocalities));
  yield takeLeading(FETCH_PUBLIC_POPULATION, withAlert(fetchPublicPopulation));
  yield takeLeading(FETCH_PUBLIC_ELDERLY, withAlert(fetchPublicElderly));
  yield takeLeading(FETCH_PUBLIC_DEMENTIA, withAlert(fetchPublicDementia));
  yield takeLeading(FETCH_PUBLIC_DOCTOR_DENSITY, withAlert(fetchPublicDoctorDensity));
  yield takeLeading(FETCH_PUBLIC_SCENARIO, withAlert(fetchPublicScenario));
  yield takeLeading(DOWNLOAD_PUBLIC_SCENARIO, withAlert(downloadPublicScenario));
  yield takeLatest(FETCH_INPATIENT_ESTIMATES, withAlert(fetchInpatientEstimates));
}
