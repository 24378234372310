import { throttle, takeLatest, call, put } from 'redux-saga/effects';
import { path, pipe, assocPath } from 'ramda';

import { withAlert, applyCancelToken } from 'store/alerts';
import api from 'api';

import { ID, NAME, DEMENTIA } from '.';
import { updateCantons } from './actions';
import { FETCH_CANTONS_COLLECT, FETCH_CANTON_BY_NAME, FETCH_CANTON_BY_ID } from './types';

function* fetchCantonsCollect({ payload }) {
  const data = yield call(api.get, '/cantons', { params: payload });
  return { success: { data } };
}

function* fetchCantonByName({ payload, ...rest }) {
  const data = yield call(api.get, '/cantons', { params: { [NAME]: payload }, ...applyCancelToken(rest) });
  const cantonId = path([0, ID], data);

  if (cantonId) {
    const dementia = yield call(api.get, `/cantons/${cantonId}/dementia`, applyCancelToken(rest));
    yield pipe(assocPath([0, DEMENTIA], dementia), updateCantons, put)(data);
  } else {
    yield put(updateCantons(data));
  }
}

function* fetchCantonById({ payload, ...rest }) {
  const data = yield call(api.get, `/cantons/${payload}`, applyCancelToken(rest));
  yield put(updateCantons(data));
}

export default function* watchCantons() {
  yield throttle(300, FETCH_CANTONS_COLLECT, withAlert(fetchCantonsCollect));
  yield takeLatest(FETCH_CANTON_BY_NAME, withAlert(fetchCantonByName));
  yield takeLatest(FETCH_CANTON_BY_ID, withAlert(fetchCantonById));
}
