import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 33 32">
    <g fill="none" fillRule="evenodd" stroke="#000" strokeWidth="2">
      <path d="M17.245 -5.4L16.55 36.634" transform="rotate(45 16.898 15.617)" />
      <path d="M37.159 15.27L-4.875 15.965" transform="rotate(45 16.142 15.617)" />
    </g>
  </svg>
);
