import React from 'react';

import BrowserChecker from './BrowserChecker';
import UpdateNotification from './UpdateNotification';
import Warn2FA from './Warn2FA';

const Notifications = () => (
  <div id="app-notifications-container">
    <BrowserChecker />
    <Warn2FA />
    <UpdateNotification />
  </div>
);

export default Notifications;
