import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { fontStyles, palette } from 'theme';

export const Container = styled.div`
  display: flex;
  margin: 15px 20px 0 20px;
  border: 2px solid ${palette.black};
  @media (max-width: 991px) {
    margin: 15px 15px 0 15px;
  }
`;

export const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 33px;
  width: 200px;
  ${fontStyles.textUpper}
  text-decoration: none;
  text-align: center;
  border-left: 2px solid ${palette.black};
  background-color: ${palette.black};
  color: ${palette.white};
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.3s;
  padding: 0 5px;
  @media (hover) {
    &:hover {
      background-color: ${palette.yellow};
      color: ${palette.black};
    }
  }
`;
