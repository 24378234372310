import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_TEAM,
  RESET_TEAM,
  UPDATE_MEMBER,
  DELETE_MEMBER,
  FETCH_TEAM,
  CREATE_MEMBER,
  SAVE_MEMBER,
  REMOVE_MEMBER,
} from './types';

export const updateTeam = createAction(UPDATE_TEAM);
export const resetTeam = createAction(RESET_TEAM);
export const updateMember = createAction(UPDATE_MEMBER);
export const deleteMember = createAction(DELETE_MEMBER);

export const fetchTeam = createAlertAction(FETCH_TEAM);
export const createMember = createAlertAction(CREATE_MEMBER);
export const saveMember = createAlertAction(SAVE_MEMBER);
export const removeMember = createAlertAction(REMOVE_MEMBER);
