import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE } from 'utils/constants';

const deFiles = [
  'common',
  'services',
  'population',
  'contact',
  'register',
  'infrastructure',
  'careprovider',
  'scenarios',
  'forms',
  'imprint',
  'bugreport',
  'privatemenu',
  'profile',
  'team',
];

const resources = {
  de: deFiles.reduce((acc, key) => {
    acc[key] = require(`./de/${key}.json`); // eslint-disable-line import/no-dynamic-require
    return acc;
  }, {}),
};

i18n.use(initReactI18next).init({
  resources,
  defaultNs: 'common',
  lng: DEFAULT_LANGUAGE,
  keySeparator: false,
  nsSeparator: ':',
  interpolation: {
    escapeValue: false,
  },
  debug: process.env.REACT_APP_TYPE !== 'production',
});

export default i18n;
