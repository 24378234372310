import axiosDef from 'axios';
import { omit } from 'ramda';

import cache from './cache';
import { TOKEN, TOKEN_2FA, DEFAULT_ERROR } from './consts';

export const axios = axiosDef.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'api-key': process.env.REACT_APP_TOKEN_APP,
    AUTHORIZATION: `Bearer ${sessionStorage[TOKEN_2FA] || localStorage[TOKEN] || process.env.REACT_APP_DEFAULT_TOKEN}`,
  },
  timeout: 60000,
  validateStatus: (status) => (status >= 200 && status < 300) || status === 403 || status === 401,
});

export const updateToken = (token, token2FA) => {
  if (token) {
    localStorage.setItem(TOKEN, token);
    sessionStorage.removeItem(TOKEN_2FA);
    axios.defaults.headers = {
      ...axios.defaults.headers,
      AUTHORIZATION: `Bearer ${token}`,
    };
  }
  if (token2FA) {
    sessionStorage.setItem(TOKEN_2FA, token2FA);
    axios.defaults.headers = {
      ...axios.defaults.headers,
      AUTHORIZATION: `Bearer ${token2FA}`,
    };
  }
  if (!(token || token2FA)) {
    localStorage.removeItem(TOKEN);
    sessionStorage.removeItem(TOKEN_2FA);
    axios.defaults.headers = {
      ...axios.defaults.headers,
      AUTHORIZATION: `Bearer ${process.env.REACT_APP_DEFAULT_TOKEN}`,
    };
  }
};

const handleResponse = async ({ status, data }, offlineMode) => {
  if (status === 401) {
    updateToken();
    window.location.reload();
  }

  if (status >= 300) {
    const error = new Error(
      (status === 401 && 'Ihre Authentifizierung ist fehlgeschlagen. Bitte melden Sie sich an.') ||
        (status === 403 && data?.error) ||
        DEFAULT_ERROR
    );
    error.status = status;
    throw error;
  }

  if (offlineMode && status >= 200 && status < 300) cache.put(offlineMode, data);

  return data;
};

const handler = async (promise, { url, body, config }) => {
  const offlineMode = (config || {}).offlineMode && (body ? `${url}&${JSON.stringify(body)}` : url);

  if (!window.navigator.onLine && offlineMode) {
    try {
      return await cache.get(offlineMode);
    } catch (e) {
      throw Error('Keine Internetverbindung, keine Daten im Cache');
    }
  }

  try {
    const args = body ? [url, body, config] : [url, config];

    return await handleResponse(await promise(...args), offlineMode);
  } catch (e) {
    if (e?.status) throw Error(e?.message);

    throw Error('Ihre Anfrage ist fehlgeschlagen. Bitte versuchen Sie es erneut oder senden Sie uns einen Fehlerbericht.');
  }
};

const methods = {
  get: (url, config) => handler(axios.get, { url, config }),
  post: (url, body, config) => handler(axios.post, { url, body, config }),
  put: (url, body, config) => handler(axios.put, { url, body, config }),
  patch: (url, body, config) => handler(axios.patch, { url, body, config }),
  delete: (url, config) => handler(axios.delete, { url, config }),
  uploadFile: (url, data, params) =>
    handler(axios.post, url, data, { ...params, headers: omit(['AUTHORIZATION'], axios.defaults.headers) }),
};

export default methods;
