import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { fontStyles, palette } from 'theme';

export const Container = styled.footer`
  flex: 0 0 auto;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  @media (max-width: 1360px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 991px) {
    grid-template-rows: auto;
    padding: 0 15px;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  column-gap: 40px;
  height: 100px;
  width: 100%;
  box-sizing: border-box;
  @media (min-width: 992px) {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 31px;
      left: 0px;
      right: 0px;
      height: 2px;
      background-color: ${palette.black};
    }
  }
  @media (max-width: 1360px) {
    grid-row: 2/3;
    justify-content: space-between;
    column-gap: 10px;
  }
  @media (max-width: 991px) {
    grid-row: 1/2;
    grid-template-columns: 1fr;
    height: auto;
  }
`;

export const StyledLink = styled(NavLink)`
  ${fontStyles.smallText}
  width: fit-content;
  color: ${palette.black};
  text-decoration: none;
  @media (hover) {
    &:hover {
      text-decoration: underline;
    }
  }
  &.active {
    text-decoration: underline;
  }
  @media (max-width: 991px) {
    ${({ $lastItem }) =>
      $lastItem
        ? fontStyles.textUpper
        : `
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 32px;
        align-items: center;
        width: 100%;
        border-top: 2px solid ${palette.black};
        ${fontStyles.text}
        line-height: 1;
    `}
    color: ${palette.black};
    &.active {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;

export const ExternLink = styled.a`
  ${fontStyles.smallText}
  width: fit-content;
  color: ${palette.black};
  text-decoration: none;
  @media (hover) {
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 991px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 32px;
    align-items: center;
    width: 100%;
    border-top: 2px solid ${palette.black};
    ${fontStyles.text}
    line-height: 1;
  }
`;
