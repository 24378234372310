import { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch as useDefDispatch } from 'react-redux';

export { useField } from './useField';

export const useAsyncState = (init) => {
  const isMounted = useRef(true);
  const [state, setState] = useState(init);
  const checkIsMounted = useCallback(() => isMounted.current, []);

  const updateState = useCallback((data) => {
    if (isMounted.current) {
      setState(data);
    }
  }, []);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return [state, updateState, checkIsMounted];
};

export const useDispatch = (actionCreator) => {
  const dispatch = useDefDispatch();

  return useCallback((payload) => dispatch(actionCreator(payload)), [dispatch, actionCreator]);
};
