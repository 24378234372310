import styled from 'styled-components';

export const CenteredContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 90%;
  max-width: 990px;
`;

export const Title = styled.h1`
  font-family: 'SimpleRegular', Helvetica, Arial, sans-serif;
  font-size: 57px;
  text-align: center;
  margin-bottom: 45px;
  @media (max-width: 991px) {
    font-size: 32px;
    margin-bottom: 30px;
  }
`;

export const Text = styled.p`
  font-family: 'SimpleRegular', Helvetica, Arial, sans-serif;
  font-size: 18px;
  text-align: center;
  margin-bottom: 60px;
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
`;

export const Restart = styled.button`
  height: 48px;
  width: fit-content;
  background-color: #000;
  border: 2px solid #000;
  font-family: 'SimpleRegular', Helvetica, Arial, sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  margin: 0 auto;
  padding: 0 30px;
  &:active {
    background-color: #fff;
    color: #000;
  }
  &:disabled {
    background-color: transparent;
    color: #000;
    border: none;
    width: auto;
    cursor: auto;
    text-transform: none;
  }
  @media (hover) {
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
  @media (max-width: 991px) {
    font-size: 18px;
    padding: 0 15px;
  }
`;
