import React from 'react';

import { useSubscribeForm } from 'hooks/useSubscribeForm';
import { PRIVACY } from 'utils/routes';
import CloseIcon from 'assets/svg-icons/CloseIcon';

import { Container, Icon, Title, CloseButton, Form, Input, Button, Text, TextLink } from './styles';

const Subscription = () => {
  const { name, email, loading, success, error, validationError, handleChange, handleSubmit, resetAlerts } = useSubscribeForm();

  return success ? (
    <Container>
      <Icon />
      <Title>Vielen Dank! Sie haben sich für unseren Newsletter angemeldet.</Title>
      <CloseButton type="button" onClick={resetAlerts}>
        <CloseIcon />
      </CloseButton>
    </Container>
  ) : (
    <Form onSubmit={handleSubmit}>
      <Input
        name={name}
        value={email}
        onChange={handleChange}
        error={Boolean(validationError)}
        placeholder="Geben Sie Ihre E-Mail-Adresse für unseren Newsletter ein"
        disabled={loading}
      />
      <Button type="submit">Anmelden</Button>
      <Text $error={error || validationError}>
        {error || validationError || (
          <>
            Wenn Sie auf Abonnieren klicken, erhalten Sie gelegentlich E-Mails von CuraData. Sie haben jederzeit die Möglichkeit,
            sich abzumelden. Durch Absenden stimmen Sie unserer <TextLink to={PRIVACY}>Datenschutzerklärung</TextLink> zu.
          </>
        )}
      </Text>
    </Form>
  );
};

export default Subscription;
