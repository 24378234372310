import { handleActions } from 'redux-actions';
import { assocPath } from 'ramda';

import {
  INPATIENT,
  INPATIENT_SUMMARY,
  OUTPATIENT,
  OUTPATIENT_SUMMARY,
  CARE_PROVIDER,
  DASHBOARDS,
  DASHBOARDS_FILTERS,
  SETTINGS,
  CANTONS_MAP,
  SELECTED_CANTON,
} from './consts';
import {
  UPDATE_INPATIENT,
  UPDATE_INPATIENT_SUMMARY,
  UPDATE_OUTPATIENT,
  UPDATE_OUTPATIENT_SUMMARY,
  UPDATE_CARE_PROVIDER,
  UPDATE_DASHBOARDS,
  UPDATE_DASHBOARD_SETTINGS,
  UPDATE_DASHBOARDS_FILTERS,
  UPDATE_CANTONS_MAP,
  UPDATE_SELECTED_CANTON,
} from './types';
import { KIND, SEARCH } from '.';

const init = {
  [INPATIENT]: [],
  [INPATIENT_SUMMARY]: {},
  [OUTPATIENT]: [],
  [OUTPATIENT_SUMMARY]: {},
  [CARE_PROVIDER]: {},
  [DASHBOARDS]: null,
  [DASHBOARDS_FILTERS]: {
    [KIND]: '',
    [SEARCH]: '',
  },
  [CANTONS_MAP]: null,
};

export default handleActions(
  {
    [UPDATE_INPATIENT]: (state, { payload }) => ({ ...state, [INPATIENT]: payload }),
    [UPDATE_INPATIENT_SUMMARY]: (state, { payload }) => ({ ...state, [INPATIENT_SUMMARY]: payload }),
    [UPDATE_OUTPATIENT]: (state, { payload }) => ({ ...state, [OUTPATIENT]: payload }),
    [UPDATE_OUTPATIENT_SUMMARY]: (state, { payload }) => ({ ...state, [OUTPATIENT_SUMMARY]: payload }),
    [UPDATE_CARE_PROVIDER]: (state, { payload }) => ({ ...state, [CARE_PROVIDER]: payload || {} }),
    [UPDATE_DASHBOARDS]: (state, { payload }) => ({ ...state, [DASHBOARDS]: payload || {} }),
    [UPDATE_DASHBOARD_SETTINGS]: (state, { payload }) => assocPath([DASHBOARDS, payload.type, SETTINGS], payload.data, state),
    [UPDATE_DASHBOARDS_FILTERS]: (state, { payload }) => ({
      ...state,
      [DASHBOARDS_FILTERS]: payload,
    }),
    [UPDATE_CANTONS_MAP]: (state, { payload }) => ({ ...state, [CANTONS_MAP]: payload }),
    [UPDATE_SELECTED_CANTON]: (state, { payload }) => assocPath([CANTONS_MAP, SELECTED_CANTON], payload, state),
  },
  init
);
