import React from 'react';
import { useTranslation } from 'react-i18next';
import Bowser from 'bowser';

import { Message } from './styles';

const bowser = Bowser.getParser(window.navigator.userAgent);

const isValidBrowser = bowser.satisfies({
  chrome: '>=78',
  safari: '>=13',
  edge: '>=79',
  firefox: '>=70',
  opera: '>=65',
});

const isIE = bowser.satisfies({ ie: '<=11' });

const BrowserChecker = () => {
  const { t } = useTranslation('common');

  if (isIE)
    return (
      <Message>
        {t('Outdated Browser')}
        <a target="_blank" rel="noopener noreferrer" href="https://www.mozilla.org/de/firefox/">
          {t('Modern Browser')}
        </a>
        {t('Improve Experience')}
      </Message>
    );

  return isValidBrowser === false && <Message>{t('Update your browser')}</Message>;
};

export default BrowserChecker;
