import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_ORGANIZATION = getName('UPDATE_ORGANIZATION');
export const UPDATE_ORGANIZATION_CONFIG = getName('UPDATE_ORGANIZATION_CONFIG');
export const UPDATE_DEMENTIA_POPULATIONS = getName('UPDATE_DEMENTIA_POPULATIONS');
export const RESET_DEMENTIA_POPULATIONS = getName('RESET_DEMENTIA_POPULATIONS');
export const UPDATE_DEMENTIA_POPULATION = getName('UPDATE_DEMENTIA_POPULATION');
export const DELETE_DEMENTIA_POPULATION = getName('DELETE_DEMENTIA_POPULATION');

export const FETCH_ORGANIZATIONS = getName('FETCH_ORGANIZATIONS');
export const FETCH_ORGANIZATION = getName('FETCH_ORGANIZATION');
export const FETCH_DEMENTIA_POPULATION = getName('FETCH_DEMENTIA_POPULATION');
export const FETCH_ACCOUNT_ORGANIZATIONS = getName('FETCH_ACCOUNT_ORGANIZATIONS');
export const SAVE_ORGANIZATION = getName('SAVE_ORGANIZATION');
