import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="59" height="59" viewBox="0 0 59 59">
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" stroke="#000" strokeLinejoin="round" strokeWidth="2" d="M0 0H57V57H0z" transform="translate(1 1)" />
      <path
        fill="#000"
        fillRule="nonzero"
        d="M39.521 42.448c0 5.455-4.467 9.932-9.911 9.932H27.33c-5.444 0-9.852-4.477-9.852-9.932V14.552c0-5.515 4.408-9.932 9.852-9.932h2.279c5.444 0 9.911 4.417 9.911 9.932v.711c0 .208-.207.385-.384.385h-1.48c-.207 0-.384-.207-.384-.385v-.711c0-4.3-3.432-7.738-7.634-7.738h-2.278c-4.202 0-7.634 3.439-7.634 7.738v27.926c0 4.21 3.432 7.738 7.634 7.738h2.249c4.201 0 7.633-3.499 7.633-7.738v-1.482c0-.208.207-.386.385-.386h1.48c.206 0 .384.208.384.386v1.452h.03z"
        transform="translate(1 1)"
      />
    </g>
  </svg>
);
