import { useCallback } from 'react';
import { useField as useDefField } from 'formik';

export const useField = (...args) => {
  const [field, meta, helpers] = useDefField(...args);

  const onChange = useCallback(
    (e) => {
      field.onChange(e);

      if (meta.error) helpers.setError();
    },
    [field, helpers, meta.error]
  );

  const setValue = useCallback(
    (val) => {
      helpers.setValue(val);

      if (meta.error) helpers.setError();
    },
    [helpers, meta.error]
  );

  return [{ ...field, onChange }, meta, { ...helpers, setValue }];
};
