import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { values } from 'ramda';

import { wrapActions } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { SEARCH, SORT, PAGINATION, LIMIT, START_AFTER } from 'api/consts';
import { usePermissions, PROJECTS } from 'permissions';

import { getProjects, getProjectsFilters } from './selectors';
import * as actions from './actions';

export const useProjectsActions = wrapActions({ ...actions });

export const useInitProjects = () => {
  const can = usePermissions();
  const hasAccess = can.read(PROJECTS);
  const alerts = useAlerts();
  const projects = useSelector(getProjects);
  const list = useMemo(() => values(projects), [projects]);
  const filters = useSelector(getProjectsFilters);
  const search = filters[SEARCH];
  const sort = filters[SORT];
  const pagination = filters[PAGINATION];
  const limit = pagination[LIMIT];
  const startAfter = pagination[START_AFTER];

  const { extractId } = alerts;
  const { fetchProjects, resetProjects } = useProjectsActions();

  useEffect(() => {
    if (!hasAccess) return;
    extractId(
      fetchProjects({
        ...(search && { [SEARCH]: search }),
        [LIMIT]: limit,
        [START_AFTER]: startAfter,
        ...sort,
      })
    );
  }, [hasAccess, extractId, fetchProjects, search, limit, startAfter, sort]);

  useEffect(() => {
    if (!hasAccess) resetProjects();

    return resetProjects;
  }, [hasAccess, resetProjects]);

  return {
    list,
    ...alerts,
    search,
    sort,
    pagination,
  };
};
