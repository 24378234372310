import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { FullScreenWrap, Wrapper, Line } from './styles';

const lines = [1, 2, 3];

const Spinner = ({ height, fullScreen, className }) => {
  const Container = fullScreen ? FullScreenWrap : Fragment;

  return (
    <Container>
      <Wrapper $height={height} className={className}>
        {lines.map((index) => (
          <Line key={index} $index={index} />
        ))}
      </Wrapper>
    </Container>
  );
};

Spinner.defaultProps = {
  height: '60px',
  className: '',
  fullScreen: false,
};

Spinner.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
};

export default Spinner;
