import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_COUNTRIES = getName('UPDATE_COUNTRIES');
export const RESET_COUNTRIES = getName('RESET_COUNTRIES');
export const UPDATE_SEARCH = getName('UPDATE_SEARCH');
export const UPDATE_PAGINATION = getName('UPDATE_PAGINATION');
export const UPDATE_SORT = getName('UPDATE_SORT');
export const RESET_FILTERS = getName('RESET_FILTERS');

export const FETCH_COUNTRIES = getName('FETCH_COUNTRIES');
