import * as PERMISSIONS from 'permissions';

import {
  INDEX,
  SOLUTION,
  CUSTOMERS,
  NEWSROOMS,
  LOGIN,
  CONTACT,
  ABOUT,
  DASHBOARD,
  CARE_PROVIDERS,
  REGIONS,
  SCENARIOS,
  HOME_PAGES,
} from 'utils/routes';

export const DESKTOP_MENU = [
  [{ id: 1, label: 'Home', path: INDEX }],
  [
    { id: 2, label: 'Warum CuraData?', path: SOLUTION },
    { id: 3, label: 'Wer nutzt CuraData?', path: CUSTOMERS },
    { id: 4, label: 'Newsroom', path: NEWSROOMS },
  ],
  [
    { id: 4, label: 'Entdecken', isDropdown: true, items: HOME_PAGES },
    { id: 5, label: 'Anmelden', path: LOGIN },
  ],
];

export const MOBILE_MENU = [
  { id: 1, label: 'Warum CuraData?', path: SOLUTION },
  { id: 2, label: 'Wer nutzt CuraData?', path: CUSTOMERS },
  { id: 3, label: 'Newsroom', path: NEWSROOMS },
  { id: 4, label: 'Über uns', path: ABOUT },
  { id: 5, label: 'Kontakt', path: CONTACT },
  { id: 6, label: 'Anmelden', path: LOGIN },
  { id: 7, label: 'Entdecken', isDropdown: true, items: HOME_PAGES },
];

export const PRIVATE_MENU = [
  { id: PERMISSIONS.DASHBOARD, label: 'Dashboard', path: DASHBOARD },
  { id: PERMISSIONS.CARE_PROVIDERS, label: 'Service provider', path: CARE_PROVIDERS },
  { id: PERMISSIONS.REGIONS, label: 'Regions', path: REGIONS },
  { id: PERMISSIONS.SCENARIOS, label: 'Scenarios', path: SCENARIOS },
];
