import { combineReducers } from 'redux';

import { FEATURE_NAME as alerts } from 'store/alerts/consts';
import alertsReducer from 'store/alerts/reducer';

import { FEATURE_NAME as application } from 'store/application/consts';
import applicationReducer from 'store/application/reducer';

import { FEATURE_NAME as session } from 'store/session/consts';
import sessionReducer from 'store/session/reducer';

import { FEATURE_NAME as team } from 'store/team/consts';
import teamReducer from 'store/team/reducer';

import { FEATURE_NAME as regions } from 'store/regions/consts';
import regionsReducer from 'store/regions/reducer';

import { FEATURE_NAME as organizations } from 'store/organizations/consts';
import organizationsReducer from 'store/organizations/reducer';

import { FEATURE_NAME as scenarios } from 'store/scenarios/consts';
import scenariosReducer from 'store/scenarios/reducer';

import { FEATURE_NAME as cantons } from 'store/cantons/consts';
import cantonsReducer from 'store/cantons/reducer';

import { FEATURE_NAME as countries } from 'store/countries/consts';
import countriesReducer from 'store/countries/reducer';

import { FEATURE_NAME as projects } from 'store/projects/consts';
import projectsReducer from 'store/projects/reducer';

import { FEATURE_NAME as healthcareServices } from 'store/healthcareServices/consts';
import healthcareServicesReducer from 'store/healthcareServices/reducer';

export default combineReducers({
  [alerts]: alertsReducer,
  [application]: applicationReducer,
  [session]: sessionReducer,
  [team]: teamReducer,
  [regions]: regionsReducer,
  [organizations]: organizationsReducer,
  [scenarios]: scenariosReducer,
  [cantons]: cantonsReducer,
  [countries]: countriesReducer,
  [projects]: projectsReducer,
  [healthcareServices]: healthcareServicesReducer,
});
