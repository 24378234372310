import { takeEvery, takeLatest, call, put, throttle, select } from 'redux-saga/effects';
import { cond, equals, remove, T, pick, applySpec, propOr, is, props, prop, pipe, assoc, path, includes, __, keys } from 'ramda';

import { withAlert, applyCancelToken } from 'store/alerts';
import { normalizeArray } from 'store/utils';
import api from 'api';
import { hasOfflineMode } from 'store/session/selectors';
import { getOrganizationProp } from 'store/organizations/selectors';

import {
  updateInpatient,
  updateInpatientSummary,
  updateOutpatient,
  updateOutpatientSummary,
  updateCareProvider,
  updateDashboards,
  updateCantonsMap,
} from './actions';
import {
  FETCH_INPATIENT,
  FETCH_INPATIENT_SUMMARY,
  FETCH_OUTPATIENT,
  FETCH_OUTPATIENT_SUMMARY,
  FETCH_CARE_PROVIDERS,
  FETCH_CARE_PROVIDER,
  CREATE_CARE_PROVIDER,
  SAVE_CARE_PROVIDER,
  REMOVE_CARE_PROVIDER,
  CREATE_SERVICE,
  SAVE_SERVICE,
  REMOVE_SERVICE,
  FETCH_DASHBOARDS,
  FETCH_DASHBOARD,
  SAVE_DASHBOARD,
  FETCH_CANTONS_MAP,
  UPDATE_SELECTED_CANTON,
} from './types';
import {
  getCareProviderProp,
  getCareProvider,
  getDashboards,
  getDashboardSettings,
  getDashboardsFilters,
  getSelectedCanton,
} from './selectors';
import { combineDashboards, getDefaultDashboardSettings } from './utils';
import {
  ID,
  EXPAND,
  FIELDS,
  SUB_CARE_PROVIDER,
  NAME,
  CITY,
  LIMIT,
  SORT_DIR,
  DESC,
  SORT_BY,
  BEDS_TOTAL,
  HOURS_KLV,
  SUB_INPATIENT,
  SUB_OUTPATIENT,
  SUB_LIVING,
  USED_BY,
  SHAREHOLDERS,
  INPATIENT,
  OUTPATIENT,
  LIVING,
  VALUES,
  BENCHMARK,
  PAGINATION,
  COUNT,
  HAS_MORE,
  START_AFTER,
  SUB_ORGANIZATION,
  DEFAULT_OBSAN_DASHBOARD_SETTINGS,
  DEFAULT_EPIDEMIOLOGY_DASHBOARD_SETTINGS,
  CANTON_MAP_KEYS,
  TOPOLOGY,
  DATE_GTE,
  DATE_LT,
  SETTINGS,
  FILTER_TYPE,
  PLANNING_TYPE,
  REGION_TYPE,
  LOCALITY_TYPE,
  CARE_PROVIDER_TYPE,
  SUB_REGIONS,
  SUB_ORGANIZATIONS,
  SUB_CARE_PROVIDERS,
  DAYS,
  SPEC_DAYS,
  REMAINING_COSTS,
  NURSING_LEVEL_MOVEMENT,
  NURSING_LEVEL_RATIOS_REMAINING_COSTS,
  HISTORY,
  EPIDEMIOLOGY_POPULATION_GROUP,
  AGE_FROM,
  CANTON_TYPE,
  ORGANIZATION_TYPE,
  EPIDEMIOLOGY_POPULATION,
  EPIDEMIOLOGY_WET_AMD,
  EPIDEMIOLOGY_DME,
  EPIDEMIOLOGY_GA,
  EPIDEMIOLOGY_EYE_SUMMARY,
  EPIDEMIOLOGY_EYE_SUMMARY_BAR,
  EPIDEMIOLOGY_DEMENTIA,
  DEMENTIA,
  CURADATA_POPULATION_GROUP,
  CURADATA_POPULATION,
  KIND,
  LIST,
  TOUCHED,
} from '.';

const defaultParams = {
  [EXPAND]: SUB_CARE_PROVIDER,
  [FIELDS]: [`${SUB_CARE_PROVIDER}.${NAME}`, `${SUB_CARE_PROVIDER}.${CITY}`],
  [LIMIT]: 6,
  [SORT_DIR]: DESC,
  [DATE_GTE]: new Date(Date.parse(new Date().getFullYear(), 0, 1)).toISOString(),
  [DATE_LT]: new Date(Date.parse(new Date().getFullYear() + 1, 0, 1)).toISOString(),
};
const inpatientParams = { ...defaultParams, [SORT_BY]: BEDS_TOTAL };
const outpatientParams = { ...defaultParams, [SORT_BY]: HOURS_KLV };

function* fetchInpatient({ payload }) {
  const data = yield call(api.get, '/inpatients', { params: { ...inpatientParams, ...payload } });

  if (payload) return { success: data };

  yield put(updateInpatient(data.data));

  return { success: true };
}

function* fetchInpatientSummary(action) {
  const data = yield call(api.get, '/inpatientssummary', applyCancelToken(action));
  yield put(updateInpatientSummary(data));
}

function* fetchOutpatient({ payload }) {
  const data = yield call(api.get, '/outpatients', { params: { ...outpatientParams, ...payload } });

  if (payload) return { success: data };

  yield put(updateOutpatient(data.data));

  return { success: true };
}

function* fetchOutpatientSummary(action) {
  const data = yield call(api.get, '/outpatientssummary', applyCancelToken(action));
  yield put(updateOutpatientSummary(data));
}

function* fetchCareProviders({ payload }) {
  return { success: yield call(api.get, '/careproviders', { params: payload }) };
}

const careProviderParams = {
  [EXPAND]: [
    SUB_ORGANIZATION,
    SUB_INPATIENT,
    `${SUB_INPATIENT}.${SHAREHOLDERS}.${SUB_ORGANIZATION}`,
    SUB_OUTPATIENT,
    `${SUB_OUTPATIENT}.${USED_BY}.${SUB_ORGANIZATION}`,
    SUB_LIVING,
    `${SUB_LIVING}.${USED_BY}.${SUB_ORGANIZATION}`,
  ],
};

function* fetchCareProvider({ payload, ...rest }) {
  const data = yield call(api.get, `/careproviders/${payload}`, { params: careProviderParams, ...applyCancelToken(rest) });

  yield put(updateCareProvider(data));
}

function* createCareProvider() {
  return { success: yield call(api.post, '/careproviders', {}, { params: { [FIELDS]: ID } }) };
}

function* saveCareProvider({ payload, ...rest }) {
  const id = yield select(getCareProviderProp(ID));
  const data = yield call(api.patch, `/careproviders/${id}`, payload, { params: careProviderParams, ...applyCancelToken(rest) });

  yield put(updateCareProvider(data));
}

function* removeCareProvider({ payload }) {
  return { success: yield call(api.delete, `/careproviders/${payload}`, { params: { [FIELDS]: ID } }) };
}

const getServiceRequestData = (type = '') =>
  cond([
    [equals(INPATIENT), () => [`/inpatients`, SUB_INPATIENT], { [EXPAND]: [`${SHAREHOLDERS}.${SUB_ORGANIZATION}`] }],
    [equals(OUTPATIENT), () => [`/outpatients`, SUB_OUTPATIENT, { [EXPAND]: [`${USED_BY}.${SUB_ORGANIZATION}`] }]],
    [equals(LIVING), () => [`/livings`, SUB_LIVING, { [EXPAND]: [`${USED_BY}.${SUB_ORGANIZATION}`] }]],
    [T, () => []],
  ])(type);

function* updateServicesRelatedData() {
  yield fetchInpatient({});
  yield fetchInpatientSummary();
  yield fetchOutpatient({});
  yield fetchOutpatientSummary();
}
function* createService({ payload }) {
  const { type, data } = payload;

  const [url, storeField, params = {}] = getServiceRequestData(type);

  if (!(url || storeField)) return { error: 'Incorrect action data.' };

  const careProviderData = yield select(getCareProvider);
  const result = yield call(api.post, url, { [SUB_CARE_PROVIDER]: careProviderData[ID], ...data }, { params });
  yield updateServicesRelatedData();

  yield put(
    updateCareProvider({
      ...careProviderData,
      [storeField]: [...careProviderData[storeField], result],
    })
  );

  return { success: result[ID] };
}

function* saveService({ payload }) {
  const { id, type, data } = payload;

  const [url, storeField, params = {}] = getServiceRequestData(type);

  if (!(id || url || storeField)) return { error: 'Incorrect action data.' };

  const result = yield call(api.patch, `${url}/${id}`, data, { params });
  yield updateServicesRelatedData();
  const careProviderData = yield select(getCareProvider);
  const updatedServices = [...careProviderData[storeField]];
  const index = updatedServices.findIndex(({ [ID]: serviceId }) => serviceId === id);

  if (index < 0) return { error: 'Error while applying updated data.' };

  updatedServices[index] = result;

  yield put(
    updateCareProvider({
      ...careProviderData,
      [storeField]: updatedServices,
    })
  );

  return { success: true };
}

function* removeService({ payload }) {
  const { id, type } = payload;

  const [url, storeField] = getServiceRequestData(type);

  if (!(id || url || storeField)) return { error: 'Incorrect action data.' };

  yield call(api.delete, `${url}/${id}`);
  yield updateServicesRelatedData();
  const careProviderData = yield select(getCareProvider);
  const index = careProviderData[storeField].findIndex(({ [ID]: serviceId }) => serviceId === id);

  if (index < 0) return { error: 'Error while applying updated data.' };

  yield put(
    updateCareProvider({
      ...careProviderData,
      [storeField]: remove(index, 1, careProviderData[storeField]),
    })
  );

  return { success: true };
}

function* fetchDashboards({ payload }) {
  const storeFilters = yield select(getDashboardsFilters);
  const { data } = yield call(api.get, '/dashboards', { params: { [LIMIT]: 50, ...(payload || storeFilters) } });
  const organizationKind = yield select(getOrganizationProp(KIND));

  yield pipe(combineDashboards, updateDashboards, put)(data, organizationKind);
}

const extractData = (id, data = {}) =>
  cond([
    [
      includes(__, [
        CURADATA_POPULATION_GROUP,
        CURADATA_POPULATION,
        EPIDEMIOLOGY_POPULATION_GROUP,
        EPIDEMIOLOGY_POPULATION,
        EPIDEMIOLOGY_WET_AMD,
        EPIDEMIOLOGY_DME,
        EPIDEMIOLOGY_GA,
        EPIDEMIOLOGY_EYE_SUMMARY,
        EPIDEMIOLOGY_EYE_SUMMARY_BAR,
        EPIDEMIOLOGY_DEMENTIA,
      ]),
      () => ({
        [VALUES]: data,
        [BENCHMARK]: [],
        [PAGINATION]: {},
        [TOPOLOGY]: null,
      }),
    ],
    [
      T,
      () =>
        applySpec({
          [VALUES]: propOr([], 'data'),
          [BENCHMARK]: propOr([], BENCHMARK),
          [PAGINATION]: pick([COUNT, HAS_MORE, START_AFTER]),
          [TOPOLOGY]: propOr(null, TOPOLOGY),
        })(data),
    ],
  ])(id || null);

const applyDashboardFilter = (selected, typeName, itemsName) => {
  const isMulti = is(Array, selected);

  if (isMulti && selected.length) return { [typeName]: true, [itemsName]: selected.map(prop(ID)) };
  if (!isMulti && selected) return { [typeName]: true, [itemsName]: [selected[ID]] };

  return {};
};
export const handleSettings = (section, settings) => {
  if (!settings) return {};

  if (DEFAULT_OBSAN_DASHBOARD_SETTINGS[section]) {
    return settings[FILTER_TYPE] ? { [HISTORY]: settings[FILTER_TYPE] } : {};
  }

  const [filterType, sortDir, days, specDays, nursingLevelMovement, nursingLevelRatios, ageFrom, dementia] = props([
    FILTER_TYPE,
    SORT_DIR,
    DAYS,
    SPEC_DAYS,
    NURSING_LEVEL_MOVEMENT,
    NURSING_LEVEL_RATIOS_REMAINING_COSTS,
    AGE_FROM,
    DEMENTIA,
  ])(settings);

  if (DEFAULT_EPIDEMIOLOGY_DASHBOARD_SETTINGS[section]) {
    return {
      [AGE_FROM]: ageFrom,
      [DEMENTIA]: dementia,
      ...(includes(filterType, [CANTON_TYPE, REGION_TYPE, ORGANIZATION_TYPE]) && {
        [filterType]: path([filterType, ID], settings),
      }),
    };
  }

  return {
    ...cond([
      [equals(PLANNING_TYPE), () => applyDashboardFilter(settings[PLANNING_TYPE], REGION_TYPE, SUB_REGIONS)],
      [equals(REGION_TYPE), () => applyDashboardFilter(settings[REGION_TYPE], REGION_TYPE, SUB_REGIONS)],
      [equals(LOCALITY_TYPE), () => applyDashboardFilter(settings[LOCALITY_TYPE], CITY, SUB_ORGANIZATIONS)],
      [
        equals(CARE_PROVIDER_TYPE),
        () => applyDashboardFilter(settings[CARE_PROVIDER_TYPE], CARE_PROVIDER_TYPE, SUB_CARE_PROVIDERS),
      ],
    ])(filterType || ''),
    ...(sortDir && { [SORT_DIR]: sortDir }),
    [DAYS]: days,
    [SPEC_DAYS]: specDays,
    ...(section === REMAINING_COSTS && nursingLevelMovement && { [NURSING_LEVEL_MOVEMENT]: nursingLevelMovement }),
    ...(section === REMAINING_COSTS &&
      nursingLevelRatios &&
      nursingLevelRatios.length > 0 && { [NURSING_LEVEL_RATIOS_REMAINING_COSTS]: nursingLevelRatios }),
  };
};

function* fetchDashboard({ payload }) {
  const { id, tabConfig = {} } = payload;
  const { endpoint, allSettings } = getDefaultDashboardSettings(id);
  const selectedCanton = yield select(getSelectedCanton);
  const settings =
    selectedCanton && CANTON_MAP_KEYS[id]
      ? {
          ...allSettings[id],
          [FILTER_TYPE]: CANTON_TYPE,
          [CANTON_TYPE]: selectedCanton,
          [TOUCHED]: true,
        }
      : allSettings[id];
  const offlineMode = yield select(hasOfflineMode);
  const dashboard = yield call(
    api.post,
    `/${endpoint}/${id}`,
    { ...handleSettings(id, settings), ...tabConfig },
    { offlineMode }
  );
  const dashboards = yield select(getDashboards);

  if (dashboards[id]) {
    yield pipe(
      assoc(id, {
        ...dashboards[id],
        ...extractData(id, dashboard),
        [SETTINGS]: { ...settings, ...tabConfig },
      }),
      updateDashboards,
      put
    )(dashboards);
  }
}

function* saveDashboard({ payload, ...rest }) {
  const { endpoint } = getDefaultDashboardSettings(payload);
  const settings = yield select(getDashboardSettings(payload));
  const offlineMode = yield select(hasOfflineMode);
  const dashboard = yield call(api.post, `/${endpoint}/${payload}`, handleSettings(payload, settings), {
    ...applyCancelToken(rest),
    offlineMode,
  });
  const dashboards = yield select(getDashboards);

  yield pipe(assoc(payload, { ...dashboards[payload], ...extractData(payload, dashboard) }), updateDashboards, put)(dashboards);
}

function* fetchCantonsMap(action) {
  const { data, [TOPOLOGY]: topo } = yield call(api.get, '/maps/cantons', {
    params: { [LIMIT]: 50 },
    ...applyCancelToken(action),
  });

  if (data && topo) {
    yield put(
      updateCantonsMap({
        [LIST]: normalizeArray(ID, data),
        [TOPOLOGY]: topo,
      })
    );
  }
}

function* applyCantonFilter({ payload }) {
  const dashboards = yield select(getDashboards);
  const dashboardKeys = keys(dashboards);

  const newDashboards = dashboardKeys.reduce((acc, key) => {
    const dashboard = dashboards[key];
    if (CANTON_MAP_KEYS[key] && dashboard[VALUES]) {
      acc[key] = {
        ...dashboard,
        [VALUES]: null,
        [SETTINGS]: {
          ...dashboard[SETTINGS],
          [TOUCHED]: Boolean(payload),
        },
      };
    } else {
      acc[key] = dashboards[key];
    }

    return acc;
  }, {});

  yield put(updateDashboards(newDashboards));
}

export default function* watchHealthcareServices() {
  yield takeEvery(FETCH_INPATIENT, withAlert(fetchInpatient));
  yield takeLatest(FETCH_INPATIENT_SUMMARY, withAlert(fetchInpatientSummary));
  yield takeEvery(FETCH_OUTPATIENT, withAlert(fetchOutpatient));
  yield takeLatest(FETCH_OUTPATIENT_SUMMARY, withAlert(fetchOutpatientSummary));
  yield throttle(500, FETCH_CARE_PROVIDERS, withAlert(fetchCareProviders));
  yield takeLatest(FETCH_CARE_PROVIDER, withAlert(fetchCareProvider));
  yield takeEvery(CREATE_CARE_PROVIDER, withAlert(createCareProvider));
  yield takeLatest(SAVE_CARE_PROVIDER, withAlert(saveCareProvider));
  yield takeEvery(REMOVE_CARE_PROVIDER, withAlert(removeCareProvider));
  yield takeEvery(CREATE_SERVICE, withAlert(createService));
  yield takeEvery(SAVE_SERVICE, withAlert(saveService));
  yield takeEvery(REMOVE_SERVICE, withAlert(removeService));
  yield throttle(500, FETCH_DASHBOARDS, withAlert(fetchDashboards));
  yield takeEvery(FETCH_DASHBOARD, withAlert(fetchDashboard));
  yield takeLatest(SAVE_DASHBOARD, withAlert(saveDashboard));
  yield takeLatest(FETCH_CANTONS_MAP, withAlert(fetchCantonsMap));
  yield takeEvery(UPDATE_SELECTED_CANTON, withAlert(applyCantonFilter));
}
