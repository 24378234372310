import { takeLatest, takeEvery, throttle, call, put, select, all } from 'redux-saga/effects';

import { withAlert, applyCancelToken } from 'store/alerts';
import api from 'api';

import { ID, NAME, CANTON, FIELDS, SORT_BY, SORT_DIR, ASC, EXPAND, SUB_ORGANIZATIONS } from '.';
import { updateOrganization, updateDementiaPopulation, updateOrganizationConfig } from './actions';
import { getOrganizationProp } from './selectors';
import {
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATION,
  FETCH_DEMENTIA_POPULATION,
  FETCH_ACCOUNT_ORGANIZATIONS,
  SAVE_ORGANIZATION,
} from './types';

const defaultParams = { [FIELDS]: [NAME, CANTON] };

function* fetchOrganizations({ payload }) {
  return {
    success: yield call(api.get, '/organizations', {
      params: { ...defaultParams, ...payload, [SORT_BY]: NAME, [SORT_DIR]: ASC },
    }),
  };
}

function* fetchOrganization({ payload, ...rest }) {
  const fields = { [FIELDS]: defaultParams[FIELDS].map((field) => `${SUB_ORGANIZATIONS}.${field}`) };
  const [organization, config] = yield all([
    call(api.get, `/organizations/${payload}`, {
      params: { ...fields, [EXPAND]: SUB_ORGANIZATIONS },
      ...applyCancelToken(rest),
    }),
    call(api.get, `/organizations/${payload}/config`, applyCancelToken(rest)),
  ]);

  yield put(updateOrganization(organization));
  yield put(updateOrganizationConfig(config));
}

function* fetchDementiaPopulation({ payload, ...rest }) {
  const data = yield call(api.get, `/organizations/${payload}/dementia`, applyCancelToken(rest));
  yield put(updateDementiaPopulation({ [ID]: payload, ...data }));
}

function* fetchAccountOrganizations({ payload }) {
  return {
    success: yield call(api.get, '/session/organizations', {
      baseURL: process.env.REACT_APP_URL,
      params: { ...payload, [SORT_BY]: NAME, [SORT_DIR]: ASC },
    }),
  };
}

function* saveOrganization({ payload, ...rest }) {
  const id = yield select(getOrganizationProp(ID));
  const fields = { [FIELDS]: defaultParams[FIELDS].map((field) => `${SUB_ORGANIZATIONS}.${field}`) };
  const data = yield call(api.patch, `/organizations/${id}`, payload, {
    params: { ...fields, [EXPAND]: SUB_ORGANIZATIONS },
    ...applyCancelToken(rest),
  });
  yield put(updateOrganization(data));
}

export default function* watchOrganizations() {
  yield throttle(300, FETCH_ORGANIZATIONS, withAlert(fetchOrganizations));
  yield takeLatest(FETCH_ORGANIZATION, withAlert(fetchOrganization));
  yield takeEvery(FETCH_DEMENTIA_POPULATION, withAlert(fetchDementiaPopulation));
  yield throttle(300, FETCH_ACCOUNT_ORGANIZATIONS, withAlert(fetchAccountOrganizations));
  yield takeLatest(SAVE_ORGANIZATION, withAlert(saveOrganization));
}
