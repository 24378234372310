import React from 'react';

import { FOOTER_MENU } from './consts';
import Content from './Content';
import Subscription from './Subscription';
import { Container, Wrapper, StyledLink, ExternLink } from './styles';

const Footer = () => (
  <Container>
    <Wrapper>
      {FOOTER_MENU.map(({ id, title, items, lastItem }) => (
        <Content key={id} title={title} lastItem={lastItem}>
          {items.map(({ isExternal, to, name }) =>
            isExternal ? (
              <ExternLink key={to} href={to} target="_blank" rel="noopener noreferrer">
                {name}
              </ExternLink>
            ) : (
              <StyledLink key={to} to={to} $lastItem={lastItem}>
                {name}
              </StyledLink>
            )
          )}
        </Content>
      ))}
    </Wrapper>
    <Subscription />
  </Container>
);

export default Footer;
