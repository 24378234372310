import { CACHE_NAME } from './consts';

class Cache {
  async open(name) {
    if (!this.cache) this.cache = await (window.caches && window.caches.open(name));

    return this.cache;
  }

  async put(url, response) {
    const cache = await this.open(CACHE_NAME);
    const data = await cache.put(url, new Response(JSON.stringify(response)));

    return data;
  }

  async get(url) {
    const cache = await this.open(CACHE_NAME);
    const res = await cache.match(url);
    const data = await res.json();

    return data;
  }
}

const cache = new Cache();

export default cache;
