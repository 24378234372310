import React, { useMemo, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { useLoggedIn } from 'store/session';
import { useOrganization, KIND } from 'store/organizations';
import { usePermissions, DASHBOARD as DASHBOARD_ACCESS, regionPermissionTask } from 'permissions';
import { INDEX, DASHBOARD } from 'utils/routes';
import Spinner from 'components/Spinner';
import ErrorBoundary from '../ErrorBoundary';

const PrivateRoute = ({ layout: Layout, access, component: Component }) => {
  const isLoggedIn = useLoggedIn();
  const can = usePermissions();
  const hasAccessToDashboard = useMemo(() => can.redirect(DASHBOARD_ACCESS), [can]);
  const organizationKind = useOrganization(KIND);
  const hasAccess = useMemo(
    () => isLoggedIn && can.redirect(access, regionPermissionTask(organizationKind)),
    [isLoggedIn, can, access, organizationKind]
  );

  return hasAccess ? (
    <Layout>
      <ErrorBoundary>
        <Suspense fallback={<Spinner fullScreen />}>
          <Component />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  ) : (
    <Navigate to={hasAccessToDashboard ? DASHBOARD : INDEX} replace />
  );
};

PrivateRoute.propTypes = {
  layout: PropTypes.elementType.isRequired,
  access: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
