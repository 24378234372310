import React, { useCallback, useState, useMemo, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';

import CrossIcon from 'assets/svg-icons/Cross';

import SearchIcon from './SearchIcon';
import { Container, Input, Wrapper, Circle, Dot, Text, ClearBtn } from './styles';

const TextInput = forwardRef(({ type, value, changeText, onChange, error, disabled, readMode, ...rest }, ref) => {
  const inputRef = useRef();
  const [isShowPassword, setIsShowPassword] = useState();
  const handleChange = useCallback(
    (e) => {
      changeText(e.target.value);
      onChange(e);
    },
    [changeText, onChange]
  );
  const handleClear = useCallback(
    (e) => {
      const elem = ref?.current || inputRef.current;
      const lastValue = elem.value;
      elem.value = '';
      const tracker = elem._valueTracker; // eslint-disable-line no-underscore-dangle
      if (tracker) tracker.setValue(lastValue);

      elem.dispatchEvent(new Event('change', { bubbles: true }));
    },
    [ref]
  );

  const isPassword = type === 'password';
  const isSearch = type === 'search';
  const inputType = useMemo(
    () => ((isPassword && isShowPassword) || isSearch ? 'text' : type),
    [isPassword, isShowPassword, isSearch, type]
  );
  const handleClick = useCallback(() => setIsShowPassword(($) => !$), []);

  return (
    <Container $error={error}>
      {isSearch && (
        <Wrapper $startPosition>
          <SearchIcon />
        </Wrapper>
      )}
      <Input
        ref={ref || inputRef}
        type={inputType}
        $isPassword={isPassword}
        $isSearch={isSearch}
        value={value}
        onChange={handleChange}
        $error={error}
        disabled={disabled || readMode}
        $readMode={readMode}
        {...rest}
      />
      {Boolean(isSearch && value) && (
        <ClearBtn type="button" onClick={handleClear} aria-label="clear">
          <CrossIcon />
        </ClearBtn>
      )}
      {isPassword && (
        <Wrapper>
          <Circle type="button" onClick={handleClick} aria-label="show/hide password">
            <Dot $isShow={isShowPassword} />
          </Circle>
          <Text>Zeigen</Text>
        </Wrapper>
      )}
    </Container>
  );
});

TextInput.defaultProps = {
  type: 'text',
  value: '',
  changeText: () => null,
  onChange: () => null,
  error: '',
  disabled: false,
  readMode: false,
};

TextInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  changeText: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  readMode: PropTypes.bool,
};

export default TextInput;
