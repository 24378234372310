import { path, pipe, pathOr, includes } from 'ramda';

import { ID } from 'api/consts';
import { EPIDEMIOLOGY } from 'permissions/consts';
import { FEATURE_NAME as $, AUTH, LOGGED_IN, USER, PERMISSIONS, ROLES } from './consts';

export const getAuth = path([$, AUTH]);
export const getLoggedIn = path([$, LOGGED_IN]);
export const getUser = path([$, USER]);
export const getUserProp = (name) => path([$, USER, name]);
export const getUserId = path([$, USER, ID]);
export const getPermissionsObject = path([$, PERMISSIONS]);
export const hasOfflineMode = pipe(pathOr([], [$, USER, ROLES]), includes(EPIDEMIOLOGY));
