export const FEATURE_NAME = 'organizations';

export const CURRENT = 'current';
export const DEMENTIA = 'dementiaPopulations';
export const CONFIG = 'config';

export const FULL_NAME = 'fullname';
export const CANTON = 'canton';
export const COUNTRY = 'country';
export const SHORT_NAME = 'shortname';
export const STATUS = 'status';
export const SOURCE = 'source';
export const AGE_GROUP = 'ageGroups';
export const INPATIENT = 'inpatient';
export const OUTPATIENT = 'outpatient';
export const ZIP_CODE = 'zip_code';
export const CITY = 'city';
export const ADDRESS = 'address';
export const PHONE = 'phone';
export const EMAIL = 'email';
export const DEPARTMENT = 'department';
export const OFFICE = 'office';
export const UNIT = 'unit';
export const DATA_CONSULTING_SERVICES = 'dataConsultingServices';
export const FORCE_2FA = 'force2FA';

export const TOTAL = 'totalPopulation';
export const AGE_FROM = 'ageFrom';
export const AGE_UNTIL = 'ageTo';
export const YEAR = 'year';
export const VALUE = 'value';

export const SCENARIO_ID = 'scenarioId';

export const SCENARIO_EYE_DISEASES = 'scenarioEyeDiseases';
export const SCENARIO_MIGRATION = 'scenarioMigration';

export const ORGANIZATION_KINDS = {
  MUNICIPALITY: 'municipality',
  CITY: 'city',
  REGION: 'region',
  CANTON: 'canton',
  COUNTRY: 'country',
};
