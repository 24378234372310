import { useMemo } from 'react';

import { useMobileDetect } from 'hooks/useMobileDetect';

const DESKTOP_TEXTS = ['Der Wandel im Gesundheitssystem erklärt', 'Stellen Sie CuraData Ihre Fragen.'];
const MOBILE_TEXTS = ['DER WANDEL IM', 'GESUNDHEITSSYSTEM', 'ERKLÄRT', 'STELLEN SIE CURADATA', 'IHRE FRAGEN.'];

export const useTypingTexts = () => {
  const isMobile = useMobileDetect(689);
  const texts = useMemo(() => (isMobile ? MOBILE_TEXTS : DESKTOP_TEXTS), [isMobile]);

  return { texts, gapIndex: 2, isMobile };
};
