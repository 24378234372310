import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_REGIONS,
  UPDATE_REGION,
  DELETE_REGION,
  RESET_REGIONS,
  UPDATE_SEARCH,
  UPDATE_PAGINATION,
  UPDATE_SORT,
  RESET_FILTERS,
  FETCH_REGIONS,
  FETCH_REGIONS_COLLECT,
  FETCH_LOCALITIES_COLLECT,
  FETCH_REGION,
  CREATE_REGION,
  SAVE_REGION,
  REMOVE_REGION,
} from './types';

export const updateRegions = createAction(UPDATE_REGIONS);
export const updateRegion = createAction(UPDATE_REGION);
export const deleteRegion = createAction(DELETE_REGION);
export const resetRegions = createAction(RESET_REGIONS);
export const updateSearch = createAction(UPDATE_SEARCH);
export const updatePagination = createAction(UPDATE_PAGINATION);
export const updateSort = createAction(UPDATE_SORT);
export const resetFilters = createAction(RESET_FILTERS);

export const fetchRegions = createAlertAction(FETCH_REGIONS);
export const fetchRegionsCollect = createAlertAction(FETCH_REGIONS_COLLECT);
export const fetchLocalitiesCollect = createAlertAction(FETCH_LOCALITIES_COLLECT);
export const fetchRegion = createAlertAction(FETCH_REGION);
export const createRegion = createAlertAction(CREATE_REGION);
export const saveRegion = createAlertAction(SAVE_REGION);
export const removeRegion = createAlertAction(REMOVE_REGION);
