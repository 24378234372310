import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_CANTONS = getName('UPDATE_CANTONS');
export const RESET_CANTONS = getName('RESET_CANTONS');

export const FETCH_CANTONS_COLLECT = getName('FETCH_CANTONS_COLLECT');
export const FETCH_CANTON_BY_NAME = getName('FETCH_CANTON_BY_NAME');
export const FETCH_CANTON_BY_ID = getName('FETCH_CANTON_BY_ID');
