import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  SET_SEEN_ANIMATION,
  UPDATE_PUBLIC_FILTER,
  UPDATE_CERTIFICATE_KIND,
  UPDATE_TOPOLOGY,
  UPDATE_PUBLIC_POPULATION,
  UPDATE_PUBLIC_ELDERLY,
  UPDATE_PUBLIC_DEMENTIA,
  UPDATE_PUBLIC_DOCTOR_DENSITY,
  UPDATE_PUBLIC_SCENARIO,
  CREATE_BUG_REPORT,
  SEND_CONTACT,
  UPLOAD_MEDIA,
  DOWNLOAD_XLSX,
  FETCH_PUBLIC_LOCALITIES,
  FETCH_PUBLIC_POPULATION,
  FETCH_PUBLIC_ELDERLY,
  FETCH_PUBLIC_DEMENTIA,
  FETCH_PUBLIC_DOCTOR_DENSITY,
  FETCH_PUBLIC_SCENARIO,
  DOWNLOAD_PUBLIC_SCENARIO,
  FETCH_INPATIENT_ESTIMATES,
} from './types';

export const setSeenAnimation = createAction(SET_SEEN_ANIMATION);
export const updateFilter = createAction(UPDATE_PUBLIC_FILTER);
export const updateCertificateKind = createAction(UPDATE_CERTIFICATE_KIND);
export const updateTopology = createAction(UPDATE_TOPOLOGY);
export const updatePublicPopulation = createAction(UPDATE_PUBLIC_POPULATION);
export const updatePublicElderly = createAction(UPDATE_PUBLIC_ELDERLY);
export const updatePublicDementia = createAction(UPDATE_PUBLIC_DEMENTIA);
export const updatePublicDoctorDensity = createAction(UPDATE_PUBLIC_DOCTOR_DENSITY);
export const updatePublicScenario = createAction(UPDATE_PUBLIC_SCENARIO);

export const createBugReport = createAlertAction(CREATE_BUG_REPORT);
export const sendContact = createAlertAction(SEND_CONTACT);
export const uploadMedia = createAlertAction(UPLOAD_MEDIA);
export const downloadXlsx = createAlertAction(DOWNLOAD_XLSX);
export const fetchPublicLocalities = createAlertAction(FETCH_PUBLIC_LOCALITIES);
export const fetchPublicPopulation = createAlertAction(FETCH_PUBLIC_POPULATION);
export const fetchPublicElderly = createAlertAction(FETCH_PUBLIC_ELDERLY);
export const fetchPublicDementia = createAlertAction(FETCH_PUBLIC_DEMENTIA);
export const fetchPublicDoctorDensity = createAlertAction(FETCH_PUBLIC_DOCTOR_DENSITY);
export const fetchPublicScenario = createAlertAction(FETCH_PUBLIC_SCENARIO);
export const downloadPublicScenario = createAlertAction(DOWNLOAD_PUBLIC_SCENARIO);
export const fetchInpatientEstimates = createAlertAction(FETCH_INPATIENT_ESTIMATES);
