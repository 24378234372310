import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

import { fontStyles, palette } from 'theme';

export const Container = styled.header`
  position: sticky;
  top: -1px;
  left: 0;
  right: 0;
  height: ${({ $isPrivate }) => ($isPrivate ? 60 : 110)}px;
  background-color: ${palette.white};
  z-index: 99;
  @media (max-width: 991px) {
    height: 85px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const DesktopWrap = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 15px;
  width: 100%;
  height: 110px;
  padding: 24px 20px;
  box-sizing: border-box;
  @media (max-width: 1050px) {
    column-gap: 5px;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;

export const ItemWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ $count }) => $count}, auto);
  justify-content: flex-start;
  column-gap: 30px;
  margin-left: ${({ $index }) => ($index === 1 ? '10%' : 0)};
  @media (max-width: 1300px) {
    margin-left: ${({ $index }) => ($index === 1 ? '2%' : 0)};
    column-gap: 15px;
  }
  @media (max-width: 1050px) {
    margin-left: 0;
    column-gap: 5px;
  }
`;

export const MobileWrap = styled.div`
  display: none;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 15px 0 15px;
  height: 80px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 991px) {
    display: grid;
  }
`;

export const Button = styled(NavLink)`
  ${fontStyles.textUpper}
  line-height: 30px;
  background-color: ${({ $isIndex }) => ($isIndex ? 'transparent' : palette.white)};
  color: ${palette.black};
  border: 2px solid;
  border-color: ${({ $isIndex }) => ($isIndex ? 'transparent' : palette.white)};
  text-decoration: none;
  box-sizing: border-box;
  padding: 0 10px;
  height: 33px;
  width: fit-content;
  @media (hover) {
    &:hover {
      border: 2px solid ${palette.black};
      background-color: ${palette.black};
      color: ${palette.white};
    }
  }
  &.active {
    border: 2px solid ${palette.black};
    background-color: ${palette.white};
    color: ${palette.black};
  }
`;

const transition = {
  duration: 0.5,
  ease: 'easeOut',
};

export const menuBtnVariants = {
  open: {
    rotate: 90,
    transition,
  },
  close: {
    rotate: 0,
    transition,
  },
};

export const MenuBtn = styled(motion.button)`
  background: none;
  border: none;
  outline: none;
  padding-top: 2px;
  margin: 0;
`;
