import { ORGANIZATION_KINDS } from 'store/organizations/consts';

import { CONSULTANT, REGION_MANAGER, REGIONS, ORGANIZATIONS, UPDATE, DELETE, EPIDEMIOLOGY } from './consts';

export * from './consts';
export * from './hooks';

export const regionPermissionTask = (kind) => (source) => source !== REGIONS || kind === ORGANIZATION_KINDS.REGION;
export const regionManagerTask = (source, name, permissions, roles) => roles.includes(REGION_MANAGER);
export const editRegionTask = (legalEntity) => (source, name, permissions, roles) =>
  roles.includes(REGION_MANAGER) || ((name === UPDATE || name === DELETE) && !legalEntity);
export const switchAccountTask = (source, name, permissions, roles) => source === ORGANIZATIONS && roles.includes(CONSULTANT);
export const multiOrganizationsTask = (kind) => (source) => source === ORGANIZATIONS && kind === ORGANIZATION_KINDS.REGION;
export const mapEditor = (source, name, permissions, roles) => roles.includes(EPIDEMIOLOGY);
