import { takeLatest, call, put, select } from 'redux-saga/effects';

import { withAlert, applyCancelToken } from 'store/alerts';
import api from 'api';

import { authenticate, authenticate2FA, updateUserData } from './actions';
import { getUserId } from './selectors';
import {
  LOGIN,
  LOGIN_2FA,
  RESTORE,
  FETCH_RESET_LINK,
  RESET_PASSWORD,
  SAVE_USER_DATA,
  SAVE_USER_PASSWORD,
  CREATE_2FA,
  VALIDATE_2FA,
  DELETE_2FA,
  SUBSCRIBE_NEWSLETTER,
} from './types';
import { TOKEN_2FA } from '.';

const baseURL = process.env.REACT_APP_URL;

function* login({ payload }) {
  const data = yield call(api.post, '/login', payload, { baseURL });
  yield put(authenticate(data));

  return data;
}

function* restore() {
  const data = yield call(api.get, '/session', { baseURL });
  const action = sessionStorage[TOKEN_2FA] ? authenticate2FA : authenticate;
  yield put(action(data));
}

function* fetchResetLink({ payload }) {
  const data = yield call(api.post, '/reset', payload, { baseURL });
  return data;
}

function* resetPassword({ payload }) {
  const data = yield call(api.post, '/setpassword', payload, { baseURL });
  return data;
}

function* saveUserData({ payload, ...rest }) {
  const id = yield select(getUserId);
  const data = yield call(api.patch, `/users/${id}`, payload, applyCancelToken(rest));

  yield put(updateUserData(data));

  return data;
}

function* saveUserPassword({ payload, ...rest }) {
  const data = yield call(api.post, '/updatepassword', payload, { baseURL, ...applyCancelToken(rest) });

  return data;
}

function* create2FA(action) {
  const data = yield call(api.post, '/auth/2fa/create', {}, { baseURL, ...applyCancelToken(action) });

  return { success: data?.url };
}

function* validate2FA({ payload, ...rest }) {
  const data = yield call(api.post, '/auth/2fa/validate', { code: payload }, { baseURL, ...applyCancelToken(rest) });

  return data;
}

function* login2FA({ payload, ...rest }) {
  const data = yield call(api.post, '/auth/2fa/authenticate', { code: payload }, { baseURL, ...applyCancelToken(rest) });

  yield put(authenticate2FA(data));

  return data;
}

function* delete2FA(action) {
  const data = yield call(api.post, '/auth/2fa/delete', {}, { baseURL, ...applyCancelToken(action) });

  yield put(authenticate(data));

  return data;
}

function* subscribeNewsletter({ payload, ...rest }) {
  return { success: yield call(api.post, '/newsletters', payload, applyCancelToken(rest)) };
}

export default function* watchSession() {
  yield takeLatest(LOGIN, withAlert(login));
  yield takeLatest(LOGIN_2FA, withAlert(login2FA));
  yield takeLatest(RESTORE, withAlert(restore));
  yield takeLatest(FETCH_RESET_LINK, withAlert(fetchResetLink));
  yield takeLatest(RESET_PASSWORD, withAlert(resetPassword));
  yield takeLatest(SAVE_USER_DATA, withAlert(saveUserData));
  yield takeLatest(SAVE_USER_PASSWORD, withAlert(saveUserPassword));
  yield takeLatest(CREATE_2FA, withAlert(create2FA));
  yield takeLatest(VALIDATE_2FA, withAlert(validate2FA));
  yield takeLatest(DELETE_2FA, withAlert(delete2FA));
  yield takeLatest(SUBSCRIBE_NEWSLETTER, withAlert(subscribeNewsletter));
}
