import { useMemo, useCallback, useEffect } from 'react';
import { object, string } from 'yup';
import { useFormik } from 'formik';

import { useSessionActions, useAlerts, EMAIL, SUBSCRIBED } from 'store/session';
import { DEFAULT_ERROR } from 'api/consts';

const validationSchema = object().shape({
  [EMAIL]: string().email('Ihre E-Mail-Adresse ist ungültig').required('Ihre E-Mail-Adresse fehlt'),
});

export const useSubscribeForm = () => {
  const { subscribeNewsletter } = useSessionActions();
  const { action, loading, success, error, resetAlerts } = useAlerts(subscribeNewsletter);
  const initialValues = useMemo(() => ({ [EMAIL]: '', [SUBSCRIBED]: true }), []);

  const onSubmit = useCallback(
    (values, { setSubmitting }) => {
      action(values);
      setSubmitting(false);
    },
    [action]
  );

  const { values, setFieldValue, handleSubmit, errors, setErrors, resetForm } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
  });

  const handleChange = useCallback(
    ({ target }) => {
      const { name, value } = target;
      resetAlerts();
      setErrors({ ...errors, [name]: '' });
      setFieldValue(name, value);
    },
    [resetAlerts, errors, setErrors, setFieldValue]
  );

  useEffect(() => {
    if (success) resetForm({ values: initialValues, errors: {} });
  }, [success, resetForm, initialValues]);

  return {
    name: EMAIL,
    email: values[EMAIL],
    loading,
    success: success && 'Vielen Dank! Sie haben sich für unseren Newsletter angemeldet.',
    error: error && DEFAULT_ERROR,
    validationError: errors[EMAIL],
    handleChange,
    handleSubmit,
    resetAlerts,
  };
};
