export default {
  black: '#000',
  white: '#fff',
  yellow: '#fff900',
  gray: '#ebebeb',
  darkGray: '#696969',
  red: '#ff5228',
  green: '#31ca4d',
  lightGreen: '#00ff93',
  lightGreen2: '#cdfeb1',
  blue: '#3574e3',
  sea: '#9fe7ff',
  pink: '#fe82e3',
  purple: '#9828ff',
  orange: '#ff7500',
  pistachio: '#b9fb93',
  dashboardsColours: [
    '#fff900',
    '#9828ff',
    '#ff7500',
    '#93ff75',
    '#ff93e7',
    '#3574e3',
    '#ff5228',
    '#cdfeb1',
    '#ebebeb',
    '#00e8e5',
    '#d68dff',
    '#00ff93',
    '#9fe7ff',
    '#ff003f',
    '#0011ff',
    '#cd2bea',
    '#31ca4d',
    '#ff9090',
    '#b6f2ca',
    '#c00000',
    '#b8b3ff',
    '#157633',
    '#ffd2ef',
    '#fbb834',
    '#baf200',
    '#8bc2ff',
    '#fffdb4',
    '#b07474',
    '#00afff',
    '#d1d1d1',
  ],
  mapColours: [
    { c1: 'rgba(241, 71, 58, 1)', c07: 'rgba(241, 71, 58, 0.7)' },
    { c1: 'rgba(255, 136, 118, 1)', c07: 'rgba(255, 136, 118, 0.7)' },
    { c1: 'rgba(255, 193, 184, 1)', c07: 'rgba(255, 193, 184, 0.7)' },
    { c1: 'rgba(251, 255, 123, 1)', c07: 'rgba(251, 255, 123, 0.7)' },
    { c1: 'rgba(228, 228, 222, 1)', c07: 'rgba(228, 228, 222, 0.7)' },
    { c1: 'rgba(168, 222, 197, 1)', c07: 'rgba(168, 222, 197, 0.7)' },
    { c1: 'rgba(123, 255, 193, 1)', c07: 'rgba(123, 255, 193, 0.7)' },
    { c1: 'rgba(112, 255, 159, 1)', c07: 'rgba(112, 255, 159, 0.7)' },
    { c1: 'rgba(0, 222, 118, 1)', c07: 'rgba(0, 222, 118, 0.7)' },
    { c1: 'rgba(30, 167, 110, 1)', c07: 'rgba(30, 167, 110, 0.7)' },
  ],
  clusterColors: {
    common: {
      a1: [
        'rgba(159, 231, 255, 1)',
        'rgba(139, 194, 255, 1)',
        'rgba(0, 175, 255, 1)',
        'rgba(53, 116, 227, 1)',
        'rgba(0, 17, 255, 1)',
      ],
      a05: [
        'rgba(159, 231, 255, 0.5)',
        'rgba(139, 194, 255, 0.5)',
        'rgba(0, 175, 255, 0.5)',
        'rgba(53, 116, 227, 0.5)',
        'rgba(0, 17, 255, 0.5)',
      ],
    },
    female: {
      a1: [
        'rgba(205, 254, 177, 1)',
        'rgba(147, 255, 117, 1)',
        'rgba(0, 255, 147, 1)',
        'rgba(0, 222, 118, 1)',
        'rgba(30, 167, 110, 1)',
      ],
      a05: [
        'rgba(205, 254, 177, 0.5)',
        'rgba(147, 255, 117, 0.5)',
        'rgba(0, 255, 147, 0.5)',
        'rgba(0, 222, 118, 0.5)',
        'rgba(30, 167, 110, 0.5)',
      ],
    },
    male: {
      a1: [
        'rgba(255, 210, 239, 1)',
        'rgba(252, 157, 191, 1)',
        'rgba(255, 147, 231, 1)',
        'rgba(254, 92, 218, 1)',
        'rgba(254, 92, 150, 1)',
      ],
      a05: [
        'rgba(255, 210, 239, 0.5)',
        'rgba(252, 157, 191, 0.5)',
        'rgba(255, 147, 231, 0.5)',
        'rgba(254, 92, 218, 0.5)',
        'rgba(254, 92, 150, 0.5)',
      ],
    },
  },
};
