import { path, propOr } from 'ramda';

import {
  FEATURE_NAME as $,
  INPATIENT,
  INPATIENT_SUMMARY,
  OUTPATIENT,
  OUTPATIENT_SUMMARY,
  CARE_PROVIDER,
  DASHBOARDS,
  DASHBOARDS_FILTERS,
  SETTINGS,
  CANTONS_MAP,
  LIST,
  SELECTED_CANTON,
} from './consts';

export const getInpatient = path([$, INPATIENT]);
export const getInpatientSummary = path([$, INPATIENT_SUMMARY]);
export const getOutpatient = path([$, OUTPATIENT]);
export const getOutpatientSummary = path([$, OUTPATIENT_SUMMARY]);
export const getCareProvider = path([$, CARE_PROVIDER]);
export const getCareProviderProp = (name) => path([$, CARE_PROVIDER, name]);
export const getDashboards = path([$, DASHBOARDS]);
export const getDashboardsFilters = path([$, DASHBOARDS_FILTERS]);
export const getDashboard = (key) => path([$, DASHBOARDS, key]);
export const getDashboardSettings = (key) => path([$, DASHBOARDS, key, SETTINGS]);
export const getCantonsMap = path([$, CANTONS_MAP]);
export const getCantonsMapProp = (key) => path([$, CANTONS_MAP, key]);
export const getSelectedCanton = (state) => {
  const list = getCantonsMapProp(LIST)(state);
  const selectedId = getCantonsMapProp(SELECTED_CANTON)(state);

  if (!(list && selectedId)) return null;

  return propOr(null, selectedId)(list || {});
};
