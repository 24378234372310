export const INTERN_USER = 'curadata';
export const ADMIN = 'curadata-admin';
export const INTERNAL = 'curadata-intern';
export const REGISTRAR = 'curadata-registrar';
export const REGION = 'curadata-region';
export const CONSULTANT = 'curadata-consultant';
export const REGION_MANAGER = 'curadata-region-manager';
export const EPIDEMIOLOGY = 'curadata-epidemiology';

export const USER = 'user';
export const ORGANIZATIONS = 'organizations';
export const CARE_PROVIDERS = 'careproviders';
export const SCENARIOS = 'scenarios';
export const TEAM = 'team';
export const CARE_PROVIDER = 'careprovider';
export const REGIONS = 'regions';
export const DASHBOARD = 'dashboard';
export const CANTONS = 'cantons';
export const COUNTRIES = 'countries';
export const PROJECTS = 'projects';
export const BUG_REPORT = 'bugReport';

export const CREATE = 'canCreate';
export const READ = 'canRead';
export const UPDATE = 'canUpdate';
export const DELETE = 'canDelete';
export const REDIRECT = 'canRedirect';
