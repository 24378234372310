import styled from 'styled-components';
import { motion } from 'framer-motion';

import { palette } from 'theme';

const style = `
  position: absolute;
  bottom: 4px;
  width: 10px;
  height: 2px;
  background-color: ${palette.black};
`;

export const Container = styled.div`
  position: relative;
  width: 16px;
  height: 9px;
`;

export const LeftLine = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: {
    rotate: $isOpen ? ['45deg', '90deg', '135deg'] : ['135deg', '90deg', '45deg'],
    left: $isOpen ? [0, 3, 0.0001] : [0.0001, 3, 0],
    borderRadius: $isOpen ? [0, 1, 0.0001] : [0.0001, 1, 0],
  },
  initial: false,
  transition: { duration: 0.5, times: [0, 0.5, 1] },
}))`
  ${style}
  transform: rotate(45deg);
  left: 0;
  border-radius: 0;
`;

export const RightLine = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: {
    rotate: $isOpen ? ['135deg', '90deg', '45deg'] : ['45deg', '90deg', '135deg'],
    left: $isOpen ? [6, 3, 6.0001] : [6.0001, 3, 6],
    borderRadius: $isOpen ? [0, 1, 0.0001] : [0.0001, 1, 0],
  },
  initial: false,
  transition: { duration: 0.5, times: [0, 0.5, 1] },
}))`
  ${style}
  transform: rotate(135deg);
  left: 6px;
  border-radius: 0;
`;
