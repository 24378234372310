import { throttle, call, put } from 'redux-saga/effects';
import { pick } from 'ramda';

import { withAlert } from 'store/alerts';
import api from 'api';

import { FEATURE_NAME, HAS_MORE, COUNT } from '.';
import { updateProjects, updatePagination } from './actions';
import { FETCH_PROJECTS } from './types';

function* fetchProjects({ payload }) {
  const data = yield call(api.get, FEATURE_NAME, { params: payload });
  yield put(updateProjects(data?.data || []));
  yield put(updatePagination(pick([COUNT, HAS_MORE], data)));
}

export default function* watchProjects() {
  yield throttle(500, FETCH_PROJECTS, withAlert(fetchProjects));
}
