import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_REGIONS = getName('UPDATE_REGIONS');
export const UPDATE_REGION = getName('UPDATE_REGION');
export const DELETE_REGION = getName('DELETE_REGION');
export const RESET_REGIONS = getName('RESET_REGIONS');
export const UPDATE_SEARCH = getName('UPDATE_SEARCH');
export const UPDATE_PAGINATION = getName('UPDATE_PAGINATION');
export const UPDATE_SORT = getName('UPDATE_SORT');
export const RESET_FILTERS = getName('RESET_FILTERS');

export const FETCH_REGIONS = getName('FETCH_REGIONS');
export const FETCH_REGIONS_COLLECT = getName('FETCH_REGIONS_COLLECT');
export const FETCH_LOCALITIES_COLLECT = getName('FETCH_LOCALITIES_COLLECT');
export const FETCH_REGION = getName('FETCH_REGION');
export const CREATE_REGION = getName('CREATE_REGION');
export const SAVE_REGION = getName('SAVE_REGION');
export const REMOVE_REGION = getName('REMOVE_REGION');
