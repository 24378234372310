import { useMemo, useEffect } from 'react';

import { wrapActions, wrapMultiSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { mergeAlerts } from 'store/alerts';
import { useUser, useLoggedIn, SUB_ORGANIZATION } from 'store/session';
import { useCountry, ID as COUNTRY_ID } from 'store/countries';

import {
  getOrganization,
  getOrganizationProp,
  getOrganizationConfig,
  getOrganizationConfigProp,
  getDementiaPopulation,
  getDementiaPopulationProp,
} from './selectors';
import * as actions from './actions';

export const useOrganizationsActions = wrapActions({ ...actions });

export const useOrganization = wrapMultiSelector(getOrganizationProp, getOrganization);
export const useOrganizationConfig = wrapMultiSelector(getOrganizationConfigProp, getOrganizationConfig);

export const useDementiaPopulation = (id, arg) =>
  wrapMultiSelector(getDementiaPopulationProp(id), getDementiaPopulation(id))(arg);

export const useInitOrganization = () => {
  const { extractId, id, loading, success, error } = useAlerts();
  const loggedIn = useLoggedIn();
  const organizationId = useUser(SUB_ORGANIZATION);
  const { fetchOrganization } = useOrganizationsActions();

  useEffect(() => {
    if (loggedIn && organizationId) {
      extractId(fetchOrganization(organizationId));
    }
  }, [loggedIn, organizationId, extractId, fetchOrganization]);

  return organizationId ? { loading: !id || loading, success, error } : { success: true };
};

export const useInitDementiaPopulation = () => {
  const alertsOrg = useAlerts();
  const extractOrgId = alertsOrg.extractId;
  const alertsCountry = useAlerts();
  const extractCountryId = alertsCountry.extractId;
  const { loading, success, error } = useMemo(() => mergeAlerts([alertsOrg, alertsCountry]), [alertsOrg, alertsCountry]);

  const loggedIn = useLoggedIn();
  const organizationId = useUser(SUB_ORGANIZATION);
  const countryId = useCountry(COUNTRY_ID);
  const { fetchDementiaPopulation } = useOrganizationsActions();

  useEffect(() => {
    if (loggedIn && organizationId) extractOrgId(fetchDementiaPopulation(organizationId));
  }, [extractOrgId, fetchDementiaPopulation, loggedIn, organizationId]);

  useEffect(() => {
    if (loggedIn && countryId) extractCountryId(fetchDementiaPopulation(countryId));
  }, [extractCountryId, fetchDementiaPopulation, loggedIn, countryId]);

  return loggedIn && organizationId && countryId
    ? { loading: !alertsOrg.id || !alertsCountry.id || loading, success, error }
    : { success: true };
};
