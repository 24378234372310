import { useEffect } from 'react';
import { wrapActions, wrapMultiSelector } from 'store/utils';
import { useOrganization, useAlerts, CANTON } from 'store/organizations';
import { usePermissions, CANTONS } from 'permissions';

import { getCanton, getCantonProp } from './selectors';
import * as actions from './actions';

export const useCantonsActions = wrapActions({ ...actions });

export const useCanton = (arg) => wrapMultiSelector(getCantonProp, getCanton)(arg);

export const useInitCurrentCanton = () => {
  const can = usePermissions();
  const { extractId, id, loading, success, error } = useAlerts();
  const canton = useOrganization(CANTON);
  const { fetchCantonByName } = useCantonsActions();
  const hasAccess = can.read(CANTONS) && canton;

  useEffect(() => {
    if (hasAccess) extractId(fetchCantonByName(canton));
  }, [hasAccess, canton, extractId, fetchCantonByName]);

  return hasAccess ? { loading: !id || loading, success, error } : { success: true };
};
