import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { INDEX } from 'utils/routes';
import GlobalStyles from 'theme/GlobalStyles';
import Notifications from 'components/Notifications';

import ROUTES from './utils';
import Helmet from './Helmet';
import InitApp from './InitApp';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

const Root = () => (
  <>
    <Helmet />
    <GlobalStyles />
    <Notifications />
    <InitApp>
      <Routes>
        {ROUTES.map(({ path, layout, access, component }) => (
          <Route
            key={path}
            index={path === INDEX}
            path={path}
            element={
              access ? (
                <PrivateRoute layout={layout} access={access} component={component} />
              ) : (
                <PublicRoute layout={layout} component={component} />
              )
            }
          />
        ))}
        <Route path="*" element={<Navigate to={INDEX} />} />
      </Routes>
    </InitApp>
  </>
);

export default Root;
