import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
    <g fill="none" fillRule="evenodd">
      <g stroke="#000" strokeWidth="2" transform="translate(-88 -307) translate(78 300) translate(10 7) translate(6 6)">
        <circle cx="8" cy="8" r="8" />
        <path d="M13 13L21 21" />
      </g>
    </g>
  </svg>
);
