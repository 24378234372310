import React, { useState, useCallback, useEffect } from 'react';

import { useAsyncState } from 'hooks';
import { useApplicationActions } from 'store/application';

import { useTypingTexts } from './hooks';
import AnimatedMap from './AnimatedMap';
import TypingText from './TypingText';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import { GlobalStyles, Container } from './styles';

const Home = () => {
  const { setSeenAnimation } = useApplicationActions();
  const [isVisibleMap, setVisibleMap] = useAsyncState(true);
  const [isTransparent, setTransparent] = useState(false);
  const [isOpenedMenu, setOpenedMenu] = useAsyncState(false);
  const { texts, gapIndex, isMobile } = useTypingTexts();

  const onScroll = useCallback(
    ({ currentTarget }) => {
      if (currentTarget.scrollY >= window.innerHeight * 0.5 && !isTransparent) {
        setTransparent(true);
      }
      if (currentTarget.scrollY < window.innerHeight * 0.5 && isTransparent) {
        setTransparent(false);
      }
    },
    [isTransparent]
  );

  useEffect(() => {
    if (isMobile) {
      window.addEventListener('scroll', onScroll);

      return () => window.removeEventListener('scroll', onScroll);
    }

    return () => null;
  }, [isMobile, onScroll]);

  useEffect(() => {
    if (!isVisibleMap && isMobile) {
      setVisibleMap(true);
    }
    if (!isMobile) {
      setTransparent(false);
    }
  }, [isVisibleMap, setVisibleMap, isMobile]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isMobile]);

  useEffect(
    () => () => {
      setSeenAnimation(true);
    },
    [setSeenAnimation]
  );

  return (
    <>
      <GlobalStyles $isTransparent={!isVisibleMap || (isMobile && isTransparent)} />
      <Container>
        <AnimatedMap isVisible={isMobile || isVisibleMap} />
        <TypingText
          key={isMobile ? 1 : 2}
          isOpenedMenu={isMobile && isOpenedMenu}
          texts={texts}
          gapIndex={gapIndex}
          onChange={setOpenedMenu}
        />
        {isMobile ? <MobileMenu /> : <DesktopMenu isOpened={isOpenedMenu} onComplete={setVisibleMap} />}
      </Container>
    </>
  );
};

export default Home;
