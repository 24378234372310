import { combineReducers } from 'redux';

import { reducerListTemplate, reducerFiltersTemplate } from 'store/utils';

import { ID, LIST, FILTERS, SEARCH, PAGINATION, COUNT, HAS_MORE, START_AFTER, LIMIT, SORT, SORT_BY, SORT_DIR } from '.';
import {
  UPDATE_REGIONS,
  UPDATE_REGION,
  DELETE_REGION,
  RESET_REGIONS,
  UPDATE_SEARCH,
  UPDATE_PAGINATION,
  UPDATE_SORT,
  RESET_FILTERS,
} from './types';

const regionsReducer = reducerListTemplate(
  {
    id: ID,
    list: UPDATE_REGIONS,
    item: UPDATE_REGION,
    deleteItem: DELETE_REGION,
    reset: RESET_REGIONS,
  },
  {}
);

const initFilter = {
  [SEARCH]: '',
  [SORT]: {
    [SORT_BY]: '',
    [SORT_DIR]: '',
  },
  [PAGINATION]: {
    [START_AFTER]: 0,
    [LIMIT]: 10,
    [COUNT]: 0,
    [HAS_MORE]: false,
  },
};

const filterReducer = reducerFiltersTemplate(
  {
    search: UPDATE_SEARCH,
    sort: UPDATE_SORT,
    pagination: UPDATE_PAGINATION,
    reset: RESET_FILTERS,
  },
  initFilter
);

export default combineReducers({
  [LIST]: regionsReducer,
  [FILTERS]: filterReducer,
});
