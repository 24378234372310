/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useAsyncState } from 'hooks';
import { useSeenAnimation } from 'store/application';

import { Container, Row, Text, Cursor } from './styles';

const TypingText = ({ texts, gapIndex, onChange }) => {
  const isSeenAnimation = useSeenAnimation();
  const [currentRow, setCurrentRow] = useAsyncState(0);
  const [isFinished, setIsFinished] = useAsyncState(false);

  const nextRow = useCallback(
    (def) => {
      if (def !== 'show') return;
      if (texts.length > currentRow + 1) {
        setCurrentRow(currentRow + 1);
      } else {
        setIsFinished(true);
      }
    },
    [texts, currentRow, setCurrentRow, setIsFinished]
  );

  const onAnimationComplete = useCallback(({ visibility }) => onChange(visibility === 'hidden'), [onChange]);

  useEffect(() => {
    currentRow === 0 && onChange(false);
  }, [currentRow, onChange]);

  return (
    <Container>
      {texts.map((text, index) => (
        <Row
          key={index}
          animate={currentRow >= index || isSeenAnimation ? 'show' : 'hidden'}
          onAnimationComplete={nextRow}
          $length={text.length}
          $mb={gapIndex === index}
          $withoutAnimation={isSeenAnimation}
        >
          {text.split('').map((letter, idx) => (
            <Text key={idx}>{letter}</Text>
          ))}
          {!isSeenAnimation && currentRow === index && (
            <Cursor $isFinished={isFinished} onAnimationComplete={onAnimationComplete} />
          )}
        </Row>
      ))}
    </Container>
  );
};

TypingText.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  gapIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TypingText;
