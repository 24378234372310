import styled from 'styled-components';

import { fontStyles, palette } from 'theme';

export const Container = styled.div`
  width: 100%;
  position: relative;
  ${({ $error }) =>
    typeof $error === 'string' &&
    `
    &::after {
      content: '${$error}';
      ${fontStyles.smallText}
      width: 100%;
      color: ${palette.red};
    }
  `}
`;

export const Input = styled.input`
  height: 48px;
  width: 100%;
  border: 2px solid
    ${({ value, $error, $readMode }) => {
      if ($readMode) return palette.black;
      if ($error) return palette.red;
      if (value) return palette.yellow;

      return palette.black;
    }};
  border-width: ${({ $readMode }) => ($readMode ? '2px 0' : '2px')};
  border-radius: 0;
  ${fontStyles.text}
  color: ${palette.black};
  &::placeholder {
    color: #696969;
  }
  margin-bottom: ${({ $error }) => ($error && typeof $error === 'string' ? 0 : 15)}px;
  outline: none;
  background: none;
  box-sizing: border-box;
  transition: all 0.3s;
  padding-left: ${({ $isSearch }) => ($isSearch ? 50 : 15)}px;
  padding-right: ${({ $isPassword }) => ($isPassword ? 85 : 15)}px;
  &:disabled {
    -webkit-text-fill-color: #000000;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: ${({ $startPosition }) => ($startPosition ? 'auto' : 0)};
  left: ${({ $startPosition }) => ($startPosition ? '10px' : 'auto')};
  align-items: center;
  z-index: 1;
  height: 47px;
  padding-right: 15px;
`;

export const Circle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 2px solid ${palette.black};
  border-radius: 50%;
  background: none;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
`;

export const ClearBtn = styled(Circle)`
  width: 47px;
  height: 47px;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  margin: 0;
`;

export const Dot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: ${palette.black};
  transition: all 0.3s;
  opacity: ${({ $isShow }) => ($isShow ? 1 : 0.0)};
`;

export const Text = styled.p`
  ${fontStyles.smallText}
  user-select: none;
`;
