import { createGlobalStyle } from 'styled-components';

import SimpleRegularWoff from 'assets/fonts/SimpleRegular.woff';
import SimpleRegularWoff2 from 'assets/fonts/SimpleRegular.woff2';
import SimpleLightWoff from 'assets/fonts/SimpleLight.woff';
import SimpleLightWoff2 from 'assets/fonts/SimpleLight.woff2';
import SimpleBoldWoff from 'assets/fonts/SimpleBold.woff';
import SimpleBoldWoff2 from 'assets/fonts/SimpleBold.woff2';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-display: swap;
    font-family: SimpleRegular;
    src: url(${SimpleRegularWoff}) format("woff"), url(${SimpleRegularWoff2}) format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: SimpleLight;
    src: url(${SimpleLightWoff}) format("woff"), url(${SimpleLightWoff2}) format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: SimpleBold;
    src: url(${SimpleBoldWoff}) format("woff"), url(${SimpleBoldWoff2}) format("woff2");
  }
  html {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-width: 350px;
  }
  body, #root {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    border: none;
    font-size: 18px;
    line-height: 1.33;
    font-style: normal;
    font-family: SimpleRegular, sans-serif;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  ul {
    list-style-type: none;
  }
  .xaxis path,
  .yaxis path {
    stroke-width: 2px;
  }
  .grid .tick {
    stroke: #000;
    stroke-opacity: 0.2;
    shape-rendering: crispEdges;
  }
  .grid path {
    stroke-width: 0;
  }
  .hide-scroll-bar {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .map-popup-overlay-content-bottom {
    transform: translateY(calc(100% + 118px));
    & + div {
      transform: translateY(78px);
    }
  }
  .ol-zoom>button {
    width: 30px !important;
    height: 30px !important;
    border: 2px solid #000 !important;
    background-color: rgba(255, 255, 255, 0.5) !important;;
    color: #000 !important;;
    border-radius: 0 !important;
    padding-bottom: 2px !important;;
    &:focus {
      background-color: rgba(255, 255, 255, 0.5);
    }
    @media (hover) {
      &:hover {
        transform: scale(1.1);
        background: none;
      }
    }
  }
  .ol-scale-line {
    background: none !important;
  }
  .ol-scale-line-inner {
    border: 2px solid #000 !important;
    border-width: 0 2px 2px 2px !important;
    color: #000 !important;
  }
`;

export default GlobalStyles;
