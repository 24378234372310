import { SUPPORT, CONTACT, RELEASES, PRIVACY, IMPRINT, ABOUT, DATA } from 'utils/routes';

export const FOOTER_MENU = [
  {
    id: 1,
    title: 'Ressourcen',
    items: [
      { to: SUPPORT, name: 'Hilfebereich' },
      { to: RELEASES, name: 'Releases' },
      { to: DATA, name: 'Daten' },
    ],
  },
  {
    id: 2,
    title: 'Über CuraData',
    items: [
      { to: ABOUT, name: 'Unternehmen' },
      { to: IMPRINT, name: 'Impressum' },
      { to: CONTACT, name: 'Kontakt' },
    ],
  },
  {
    id: 3,
    title: 'Folgen Sie uns',
    items: [
      { to: 'https://www.linkedin.com/company/curadata', name: 'LinkedIn', isExternal: true },
      { to: 'https://twitter.com/cura_data', name: 'Twitter', isExternal: true },
    ],
  },
  {
    id: 4,
    title: `©${new Date().getFullYear()} CuraData AG `,
    items: [{ to: PRIVACY, name: 'Datenschutz' }],
    lastItem: true,
  },
];
