import { useMemo } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { map } from 'ramda';

import { useOrganization, NAME } from 'store/organizations';
import { TEAM, SUPPORT_ITEM, NEWSROOM } from 'utils/routes';
import supportItems from 'pages/Support/content.json';
import newsrooms from 'locale/content/de/newsroom.json';
import { routesMapping } from '../utils';

const DEFAULT_TITLE = 'Gesundheitsversorgung der Altersbevölkerung';
const DEFAULT_DESCRIPTION =
  'CuraData macht Daten zur Altersbevölkerung nutzbar, um älteren Menschen eine bedarfsgerechte Gesundheitsversorgung zur Verfügung zu stellen.';

const supportItemPageMeta = supportItems.reduce((acc, { items }) => {
  items.forEach(({ key, name }) => {
    acc[key] = { title: name };
  });

  return acc;
}, {});
const newsroomMeta = map(
  ({ title, description, date, author }) => ({
    title,
    description,
    date: new Date(Date.parse(date)).toISOString(),
    author,
    type: 'article',
    section: 'Gesundheit',
  }),
  newsrooms
);

export const usePageData = () => {
  const { pathname } = useLocation();
  const orgName = useOrganization(NAME);
  const teamPage = useMatch(TEAM);
  const supportItemPage = useMatch(`${SUPPORT_ITEM}:id`);
  const newsroomPage = useMatch(`${NEWSROOM}:id`);

  const { title, description, date, author, type, section, withoutIndexing } = useMemo(
    () => ({
      ...(routesMapping(pathname)?.meta || {}),
      ...(teamPage && orgName && { title: orgName || 'Team' }),
      ...(supportItemPage && supportItemPage.params?.id && supportItemPageMeta[supportItemPage.params.id]),
      ...(newsroomPage && newsroomPage.params?.id && newsroomMeta[newsroomPage.params.id]),
    }),
    [newsroomPage, orgName, pathname, supportItemPage, teamPage]
  );

  return useMemo(
    () => ({
      url: `${process.env.REACT_APP_HOST}${pathname}`,
      type: type || 'website',
      title: title || DEFAULT_TITLE,
      description: description || DEFAULT_DESCRIPTION,
      withoutIndexing: Boolean(withoutIndexing) || process.env.REACT_APP_TYPE !== 'production',
      date,
      author,
      section,
    }),
    [author, date, description, pathname, section, title, type, withoutIndexing]
  );
};

export const filterMeta = [
  'meta[name=description]',
  "meta[property='og:type']",
  "meta[property='og:url']",
  "meta[property='og:title']",
  "meta[property='og:description']",
  // "meta[property='og:image']",
  // "meta[property='og:image:alt']",
  "meta[name='twitter:title']",
  "meta[name='twitter:description']",
  // "meta[name='twitter:image']",
  'meta[name=robots]',
];
