import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { fontStyles, palette } from 'theme';
import InputDef from 'components/Input';
import ButtonDef from 'components/Button';

import { ReactComponent as FlowerSvg } from 'assets/svg-icons/flower.svg';

export const Container = styled.div`
  display: grid;
  grid-column: 1/-1;
  grid-row: 1/2;
  grid-template-columns: 46px 1fr 46px;
  height: 48px;
  align-items: center;
  border: 2px solid ${palette.black};
  box-sizing: border-box;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const Icon = styled(FlowerSvg)`
  height: 100%;
  width: 100%;
  background-color: ${palette.white};
`;

export const Title = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 100%;
  ${fontStyles.textUpper}
  text-align: center;
  background-color: ${palette.gray};
  border: solid ${palette.black};
  border-width: 0 2px;
  padding: 0 10px;
  box-sizing: border-box;
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${palette.white};
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-template-rows: 33px 1fr;
  row-gap: 5px;
  width: 655px;
  margin-left: 24px;
  @media (max-width: 1360px) {
    width: 100%;
    grid-row: 1/2;
    margin: 0 0 30px 0;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;

export const Input = styled(InputDef)`
  ${fontStyles.text}
  height: 33px;
  max-height: 33px;
  border-width: 0 0 2px 0;
  padding: 0;
  &::placeholder {
    ${fontStyles.textUpper}
    color: ${palette.black};
  }
`;

export const Button = styled(ButtonDef)`
  height: 100%;
`;

export const Text = styled.p`
  grid-column: 1/-1;
  ${fontStyles.smallText}
  color: ${({ $error }) => palette[$error ? 'red' : 'black']};
  width: fit-content;
`;

export const TextLink = styled(Link)`
  ${fontStyles.smallText}
  color: ${palette.black};
  &:active {
    color: rgba(0, 0, 0, 0.6);
  }
  @media (hover) {
    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;
