import styled, { keyframes } from 'styled-components';

import { palette } from 'theme';

export const FullScreenWrap = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const opacity = keyframes`
  0%, 50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ $height }) => $height};
  animation: ${opacity} 300ms 1 ease-in-out;
`;

const load = keyframes`
  0%,
  80%,
  100% {
    transform: scaleY(0.75);
  }
  40% {
    transform: scaleY(1);
  }
`;

export const Line = styled.div`
  background-color: ${palette.yellow};
  border: 2px solid ${palette.black};
  animation: ${load} 1s infinite ease-in-out;
  box-sizing: border-box;
  width: 10px;
  height: 100%;
  transform: scaleY(0.75);
  margin: 0 2px;
  animation-delay: ${({ $index }) => $index * 0.16}s;
`;
