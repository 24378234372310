export const DEFAULT_LANGUAGE = 'de';

export const SUPPORT_EMAIL = 'info@curadata.ch';

export const AXIS_SCALE_TEMPLATE = [
  { range: 1, divider: 1, postfix: '' },
  { range: 10, divider: 1, postfix: '' },
  { range: 100, divider: 1, postfix: '' },
  { range: 1000, divider: 1000, postfix: 'k' },
  { range: 10000, divider: 1000, postfix: 'k' },
  { range: 100000, divider: 1000, postfix: 'k' },
  { range: 1000000, divider: 1000000, postfix: 'M' },
  { range: 10000000, divider: 1000000, postfix: 'M' },
  { range: 100000000, divider: 1000000, postfix: 'M' },
];
