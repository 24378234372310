import { handleActions } from 'redux-actions';
import { propOr, prop, anyPass, allPass, pipe, not, props, has, path } from 'ramda';

import { getPermissions } from 'permissions/utils';
import { updateToken } from 'api';

import { AUTHENTICATE, LOGOUT, UPDATE_USER_DATA, AUTHENTICATE_2FA } from './types';
import { ID, AUTH, LOGGED_IN, USER, PERMISSIONS, ROLES, TWO_FACTOR_AUTH, TOKEN, TOKEN_2FA } from '.';

const getInit = () => ({
  [AUTH]: Boolean(prop(TOKEN_2FA, sessionStorage) || prop(TOKEN, localStorage)),
  [LOGGED_IN]: false,
  [USER]: {},
  [PERMISSIONS]: {},
});

const handleUserData = (user = {}) => {
  const isLoggedIn = anyPass([
    allPass([() => sessionStorage[TOKEN_2FA], prop(TWO_FACTOR_AUTH), prop(ID)]),
    allPass([() => localStorage[TOKEN], pipe(prop(TWO_FACTOR_AUTH), not), prop(ID)]),
  ])(user);

  Boolean(prop(ID, user));

  return {
    [LOGGED_IN]: isLoggedIn,
    [USER]: user,
    [PERMISSIONS]: isLoggedIn ? getPermissions(propOr([], ROLES, user)) : {},
  };
};

export default handleActions(
  {
    [AUTHENTICATE]: (state, { payload }) => {
      const [token, has2FA] = props([TOKEN, TWO_FACTOR_AUTH], payload);
      updateToken(token);

      return {
        ...state,
        [AUTH]: !has2FA && Boolean(token),
        ...handleUserData(payload),
      };
    },
    [AUTHENTICATE_2FA]: (state, { payload }) => {
      const token = prop(TOKEN, payload);
      updateToken(null, token);

      return {
        ...state,
        [AUTH]: Boolean(token),
        ...handleUserData(payload),
      };
    },
    [LOGOUT]: () => {
      updateToken();

      return getInit();
    },
    [UPDATE_USER_DATA]: (state, { payload }) => ({
      ...state,
      [USER]: payload,
      ...(has(ROLES, payload) &&
        String(path([USER, ROLES], state)) !== String(prop(ROLES, payload)) && {
          [PERMISSIONS]: getPermissions(prop(ROLES, payload)),
        }),
    }),
  },
  getInit()
);
