import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import { UPDATE_CANTONS, RESET_CANTONS, FETCH_CANTONS_COLLECT, FETCH_CANTON_BY_NAME, FETCH_CANTON_BY_ID } from './types';

export const updateCantons = createAction(UPDATE_CANTONS);
export const resetCantons = createAction(RESET_CANTONS);

export const fetchCantonsCollect = createAlertAction(FETCH_CANTONS_COLLECT);
export const fetchCantonByName = createAlertAction(FETCH_CANTON_BY_NAME);
export const fetchCantonById = createAlertAction(FETCH_CANTON_BY_ID);
