import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { reducerFiltersTemplate } from 'store/utils';

import { LIST, FILTERS, PAGINATION, COUNT, HAS_MORE, START_AFTER, LIMIT, SORT, SORT_BY, SORT_DIR } from '.';
import { UPDATE_COUNTRIES, RESET_COUNTRIES, UPDATE_SEARCH, UPDATE_PAGINATION, UPDATE_SORT, RESET_FILTERS } from './types';

const countriesReducer = handleActions(
  {
    [UPDATE_COUNTRIES]: (state, { payload }) => payload,
    [RESET_COUNTRIES]: () => [],
  },
  []
);

const initFilter = {
  [SORT]: {
    [SORT_BY]: '',
    [SORT_DIR]: '',
  },
  [PAGINATION]: {
    [START_AFTER]: 0,
    [LIMIT]: 10,
    [COUNT]: 0,
    [HAS_MORE]: false,
  },
};

const filterReducer = reducerFiltersTemplate(
  {
    search: UPDATE_SEARCH,
    sort: UPDATE_SORT,
    pagination: UPDATE_PAGINATION,
    reset: RESET_FILTERS,
  },
  initFilter
);

export default combineReducers({
  [LIST]: countriesReducer,
  [FILTERS]: filterReducer,
});
