import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { values, props } from 'ramda';

import { wrapActions, wrapMultiSelector, wrapSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { SEARCH, SORT, PAGINATION, LIMIT, START_AFTER, POPULATION, COUNT, HAS_MORE, ID, SUB_ORGANIZATIONS } from 'api/consts';
import { usePermissions, SCENARIOS } from 'permissions';
import { useAsyncState } from 'hooks';

import { getScenarios, getScenario, getScenarioProp, getScenariosFilters } from './selectors';
import * as actions from './actions';
import { DEMENTIA, GERONTO, PALLIATIVE /* GERIATRICS, PARKINSON */ } from './consts';

export const useScenariosActions = wrapActions({ ...actions });

export const useScenario = (arg) => {
  const { id } = useParams();

  return wrapMultiSelector(getScenarioProp(id), getScenario(id))(arg);
};

export const useScenariosFilters = wrapSelector(getScenariosFilters);

export const useInitScenarios = () => {
  const can = usePermissions();
  const hasAccess = can.read(SCENARIOS);
  const alerts = useAlerts();
  const scenarios = useSelector(getScenarios);
  const list = useMemo(() => values(scenarios), [scenarios]);
  const filters = useSelector(getScenariosFilters);
  const search = filters[SEARCH];
  const sort = filters[SORT];
  const pagination = filters[PAGINATION];
  const limit = pagination[LIMIT];
  const startAfter = pagination[START_AFTER];

  const { extractId } = alerts;
  const { resetScenarios, fetchScenarios } = useScenariosActions();

  useEffect(() => {
    if (!hasAccess) return;
    extractId(
      fetchScenarios({
        ...(search && { [SEARCH]: search }),
        [LIMIT]: limit,
        [START_AFTER]: startAfter,
        ...sort,
      })
    );
  }, [hasAccess, extractId, fetchScenarios, search, limit, startAfter, sort]);

  useEffect(() => {
    if (!hasAccess) resetScenarios();

    return resetScenarios;
  }, [hasAccess, resetScenarios]);

  return {
    list,
    ...alerts,
    search,
    sort,
    pagination,
  };
};

export const useInitScenario = () => {
  const { id: currentId } = useParams();
  const { fetchScenario } = useScenariosActions();
  const { action, loading, success, error } = useAlerts(fetchScenario);
  const scenario = useSelector(getScenario(currentId));

  const specOffersKeys = useMemo(
    () => (scenario ? [DEMENTIA, GERONTO, PALLIATIVE /* GERIATRICS, PARKINSON */].filter((key) => Boolean(scenario[key])) : []),
    [scenario]
  );

  useEffect(() => {
    if (currentId) action(currentId);
  }, [action, currentId]);

  return { loading: !(success || error) || loading, error, specOffersKeys };
};

export const useInitScenarioComplexCare = () => {
  const { id } = useParams();
  const { fetchScenarioComplexCare } = useScenariosActions();
  const { action, loading, success, error } = useAlerts(fetchScenarioComplexCare);

  useEffect(() => {
    if (id) action(id);
  }, [action, id]);

  return { loading: !(success || error) || loading, error, complexCare: success };
};

export const useInitScenarioEyeDiseases = () => {
  const { id } = useParams();
  const { fetchScenarioEyeDiseases } = useScenariosActions();
  const { action, loading, success, error } = useAlerts(fetchScenarioEyeDiseases);

  const population = useSelector(getScenarioProp(id)(POPULATION));
  const populationLength = population?.length;

  useEffect(() => {
    if (id) action(id);
  }, [action, id, populationLength]);

  return { loading: !(success || error) || loading, error, eyeDiseases: success };
};

export const useInitScenarioPopulation = (organizations) => {
  const { id } = useParams();
  const [population, setPopulation] = useAsyncState();
  const { fetchScenarioPopulation } = useScenariosActions();
  const { action, loading, success, error } = useAlerts(fetchScenarioPopulation);
  const [startAfter, count, hasMore] = useMemo(() => props([START_AFTER, COUNT, HAS_MORE], success || {}), [success]);

  useEffect(() => {
    if (id) action({ [ID]: id, [SUB_ORGANIZATIONS]: organizations });
  }, [action, id, organizations]);

  useEffect(() => {
    if (success && success.data) setPopulation(success.data);
  }, [setPopulation, success]);

  return {
    loading: !(success || error) || loading,
    error,
    population,
    startAfter,
    count,
    hasMore,
  };
};

export const useInitScenarioOutpatientData = () => {
  const { id } = useParams();
  const { fetchScenarioOutpatientData } = useScenariosActions();
  const { action, loading, success, error } = useAlerts(fetchScenarioOutpatientData);

  useEffect(() => {
    if (id) action(id);
  }, [action, id]);

  return { loading: !(success || error) || loading, error, data: success };
};
