import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSessionActions } from 'store/session';
import { usePermissions, TEAM, USER, regionPermissionTask } from 'permissions';
import { useOrganization, NAME, KIND } from 'store/organizations';
import { INDEX, TEAM as TEAM_ROUTE } from 'utils/routes';
import { PRIVATE_MENU } from '../consts';

import ProfileButton from './ProfileButton';
import { Nav, Wrapper, Item, Button } from './styles';

const PrivateMenu = () => {
  const [organizationName, organizationKind] = useOrganization([NAME, KIND]);
  const { t } = useTranslation('privatemenu');
  const navigate = useNavigate();
  const { logout } = useSessionActions();
  const handleLogout = useCallback(() => {
    logout();
    navigate(INDEX, { replace: true });
  }, [logout, navigate]);
  const can = usePermissions();

  return (
    <Nav>
      <Wrapper>
        {PRIVATE_MENU.map(({ id, path, label }) =>
          can.redirect(id, regionPermissionTask(organizationKind)) ? (
            <Item key={id} to={path}>
              {t(label)}
            </Item>
          ) : null
        )}
      </Wrapper>
      <Wrapper>
        {can.redirect(USER) && <ProfileButton />}
        {can.redirect(TEAM) && <Item to={TEAM_ROUTE}>{organizationName || 'CuraData'}</Item>}
        <Button type="button" onClick={handleLogout}>
          {t('Log out')}
        </Button>
      </Wrapper>
    </Nav>
  );
};

export default PrivateMenu;
