import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import { omit } from 'ramda';

import { withAlert, applyCancelToken } from 'store/alerts';
import api from 'api';

import { updateTeam, updateMember, deleteMember } from './actions';
import { FETCH_TEAM, CREATE_MEMBER, SAVE_MEMBER, REMOVE_MEMBER } from './types';
import { ID } from '.';

function* fetchTeam({ payload, ...rest }) {
  const data = yield call(api.get, '/users', { params: payload, ...applyCancelToken(rest) });
  yield put(updateTeam(data.data));
}

function* createMember({ payload }) {
  const data = yield call(api.post, '/users', payload);
  yield put(updateMember(data));

  return { success: data };
}

function* saveMember({ payload }) {
  const data = yield call(api.patch, `/users/${payload[ID]}`, omit([ID], payload));
  yield put(updateMember(data));
}

function* removeMember({ payload }) {
  const data = yield call(api.delete, `/users/${payload}`);
  yield put(deleteMember(data));
}

export default function* watchTeam() {
  yield takeLatest(FETCH_TEAM, withAlert(fetchTeam));
  yield takeEvery(CREATE_MEMBER, withAlert(createMember));
  yield takeEvery(SAVE_MEMBER, withAlert(saveMember));
  yield takeEvery(REMOVE_MEMBER, withAlert(removeMember));
}
