import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { wrapActions, wrapSelector, wrapMultiSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { PAGINATION, START_AFTER, SORT, LIMIT } from 'api/consts';
import { usePermissions, COUNTRIES } from 'permissions';

import { getCountries, getCountry, geyCountryProp, getCountriesFilters } from './selectors';
import * as actions from './actions';

export const useCountriesActions = wrapActions({ ...actions });

export const useCountry = wrapMultiSelector(geyCountryProp, getCountry);

export const useCountriesFilters = wrapSelector(getCountriesFilters);

export const useInitCountries = () => {
  const can = usePermissions();
  const hasAccess = can.read(COUNTRIES);
  const alerts = useAlerts();
  const list = useSelector(getCountries);
  const filters = useCountriesFilters();
  const sort = filters[SORT];
  const pagination = filters[PAGINATION];
  const limit = pagination[LIMIT];
  const startAfter = pagination[START_AFTER];

  const { extractId } = alerts;
  const { fetchCountries } = useCountriesActions();

  useEffect(() => {
    if (!hasAccess) return;
    extractId(
      fetchCountries({
        [LIMIT]: limit,
        [START_AFTER]: startAfter,
        ...sort,
      })
    );
  }, [hasAccess, extractId, fetchCountries, limit, startAfter, sort]);

  return {
    list,
    ...alerts,
    sort,
    pagination,
  };
};
