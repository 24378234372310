import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';
import { useLoggedIn, useAuth, useSessionActions, useAlerts } from 'store/session';

import { useInitData } from './hooks';

const InitApp = ({ children }) => {
  const { extractId, ...restoreAlerts } = useAlerts();
  const { restore, logout } = useSessionActions();
  const isAuth = useAuth();
  const isLoggedIn = useLoggedIn();
  const shouldRestore = isAuth && !isLoggedIn;
  const { loading, error } = useInitData();
  const isSpinner = (shouldRestore && (!restoreAlerts.id || restoreAlerts.loading)) || (isLoggedIn && loading);

  useEffect(() => {
    if (shouldRestore) extractId(restore());
  }, [extractId, restore, shouldRestore]);

  useEffect(() => {
    if (restoreAlerts.error) logout();
  }, [restoreAlerts.error, logout]);

  if (isSpinner) return <Spinner fullScreen />;
  if (error) return `Error: ${error}`; // TODO handle error

  return children;
};

InitApp.propTypes = {
  children: PropTypes.element.isRequired,
};

export default InitApp;
